import {
  mapValues,
  map,
  keyBy,
  uniq,
  flatMap,
  chain,
  sortBy,
  cloneDeep,
} from "lodash";
import { generateXaxies, sortData } from "../../../Helpers/Helper";

const colors = [
  "#359185",
  "#83a70e",
  "#0042ab",
  "#b0a39b",
  "#83654b",
  "#39a394",
  "#99c020",
  "#0054c5",
  "#c1b2a8",
  "#8e6849",
  "#39b3a3",
  "#abd32b",
  "#005adb",
  "#d7c7bc",
  "#a37c5c",
  "#c4e8e4",
  "#e0f0ac",
  "#aeccf4",
  "#f4eeea",
  "#e4d9cf",
  "#e3f5f3",
  "#f2f6e4",
  "#d8e6fa",
  "#f1eae2",
  "#f2ece7",
];

let commonLegends = {
  orient: "horizontal",
  top: "80%", // Position the legend below the chart
  left: "center",
  itemGap: 10,
  itemWidth: 10,
  itemHeight: 10,
  textStyle: {
    fontSize: 10,
  },
};
const commonGrid = {
  left: "10%",
  right: "10%",
  top: "10%", // Adjust the top margin as needed
  bottom: "25%", // Reduced bottom margin
};

function yearlyData(data) {
  // Extract all unique year-quarter combinations and sort them
  const years = chain(data)
    .flatMap((item) => item.data.map((d) => d.qy))
    .uniq()
    .sort()
    .value();

  // Map over each data item to adjust the series
  return data.map((item) => {
    // Create a map of existing data for quick lookup
    const dataMap = keyBy(item.data, "qy");

    // Add missing year-quarter entries with a value of null
    const completeData = years.map((qy) => {
      return dataMap[qy] || { qy, value: null, year: parseInt(qy) };
    });

    // Sort the complete data by year-quarter
    item.data = sortBy(completeData, ["qy"]);

    return item;
  });
}

function generateLineChartData(names, chartData) {
  const temp = [];

  for (let i = 0; i < names.length; i++) {
    const dataObject = {
      name: names[i],
      type: "line",
      symbol: "circle",
      symbolSize: 10,
      lineStyle: {
        color: colors[i],
      },
      itemStyle: {
        color: colors[i], // Custom color for Series 1 points
      },
      // stack: "Total",
      data: chartData?.[i]?.data.map((el) => el.value),
    };
    temp.push(dataObject);
  }
  return temp;
}
function generateBarChartData(names, chartData) {
  const temp = [];

  for (let i = 0; i < names.length; i++) {
    const dataObject = {
      name: names[i],
      type: "bar",
      itemStyle: {
        color: colors[i],
      },
      data: chartData?.[i]?.data.map((el) => el.value),
    };
    temp.push(dataObject);
  }
  return temp;
}
function generateStackedLineChartData(names, chartData) {
  return names.map((name, i) => ({
    name: name,
    type: "line",
    symbol: "circle",
    symbolSize: 10,
    areaStyle: {},
    emphasis: {
      focus: "series",
    },
    lineStyle: {
      color: colors[i],
    },
    itemStyle: {
      color: colors[i], // Custom color for series points
    },
    data: chartData?.[i]?.data.map((el) => el.value),
  }));
}

function generateStackedBarChartData(names, chartData) {
  return names.map((name, i) => ({
    name: name,
    type: "bar",
    stack: "total",
    barWidth: "60%",
    label: {
      show: true,
      formatter: (params) => Math.round(params.value) / 1000 + "%",
    },
    data: chartData?.[i]?.data.map((el) => el.value),
  }));
}
function generateRandomInteger(min, max) {
  return Math.floor(Math.random() * (max - min + 1));
}

// Function to generate bubble chart data
const generateBubbleChartData = (chartData) => {
  // Generate x-axis categories and initialize a starting value for positions
  const qy = generateXaxies(chartData);

  let startingValue = 0;
  const tempObject = mapValues(keyBy(qy), () => {
    startingValue += 0.25;
    return startingValue;
  });

  // Initialize temporary array to hold series data
  const tempSeries = map(chartData, (series) => {
    return map(series.data, (obj) => {
      return [
        tempObject[obj.qy], // x-axis position
        obj.value, // y-axis value
        generateRandomInteger(5210967, 1700000000), // random size
        obj.qy, // x-axis category label
        series.name, // series name
      ];
    });
  });

  // Create final series array for the chart
  const seriesArray = map(tempSeries, (seriesData, index) => {
    return {
      name: seriesData?.[0]?.[4],
      data: seriesData,
      type: "scatter",
      symbolSize: (data) => Math.sqrt(data[2]) / 5e2, // Bubble size calculation
      itemStyle: {
        color: colors[index], // Custom color for bubbles
      },
      emphasis: {
        focus: "series",
        label: {
          show: true,
          formatter: (param) => param.data[3], // Label formatter
          position: "top",
        },
      },
    };
  });

  return seriesArray;
};

function generateData(chartType, chartData) {
  let series;
  let xAxis;
  let legend;
  let grid;
  let dChartData;

  switch (chartType) {
    case "line":
      series = generateLineChartData(
        chartData.map((ie) => ie.name),
        chartData
      );
      xAxis = generateXaxies(chartData);
      legend = commonLegends;
      grid = commonGrid;
      break;
    case "bar":
      series = generateBarChartData(
        chartData.map((ie) => ie.name),
        chartData
      );
      xAxis = generateXaxies(chartData);
      legend = commonLegends;
      grid = commonGrid;
      break;
    case "stackedline":
      series = generateStackedLineChartData(
        chartData.map((ie) => ie.name),
        chartData
      );
      xAxis = generateXaxies(chartData);
      legend = commonLegends;
      grid = commonGrid;
      break;
    case "bubble":
      series = generateBubbleChartData(chartData);
      legend = commonLegends;
      grid = commonGrid;
      break;

    case "stackedbar":
      series = generateStackedBarChartData(chartData.map((ie) => ie.name));
      xAxis = generateXaxies(chartData);
      legend = commonLegends;
      grid = commonGrid;
      break;
    case "doughnut":
      dChartData = renderDoughnutChart(chartData);
      break;
    default:
      console.error(`Invalid chart type: ${chartType}`);
  }
  return { series, xAxis, legend, grid, dChartData };
}

const adjustYearQuarter = (jsonData) => {
  // Extract all unique year-quarter combinations
  const allYearsAndQuarters = uniq(
    flatMap(jsonData, (item) => item.data.map((d) => d.qy))
  );

  // Adjust each item's data to include all year-quarter combinations
  const adjustedData = map(jsonData, (item) => {
    // Create a map of existing data for quick lookup
    const dataMap = keyBy(item.data, "qy");

    // Add missing year-quarter entries with a value of null
    const completeData = map(allYearsAndQuarters, (qy) => {
      return (
        dataMap[qy] || {
          qy,
          value: null,
          quarter: parseInt(qy[0]),
          year: parseInt("20" + qy.slice(2, 4)),
        }
      );
    });

    // Sort the complete data
    item.data = sortData(completeData);
    return item;
  });

  return adjustedData;
};

function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

const renderDoughnutChart = (chartData) => {
  // Generate all unique year-quarter combinations for the x-axis
  const allQuarterYears = generateXaxies(chartData);

  // Map over each year-quarter combination to generate the chart options
  const options = allQuarterYears.map((quarterYear, qyIndex) => {
    const data = [];

    // Iterate over chart data to find matching values for the current quarter-year
    chartData.forEach((series, seriesIndex) => {
      const valueObj = series.data.find(
        (el) => el.qy === quarterYear && el.value !== null
      );

      if (valueObj) {
        data.push({
          value: valueObj.value,
          name: series.name,
          itemStyle: {
            color: colors[seriesIndex],
          }, // Assign color based on index
        });
      }
    });

    // Return an object representing the chart options for the current quarter-year
    return {
      title: quarterYear,
      data: data,
    };
  });

  // Render the DoughnutChart component with the generated options
  return options;
};

const collectIds = (node, parentIds, collectedIds = new Set()) => {
  if (node.is_data_available === "true") {
    parentIds.forEach((id) => collectedIds.add(id));
    collectedIds.add(node.id);
  }

  if (node.children && node.children.length > 0) {
    node.children.forEach((child) => {
      collectIds(child, [...parentIds, node.id], collectedIds);
    });
  }

  return collectedIds;
};

const collectIdsForAllNodes = (data) => {
  let allCollectedIds = new Set();
  data.forEach((node) => {
    collectIds(node, [], allCollectedIds);
  });
  return Array.from(allCollectedIds);
};

export const CommonFunctions = {
  yearlyData,
  generateData,
  adjustYearQuarter,
  getKeyByValue,
  collectIdsForAllNodes,
};
