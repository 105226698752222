import React, { useState } from 'react'
import {
  DataTypeProvider,
  TreeDataState, SortingState, SelectionState, FilteringState,
  CustomTreeData, IntegratedFiltering, IntegratedSorting, IntegratedSelection, SearchState
} from '@devexpress/dx-react-grid'
import {
  Grid,
  Table, TableHeaderRow, TableFilterRow, TableTreeColumn,
  Toolbar, TableColumnVisibility, SearchPanel, VirtualTable
} from '@devexpress/dx-react-grid-bootstrap4'
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css"

const getChildRows = (row, rows) => {
  const childRows = rows.filter(r => r.parent_id === (row ? row.id : 0))
  return childRows.length ? childRows : null
}
export default (props) => {
  const [filterOperations] = useState([
    'startsWith',
    'endsWith',
    'contains'
  ])
  const TableComponent = ({ ...restProps }) => (
    <Table.Table
      {...restProps}
      className="list-table"
    />
  )

  const CellComponent = ({ ...restProps }) => {
    return (
      <td
        className={expandedRowIds.includes(restProps.tableRow.rowId) ? "cell-expanded" : ""}
      >
        <div
          className={`d-flex flex-direction-row align-items-center text-nowrap odr`}
          children={restProps.children}
        />
      </td>
    )
  }

  const ButtonComponent = ({ ...restProps }) => {
    return (
      <button> A </button>
    )
  }

  const [defaultExpandedRowIds] = useState([props.rows.findIndex(r => r.parent_id === 0)]);

  const [expandedRowIds, setExpandedRowIds] = useState(defaultExpandedRowIds);

  const handleExpandedRows = (expandedRows) => {
    setExpandedRowIds(expandedRows);
  }

  return (
    <Grid
      rows={props.rows}
      columns={props.columns}
    >
      <TreeDataState
        defaultExpandedRowIds={defaultExpandedRowIds}
        onExpandedRowIdsChange={handleExpandedRows}
      />
      <SearchState />
      <DataTypeProvider
        for={props.filterColumns}
        availableFilterOperations={filterOperations}
      />
      <FilteringState
        columnExtensions={props.filteringColumnExtensions}
      />
      <SortingState />
      <SelectionState />
      <CustomTreeData
        getChildRows={getChildRows}
      />
      <IntegratedFiltering />
      <IntegratedSelection />
      <IntegratedSorting />
      <VirtualTable
        tableComponent={TableComponent}
        columnExtensions={props.tableColumnExtensions}
      />
      <TableColumnVisibility
      />
      <TableHeaderRow
        showSortingControls
      />
      {/* Hiding below for beta release */}
      {/* <TableFilterRow showFilterSelector /> */}
      <TableTreeColumn
        for={props.treeColumnName}
        cellComponent={CellComponent}
      />
      <Toolbar />
      <SearchPanel />
    </Grid>
  )
}
