import React, { Component, PureComponent } from "react";
import PrivateRoute from "../Routes/PrivateRoutes";
import { Route, Switch, Redirect } from "react-router";
import constants from "../Utils/constants";
import ListCompanies from "../Pages/ListCompanies";
import CompanyDetails from "../Pages/CompanyDetails";
import ProjectDetails from "../Pages/ProjectDetails";
import ListTaxonomies from "../Pages/ListTaxonomies";
import ListClientUsers from "../Pages/ListClientUsers";
import ManageClientUser from "../Pages/ManageClientUser";
import AccessDenied from "../Pages/AccessDenied";
import NotFound from "../Pages/NotFound";
import listProjects from "../Pages/listProjects";
import MyBoards from "../Pages/MyBoards";
import BoardDetails from "../Pages/BoardDetails";
import ListBenchmarks from "../Pages/ListBenchmarks";
import BenchmarkDetails from "../Pages/BenchmarkDetails";
import ListMarketForecasts from "../Pages/ListMarketForecasts";
import MarketForecastDetails from "../Pages/MarketForecastDetails";
import ListMarketLandscapes from "../Pages/ListMarketLandscapes";
import MarketLandscapeDetails from "../Pages/MarketLandscapeDetails";
import QuantData from "../Pages/QuantData";
import DataVisualization from "../Pages/DataVisualization";
import VisualizationCreateChart from "./V2/Visualization/VisualizationCreateChart";
import Dashboard from "./Dashboard";

import Faqs from "./Faqs";
import SpecialReportDetails from "./SpecialReportDetails";
import ListSpecialReports from "./ListSpecialReports";
import GlobalSearch from "../Components/globalSearch";
import WebinarCalendar from "./WebinarCalendar";
import ListCustomerStudies from "./ListCustomerStudies";
import ListEcosystems from "./ListEcosystems";
import CustomerStudyDetails from "./CustomerStudyDetails";
import EcosystemDetails from "./EcosystemDetails";
import PreferenceCenter from "./V2/PreferenceCenter";
import PreferenceCenterOld from "./PreferenceCenterOld";
import VisualizationPreview from "../Containers/V2/VisalizationV2/VisualizationPreview";
import ResourcesAndFAQs from "../Pages/ResourceAndFAQ";

class Content extends Component {
  render() {
    return (
      <Switch>
        {/* <PrivateRoute
          acl={false}
          restricted={false}
          path={"/globalsearch"}
          exact
          component={GlobalSearch}
        /> */}
        <PrivateRoute
          acl={false}
          restricted={false}
          path={constants.APPLICATION_ROUTE.COMPANY.LIST.ROUTE}
          exact
          component={ListCompanies}
        />
        <PrivateRoute
          acl={false}
          restricted={false}
          path={constants.APPLICATION_ROUTE.COMPANY.QUANTDATA.ROUTE}
          exact
          component={QuantData}
        />
        <PrivateRoute
          acl={false}
          restricted={false}
          path={constants.APPLICATION_ROUTE.COMPANY.VIEW.ROUTE}
          exact
          component={CompanyDetails}
        />
        <PrivateRoute
          acl={false}
          restricted={false}
          path={constants.APPLICATION_ROUTE.PROJECT.VIEW.ROUTE}
          exact
          component={ProjectDetails}
        />
        <PrivateRoute
          acl={false}
          restricted={false}
          path={constants.APPLICATION_ROUTE.TAXONOMY.LIST.ROUTE}
          exact
          component={ListTaxonomies}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.CLIENT_USER_RESOURCE.TITLE}
          action={constants.ACL.CLIENT_USER_RESOURCE.ACTIONS.LIST}
          restricted={false}
          path={constants.APPLICATION_ROUTE.CLIENT_USER.LIST.ROUTE}
          exact
          component={ListClientUsers}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.CLIENT_USER_RESOURCE.TITLE}
          action={constants.ACL.CLIENT_USER_RESOURCE.ACTIONS.CREATE}
          restricted={false}
          path={constants.APPLICATION_ROUTE.CLIENT_USER.CREATE.ROUTE}
          exact
          component={ManageClientUser}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.CLIENT_USER_RESOURCE.TITLE}
          action={constants.ACL.CLIENT_USER_RESOURCE.ACTIONS.EDIT}
          restricted={false}
          path={constants.APPLICATION_ROUTE.CLIENT_USER.EDIT.ROUTE}
          exact
          component={ManageClientUser}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.MY_BOARDS_RESOURCE.TITLE}
          action={constants.ACL.MY_BOARDS_RESOURCE.ACTIONS.LIST}
          restricted={false}
          path={constants.APPLICATION_ROUTE.MY_BOARDS.LIST.ROUTE}
          exact
          component={MyBoards}
        />

        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.MY_BOARDS_RESOURCE.TITLE}
          action={constants.ACL.MY_BOARDS_RESOURCE.ACTIONS.LIST}
          restricted={false}
          path={constants.APPLICATION_ROUTE.DATA_VISUALIZATION.VIEW.ROUTE}
          exact
          component={DataVisualization}
        />
        <PrivateRoute
          acl={false}
          restricted={false}
          path={constants.APPLICATION_ROUTE.VISUALIZATION.CREATE.ROUTE}
          exact
          component={VisualizationCreateChart}
        />
        <PrivateRoute
          acl={false}
          restricted={false}
          path={constants.APPLICATION_ROUTE.VISUALIZATION.PREVIEW_WITH_ID.ROUTE}
          exact
          component={VisualizationPreview}
        />

        <PrivateRoute
          acl={false}
          restricted={false}
          path={constants.APPLICATION_ROUTE.PROJECT.LIST.ROUTE}
          exact
          component={listProjects}
        />
        <PrivateRoute
          acl={false}
          restricted={false}
          path={constants.APPLICATION_ROUTE.MY_BOARDS.DASHBOARD.ROUTE}
          exact
          component={BoardDetails}
        />
        <PrivateRoute
          acl={false}
          restricted={false}
          path={constants.APPLICATION_ROUTE.BENCHMARK.VIEW.ROUTE}
          exact
          component={BenchmarkDetails}
        />
        <PrivateRoute
          acl={false}
          restricted={false}
          path={constants.APPLICATION_ROUTE.BENCHMARK.LIST.ROUTE}
          exact
          component={ListBenchmarks}
        />
        <PrivateRoute
          acl={false}
          restricted={false}
          path={constants.APPLICATION_ROUTE.MARKET_FORECAST.LIST.ROUTE}
          exact
          component={ListMarketForecasts}
        />
        <PrivateRoute
          acl={false}
          restricted={false}
          path={constants.APPLICATION_ROUTE.MARKET_FORECAST.VIEW.ROUTE}
          search={"?category_id=:id"}
          exact
          component={MarketForecastDetails}
        />
        <PrivateRoute
          acl={false}
          restricted={false}
          path={constants.APPLICATION_ROUTE.MARKET_LANDSCAPE.LIST.ROUTE}
          exact
          component={ListMarketLandscapes}
        />
        <PrivateRoute
          acl={false}
          restricted={false}
          path={constants.APPLICATION_ROUTE.MARKET_LANDSCAPE.VIEW.ROUTE}
          exact
          component={MarketLandscapeDetails}
        />
        <PrivateRoute
          acl={false}
          restricted={false}
          path={constants.APPLICATION_ROUTE.ACCESS_DENIED.ROUTE}
          exact
          component={AccessDenied}
        />
        <PrivateRoute
          acl={false}
          restricted={false}
          path={constants.APPLICATION_ROUTE.FAQS.LIST.ROUTE}
          exact
          component={Faqs}
        />
        <PrivateRoute
          acl={false}
          restricted={false}
          path={constants.APPLICATION_ROUTE.SPECIAL_REPORTS.LIST.ROUTE}
          exact
          component={ListSpecialReports}
        />
        <PrivateRoute
          acl={false}
          restricted={false}
          path={constants.APPLICATION_ROUTE.SPECIAL_REPORTS.VIEW.ROUTE}
          exact
          component={SpecialReportDetails}
        />

        <PrivateRoute
          acl={false}
          restricted={false}
          path={constants.APPLICATION_ROUTE.DASHBOARD_LIST.ROUTE}
          exact
          component={Dashboard}
        />

        <PrivateRoute
          acl={false}
          restricted={false}
          path={constants.APPLICATION_ROUTE.WEBINAR_CALENDAR.LIST.ROUTE}
          exact
          component={WebinarCalendar}
        />

        <PrivateRoute
          acl={false}
          restricted={false}
          path={constants.APPLICATION_ROUTE.CUSTOMER_STUDIES.LIST.ROUTE}
          exact
          component={ListCustomerStudies}
        />

        <PrivateRoute
          acl={false}
          restricted={false}
          path={constants.APPLICATION_ROUTE.CUSTOMER_STUDIES.VIEW.ROUTE}
          exact
          component={CustomerStudyDetails}
        />

        <PrivateRoute
          acl={false}
          restricted={false}
          path={constants.APPLICATION_ROUTE.ECOSYSTEM.LIST.ROUTE}
          exact
          component={ListEcosystems}
        />

        <PrivateRoute
          acl={false}
          restricted={false}
          path={constants.APPLICATION_ROUTE.ECOSYSTEM.VIEW.ROUTE}
          exact
          component={EcosystemDetails}
        />

        <PrivateRoute
          acl={false}
          restricted={false}
          path={constants.APPLICATION_ROUTE.MANAGE_PREFERENCES.ROUTE}
          exact
          component={PreferenceCenter}
        />

        <PrivateRoute
          acl={false}
          restricted={false}
          path={constants.APPLICATION_ROUTE.MANAGE_PREFERENCES_OLD.ROUTE}
          exact
          component={PreferenceCenterOld}
        />

        <PrivateRoute
          acl={false}
          restricted={false}
          path={constants.APPLICATION_ROUTE.GLOBAL_SEARCH.ROUTE}
          exact
          component={GlobalSearch}
        />

        <PrivateRoute
          acl={false}
          restricted={false}
          path={constants.APPLICATION_ROUTE.RESOURCES_AND_FAQS.LIST.ROUTE}
          exact
          component={ResourcesAndFAQs}
        />

        <Redirect
          exact
          from="/"
          to={constants.APPLICATION_ROUTE.DASHBOARD_LIST.ROUTE}
        />
        <Route path="*" exact component={NotFound} />
      </Switch>
    );
  }
}
export default Content;
