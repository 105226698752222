import React, { useState } from "react";
import "../Styles/ratingform.scss";

const StarRating = (props) => {
  const [rating, setRating] = useState(props.value);
  const [hover, setHover] = useState(props.value);

  const handleStarRating = (index) => {
    props.setState({ ...props.state, [props.field]: index });
    setRating(index);
  };

  return (
    <div className='star-rating'>
      {[...Array(5)].map((star, index) => {
        index += 1;
        return (
          <button
            type='button'
            key={index}
            className={index <= (hover || rating) ? "on" : "off"}
            onClick={() => handleStarRating(index)}
            onMouseEnter={() => setHover(index)}
            onMouseLeave={() => setHover(rating)}
            style={{ fontSize: `${props.fontSize}` }}
          >
            <span className='star'>&#9733;</span>
          </button>
        );
      })}
    </div>
  );
};

export default StarRating;
