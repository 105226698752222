import React, { useState, useEffect } from "react";
import Collapsible from "react-collapsible";
import constants from "../Utils/constants";

const publishedContentType = [
  {
    id: "check1",
    icon: "domain",
    name: constants.CALENDAR.PUBLISHED_TYPE.COMPANY,
    color: "green",
    is_selected: true,
    slug: constants.CALENDAR.PUBLISHED_SLUG.COMPANY,
  },
  {
    id: "check2",
    icon: "speeds",
    name: constants.CALENDAR.PUBLISHED_TYPE.BENCHMARK,
    color: "yellow",
    is_selected: true,
    slug: constants.CALENDAR.PUBLISHED_SLUG.BENCHMARK,
  },
  {
    id: "check3",
    icon: "summarize",
    name: constants.CALENDAR.PUBLISHED_TYPE.SPECIAL_REPORT,
    color: "blue",
    is_selected: true,
    slug: constants.CALENDAR.PUBLISHED_SLUG.SPECIAL_REPORT,
  },
  {
    id: "check4",
    icon: "trending_up",
    name: constants.CALENDAR.PUBLISHED_TYPE.MARKET_FORECAST,
    color: "skyblue",
    is_selected: true,
    slug: constants.CALENDAR.PUBLISHED_SLUG.MARKET_FORECAST,
  },
  {
    id: "check5",
    icon: "analytics",
    name: constants.CALENDAR.PUBLISHED_TYPE.MARKET_LANDSCAPE,
    color: "red",
    is_selected: true,
    slug: constants.CALENDAR.PUBLISHED_SLUG.MARKET_LANDSCAPE,
  },
  {
    id: "check6",
    icon: "person_search",
    name: constants.CALENDAR.PUBLISHED_TYPE.CUSTOMER_STUDIES,
    color: "violet",
    is_selected: true,
    slug: constants.CALENDAR.PUBLISHED_SLUG.CUSTOMER_STUDIES,
  },
  {
    id: "check7",
    icon: "public",
    name: constants.CALENDAR.PUBLISHED_TYPE.ECOSYSTEM,
    color: "pink",
    is_selected: true,
    slug: constants.CALENDAR.PUBLISHED_SLUG.ECOSYSTEM,
  },
];

const webinarTypesArray = [
  {
    id: 1,
    name: constants.CALENDAR.WEBINAR_TYPE.DRAFT,
    slug: constants.CALENDAR.SLUG.DRAFT,
    is_selected: true,
    color: "gray",
  },
  {
    id: 2,
    name: constants.CALENDAR.WEBINAR_TYPE.PUBLISHED,
    slug: constants.CALENDAR.SLUG.PUBLISHED,
    is_selected: true,
    color: "skateblue",
  },
];

const occurenceContentTypes = [
  { id: 1, name: "All", slug: "all", is_selected: true },
  { id: 1, name: "Today", slug: "today", is_selected: false },
  { id: 1, name: "Upcoming", slug: "upcoming", is_selected: false },
  { id: 1, name: "Past", slug: "past", is_selected: false },
];

const CalendarSidebar = ({ allEvents, displayEvents, setDisplayEvents }) => {
  const [webinarTypes, setWebinarTypes] = useState(webinarTypesArray);
  const [publishedTypes, setPublishedTypes] = useState(publishedContentType);
  const [occurenceTypes, setOccurenceTypes] = useState(occurenceContentTypes);
  const [collapseIsOpen1, setCollapseIsOpen1] = useState(true);
  const [collapseIsOpen3, setCollapseIsOpen3] = useState(true);
  const [companyCollapse, setCompanyCollapse] = useState(false);

  const handleWebinarCheckbox = (slug) => {
    const array = [];
    webinarTypes.forEach((checkbox) => {
      if (checkbox.slug === slug) {
        checkbox.is_selected = !checkbox.is_selected;
      }
      array.push(checkbox);
    });
    setWebinarTypes(array);
  };

  const handlePublishedCheckbox = (slug) => {
    const array = [];
    publishedTypes.forEach((checkbox) => {
      if (checkbox.slug === slug) {
        checkbox.is_selected = !checkbox.is_selected;
      }
      array.push(checkbox);
    });
    setPublishedTypes(array);
  };

  const handleOccurenceCheckbox = (type) => {
    const occurenceArray = [];
    occurenceTypes.forEach((checkbox) => {
      if (checkbox.slug === type) {
        checkbox.is_selected = true;
      } else {
        checkbox.is_selected = false;
      }
      occurenceArray.push(checkbox);
    });
    setOccurenceTypes(occurenceArray);
  };

  const handleFilterCalendarData = () => {
    const todayDate = new Date();
    const occurenceResult = []; // occurence result
    const occurenceType = occurenceTypes.find(
      (occurence) => occurence.is_selected === true
    );
    // get data of occurence type and stored them into occurenceResult
    allEvents.forEach((event) => {
      let eventDate = new Date(event.start);
      if (occurenceType.slug === "today") {
        if (
          eventDate.getFullYear() === todayDate.getFullYear() &&
          eventDate.getMonth() === todayDate.getMonth() &&
          eventDate.getDate() === todayDate.getDate()
        ) {
          occurenceResult.push(event);
        }
      } else if (occurenceType.slug === "upcoming") {
        eventDate > todayDate && occurenceResult.push(event);
      } else if (occurenceType.slug === "past") {
        eventDate < todayDate && occurenceResult.push(event);
      } else {
        occurenceResult.push(event);
      }
    });
    let finalResult = [];
    publishedTypes.forEach((published) => {
      if (published.is_selected) {
        occurenceResult.forEach((event) => {
          if (event?.report_type === published.slug || event?.type =="webinar") {
            finalResult.push(event);
          }
        });
      } else {
        let array = finalResult;
        array.forEach((event, index) => {
          if (event.report_type === published.slug) {
            finalResult.splice(index, 1);
          }
        });
      }
    });
    setDisplayEvents(finalResult);
  };
  useEffect(() => {
    handleFilterCalendarData();
  }, [webinarTypes, publishedTypes, occurenceTypes]);

  return (
    <>
      <h2>Published/Webinar Calendar</h2>
      <Collapsible
        open={collapseIsOpen1}
        trigger={
          <div
            onClick={() => setCollapseIsOpen1(!collapseIsOpen1)}
            className="published-collapse"
          >
            <h3 className="">Published Content Type</h3>
            <span
              style={{ color: "#555555" }}
              className="material-icons-outlined material-icon mr-2"
            >
              {collapseIsOpen1 ? "expand_less" : "expand_more"}
            </span>
          </div>
        }
      >
        <div className="mt-2">
          {publishedTypes.map((checkbox) => (
            <div key={checkbox.id}>
              <div
                style={{ paddingRight: "10px" }}
                className="d-flex justify-content-between"
              >
                <div className="d-flex">
                  <div className="sb-checkbox">
                    <input
                      type="checkbox"
                      className="sb-checkbox__input"
                      checked={checkbox.is_selected}
                      id={checkbox.id}
                      onChange={() => handlePublishedCheckbox(checkbox.slug)}
                    />
                    <label
                      className={`sb-checkbox__label sb-checkbox__label--${checkbox.color}`}
                      htmlFor={checkbox.id}
                    ></label>
                  </div>
                  <span className="material-icons-outlined icon-fs-18 published-calendar-icon">
                    {checkbox.icon}
                  </span>
                  <p className="published-checkbox-label">{checkbox.name}</p>
                </div>
                {checkbox.icon === "domain" && (
                  <span
                    onClick={() => setCompanyCollapse(!companyCollapse)}
                    className="material-icons-outlined icon-fs-20 cursor-pointer"
                  >
                    {companyCollapse ? "expand_more" : "chevron_right"}
                  </span>
                )}
              </div>
              {checkbox.icon === "domain" && (
                <div
                  className={
                    companyCollapse
                      ? "company-report-container"
                      : "company-report-none"
                  }
                >
                  <div
                    className={
                      companyCollapse
                        ? "specific-report"
                        : "specific-report-none"
                    }
                  >
                    <div className="company-round-icon"></div>
                    <p>Company Research</p>
                  </div>
                  <div
                    className={
                      companyCollapse
                        ? "specific-report"
                        : "specific-report-none"
                    }
                  >
                    <div className="company-round-icon"></div>
                    <p>Company Snapshot</p>
                  </div>
                  <div
                    className={
                      companyCollapse
                        ? "specific-report"
                        : "specific-report-none"
                    }
                  >
                    <div className="company-round-icon"></div>
                    <p>Company Profile</p>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </Collapsible>

      <Collapsible
        open={collapseIsOpen3}
        trigger={
          <div
            onClick={() => setCollapseIsOpen3(!collapseIsOpen3)}
            className="published-collapse mb-2"
          >
            <h3 className="">Occurence Type</h3>
            <span
              style={{ color: "#555555" }}
              className="material-icons-outlined material-icon mr-2"
            >
              {collapseIsOpen3 ? "expand_less" : "expand_more"}
            </span>
          </div>
        }
      >
        <div>
          {occurenceTypes.map((occurence, index) => (
            <div key={`${occurence.id}_${index}`} className="d-flex">
              <input
                className="mt-1"
                type="checkbox"
                checked={occurence.is_selected}
                onChange={() => handleOccurenceCheckbox(occurence.slug)}
              />
              <p className="webinar-checkbox-label">{occurence.name}</p>
            </div>
          ))}
        </div>
      </Collapsible>
    </>
  );
};

export default CalendarSidebar;
