import React, { memo, useEffect, useRef, useState } from "react";
import ReactHtmlParser from "react-html-parser";

const TemplateParser = (props) => {
  const containerWidthRef = useRef(null);
  const [widthOfContainer, setContainerWidth] = useState(null);

  const emptyRemainingSlugs = (replacedTemplate) => {
    const paramsPattern = /[^{}]+(?=})/g;
    let extractParams = replacedTemplate.match(paramsPattern);
    if (extractParams && extractParams.length > 0) {
      extractParams.forEach((param) => {
        replacedTemplate = replacedTemplate.replace(
          "{" + param + "}",
          "&nbsp;"
        );
      });
    }
    return replacedTemplate;
  };

  const styleConverter = (s) => {
    let style = {};
    let properties = s.split(";");
    properties.forEach((p) => {
      let propertyNames = p.split(":");
      if (propertyNames && propertyNames.length > 0)
        style[
          propertyNames[0].replace(/-([a-z])/g, function (g) {
            return g[1].toUpperCase();
          })
        ] = propertyNames[1];
    });
    return style;
  };

  const renderSvgContent = (svg_string) => {
    let new_width = `${widthOfContainer}`;
    let new_height = `${(new_width * 7.5) / 10}`;
    let new_svg_string = props.isCompany
      ? svg_string
          .replace(/width="[^"]*"/, `width="${new_width}px"`)
          .replace(/height="[^"]*"/, `height="${new_height - 10}px"`)
      : svg_string;
    return (
      <div
        className="font-Calibri"
        dangerouslySetInnerHTML={{ __html: new_svg_string }}
      />
    );
  };

  // const parseHtml = () => {
  //   let content = {};
  //   let template = props.template;
  //   try {
  //     content = JSON.parse(props.content);
  //     Object.keys(content).forEach((ind) => {
  //       if (content[ind].type) {
  //         let replacer = "";
  //         replacer = `<div class='${
  //           props.isDeleted ? "content_deleted" : ""
  //         }'><custom class='${ind}' /></div>`;
  //         template = template.replace("{" + ind + "}", replacer);
  //       }
  //     });
  //   } catch (e) {}
  //   return emptyRemainingSlugs(template);
  // };

  const parseHtml = (ele) => {
    let content = {};
    let template = props.template;

    try {
      content = JSON.parse(props.content);
      Object.keys(content).forEach((ind) => {
        if (content[ind].type) {
          const checkingTemplate = template.indexOf(ind);
          if (ind === "left_over_data" && checkingTemplate === -1) {
            template +=
              '<div class="row"><div class="col-lg-12">{left_over_data}</div><div class="col-lg-12">{left_over_images}</div></div>';
          }

          let replacer = "";
          replacer = `<div class='${
            props.isDeleted ? "content_deleted" : ""
          }'><custom class='${ind}' /></div>`;

          template = template.replace("{" + ind + "}", replacer);
        }
      });
      return emptyRemainingSlugs(template);
    } catch (e) {
      console.log("parse html error: ", e);
    }
  };

  const transform = (node) => {
    let content = JSON.parse(props.content);

    if (node.type === "tag" && node.name === "custom") {
      if (
        content[node.attribs.class] &&
        content[node.attribs.class].type === "text"
      ) {
        return (
          <>
            {content[node.attribs.class].value.trim().length > 0 &&
              widthOfContainer &&
              renderPublishSvgContent(content[node.attribs.class].value)}
          </>
        );
      } else if (
        content[node.attribs.class] &&
        content[node.attribs.class].type === "image" &&
        !Array.isArray(content[node.attribs.class].value)
      ) {
        let prefix = "";
        let temp = content[node.attribs.class].value
          ? content[node.attribs.class].value
          : "";
        content[node.attribs.class].value = temp;
        if (temp.startsWith("http")) {
          prefix = "";
        } else if (temp.startsWith("data:")) {
        } else prefix = process.env.REACT_APP_TBRI_IMAGE_URL;
        return (
          <>
            <div className="d-flex">
              <div className="mt-11px overflow-auto font-Calibri">
                {content[node.attribs.class].value.trim().length > 0 && (
                  <img
                    style={styleConverter(
                      content[node.attribs.class].style
                        ? content[node.attribs.class].style
                        : ""
                    )}
                    src={`${prefix + content[node.attribs.class].value}`}
                    alt={"content pic"}
                    className={"content_image"}
                  />
                )}
              </div>
              <div className="mt-11px overflow-auto font-Calibri">
                {content[node.attribs.class].value.trim().length === 0 &&
                  props.isEditable && <p>{props.emptyContentMessage}</p>}
              </div>
            </div>
          </>
        );
      } else {
        return (
          <>
            <div className="d-flex align-items-start my-3"></div>
          </>
        );
      }
    }
  };

  const renderPublishSvgContent = (svg_string) => {
    let new_width = `${widthOfContainer - 200}`;
    let new_height = `${(new_width * 7.5) / 10}`;
    let new_svg_string = svg_string
      .replace(/width="[^"]*"/, `width="${new_width}px"`)
      .replace(/height="[^"]*"/, `height="${new_height - 10}px"`);
    return (
      <div
        className="font-Calibri"
        dangerouslySetInnerHTML={{ __html: new_svg_string }}
      />
    );
  };

  useEffect(() => {
    let containerWidth = containerWidthRef.current?.offsetWidth;
    setContainerWidth(Number(containerWidth));
  }, []);

  return (
    <div ref={containerWidthRef} className="d-flex">
      {ReactHtmlParser(parseHtml(), { transform: transform })}
    </div>
  );
};

export default memo(TemplateParser);
