import React, { Component } from "react";
import constants from "../Utils/constants";
import { connect } from "react-redux";
import { activeMenuTab } from "../Store/Actions/User";
import { clients } from "../Store/Actions/Client";
import { segments } from "../Store/Actions/User";
import DailogNew from "../Components/DailogNew";
import CustomDropdownList from "../Components/CustomDropdownList";
import CustomReactTooltip from "../Components/CustomReactTooltip";
import Search from "../Components/searchComponent";
import Slugify from "../Utils/Slugify";
import DocumentTitle from "../Utils/DocumentTitle";
import { CompanyServices } from "../Services/Company";
import Toast from "../Utils/Toast";
import queryString from "query-string";
import { formatRoute } from "react-router-named-routes";
import { CustomerStudiesServices } from "../Services/CustomerStudies";
import CustomEllipsis from "../Utils/CustomEllipsis";
import ComapnyDetailsDateTimeFormatter from "../Utils/ComapnyDetailsDateTimeFormatter";
import permissionCheck from "../lib/permissionCheck";
import DynamicTableComponent from "../Components/DynamicTableComponent";
import HelpModal from "./HelpModal";

const tableHeader = [
  {
    id: "name",
    title: "Name",
    sortable: true,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    visibility: true,
  },
  {
    id: "is_active",
    title: "Status",
    sortable: true,
    headerClass: "text-center",
    rowStyle: { textAlign: "center" },
    visibility: true,
  },
  {
    id: "segment_id",
    title: "Segment",
    sortable: true,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    visibility: true,
  },
  {
    id: "additional_segments",
    title: "Additional Segments",
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    visibility: true,
  },
  {
    id: "companies",
    title: "Companies",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: {
      textTransform: "capitalize",
      position: "inherit",
      textAlign: "left",
    },
    rowClass: "position-inherit-important",
    visibility: true,
  },
  {
    id: "updated_at",
    title: "Last Modified At",
    sortable: true,
    style: { textAlign: "left" },
    rowStyle: {},
  },
];
const showActions = false;

class ListCustomerStudies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: [],
      sortColumn: constants.PAGINATION.DEFAULT_SORT_COLUMN,
      sortOrder: constants.PAGINATION.DEFAULT_SORT_ORDER,
      pageNo: constants.PAGINATION.DEFAULT_PAGE_NO,
      pageSize: constants.PAGINATION.DEFAULT_PAGE_SIZE,
      name: "",
      companyId: null,
      segmentId: null,
      dailogModal: false,
      dailogModalContent: "",
      dailogModalHeader: "",
      dailogModalConfig: {},
      dailogModalStyleType: "",
      currentPageDataCount: 0,
      disableStatus: false,
    };
  }

  componentDidMount() {
    DocumentTitle(constants.APPLICATION_ROUTE.CUSTOMER_STUDIES.LIST.NAME);
    let params = queryString.parse(this.props.location.search);
    this.setState(
      {
        pageNo: params.page
          ? parseInt(params.page)
          : constants.PAGINATION.DEFAULT_PAGE_NO,
        pageSize: params.page_size
          ? parseInt(params.page_size)
          : constants.PAGINATION.DEFAULT_PAGE_SIZE,
        sortColumn: params.sort_column
          ? params.sort_column
          : constants.PAGINATION.DEFAULT_SORT_COLUMN,
        sortOrder: params.sort_order
          ? params.sort_order
          : constants.PAGINATION.DEFAULT_SORT_ORDER,
        name: params.search_value ? params.search_value : "",
        companyId: params.companyId ? params.companyId : "all",
        segmentId: params.segmentId ? params.segmentId : "all",
      },
      () => {
        this.getSegments();
        this.getCompanies();
        this.getTableData();
      }
    );
    this.props.activeMenuTab(
      constants.APPLICATION_ROUTE.CUSTOMER_STUDIES.LIST.ROUTE
    );
  }

  getSegments = () => {
    CompanyServices.getSegmentDetails(0)
      .then((res) => {
        let response = res && res.data && res.data.data;
        let segments = [];
        segments.push({ id: "all", name: "All Segments" });
        response = response.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase()
            ? 1
            : b.name.toLowerCase() > a.name.toLowerCase()
            ? -1
            : 0
        );
        let totalSegments = segments.concat(response);
        totalSegments.forEach((r) => {
          r.is_selected = r.id == this.state.segmentId ? true : false;
        });
        this.props.setSegments(totalSegments);
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  getCompanies = () => {
    CompanyServices.getTotalCompanies("companylist")
      .then((res) => {
        let response = res && res.data && res.data.data;
        let companies = [];
        companies.push({ id: "all", name: "All Companies" });
        response = response.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase()
            ? 1
            : b.name.toLowerCase() > a.name.toLowerCase()
            ? -1
            : 0
        );
        let totalCompanies = companies.concat(response);
        totalCompanies.forEach((r) => {
          r.is_selected = r.id == this.state.companyId ? true : false;
        });
        this.setState({
          companies: totalCompanies,
        });
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  goToCustomerStudies = (access, customerStudies) => {
    if (access) {
      this.props.history &&
        this.props.history.push({
          pathname: formatRoute(
            constants.APPLICATION_ROUTE.CUSTOMER_STUDIES.VIEW.ROUTE,
            { id: customerStudies.id }
          ),
        });
    } else {
      let dailogModalHeader = (
        <>
          <h3 className="text-darkblue2 font-weight-bold">
            {constants.WARNING.CONTENT_ACCESS_DENIED_TITLE}
          </h3>
        </>
      );
      let dailogModalContent = (
        <>
          <div className="noaccess-body">
            <h2 className="font-weight-semibold mb-0">
              {constants.WARNING.CONTENT_ACCESS_DENIED_TEXT}
            </h2>
          </div>
        </>
      );
      this.setState({
        dailogModal: true,
        dailogModalHeader: dailogModalHeader,
        dailogModalContent: dailogModalContent,
        dailogModalConfig: { type: false, id: null },
        dailogModalStyleType: "noaccess-modal",
      });
    }
  };

  getTableData = () => {
    let queryParams = "";
    if (this.state.sortOrder) {
      queryParams =
        "&sort_order=" +
        this.state.sortOrder +
        "&sort_column=" +
        this.state.sortColumn;
    }
    if (this.state.name !== "") {
      queryParams += "&search_column=name&search_value=" + this.state.name;
    }
    if (this.state.companyId !== "" && this.state.companyId !== "all") {
      queryParams += "&companyId=" + this.state.companyId;
    }
    if (this.state.segmentId !== "" && this.state.segmentId !== "all") {
      queryParams += "&segmentId=" + this.state.segmentId;
    }
    this.changeRoute();
    let url = "/customer-study/list";
    CustomerStudiesServices.getCustomerStudies(
      `${url}?page=` +
        this.state.pageNo +
        "&page_size=" +
        this.state.pageSize +
        queryParams
    )
      .then((res) => {
        let clientData = res.data.data;

        let checkContentPageAccess = permissionCheck(
          constants.ACL.PERMISSION_KEY,
          constants.ACL.CUSTOMER_STUDY_RESOURCE.TITLE,
          constants.ACL.CUSTOMER_STUDY_RESOURCE.ACTIONS.MANAGE
        );

        let count =
          (res.data && res.data.meta && res.data.meta.total_count) || 0;
        clientData = clientData.map((data) => {
          let companiesTooltip = CustomEllipsis(
            data.companies && data.companies.length > 0
              ? data.companies.map((c) => c.name).join(", ")
              : "-"
          );
          const additionalSegments = CustomEllipsis(
            this.props?.home?.segments
              ?.filter((segment) =>
                data?.supported_segments?.includes(segment.id)
              )
              ?.map((c) => c.name)
              .join(", ")
          ) ?? { text: "-", ellipsis: false, fullText: "" };
          let custStudiesRepoName = CustomEllipsis(data.name);
          return {
            id: data.id,
            name: (
              <span
                style={{ padding: 0, color: "#1085c6", cursor: "pointer" }}
                onClick={() => {
                  this.goToCustomerStudies(
                    data.is_allowed ? true : false,
                    data
                  );
                }}
              >
                <span className="datagrid_tooltip">
                  {custStudiesRepoName.text}
                  {custStudiesRepoName.ellipsis && (
                    <span
                      className="material-icons-round icon"
                      data-for={`tooltip-companies-${data.id}`}
                      data-tip={custStudiesRepoName.fullText}
                      data-iscapture="true"
                    >
                      info
                      <CustomReactTooltip
                        id={`tooltip-companies-${data.id}`}
                        multiline={true}
                      />
                    </span>
                  )}
                </span>
              </span>
            ),
            is_active: data.is_allowed ? (
              data.is_active === 1 ? (
                <span
                  onClick={() => {
                    this.goToCustomerStudies(true, data);
                  }}
                  className="material-icons-round icon allowed-access text-green"
                  aria-hidden="true"
                  data-for={`tooltip-${data.id}-active`}
                  data-tip={"Active"}
                  data-iscapture="true"
                >
                  circle
                  <CustomReactTooltip
                    id={`tooltip-${data.id}-active`}
                    multiline={true}
                  />
                </span>
              ) : (
                <span
                  onClick={() => {
                    this.goToCustomerStudies(true, data);
                  }}
                  className="material-icons-round icon text-orange allowed-access allowed-access-warning"
                  aria-hidden="true"
                  data-for={`tooltip-${data.id}-inactive`}
                  data-tip={"Inactive"}
                  data-iscapture="true"
                >
                  circle
                  <CustomReactTooltip
                    id={`tooltip-${data.id}-inactive`}
                    multiline={true}
                  />
                </span>
              )
            ) : (
              <>
                <span
                  onClick={() => {
                    this.goToCustomerStudies(false, data);
                  }}
                  className="material-icons-round icon not-allowed-access text-grey"
                  aria-hidden="true"
                  data-for={`tooltip-${data.id}-noaccess`}
                  data-tip={"No Access"}
                  data-iscapture="true"
                >
                  circle
                  <CustomReactTooltip
                    id={`tooltip-${data.id}-noaccess`}
                    multiline={true}
                  />
                </span>
              </>
            ),
            segment_id: `${
              data.segment && data.segment.id ? data.segment.name : "-"
            }`,
            additional_segments: (
              <span className="datagrid_tooltip">
                {additionalSegments.text || "-"}
                {additionalSegments.ellipsis && (
                  <span
                    className="material-icons-outlined icon"
                    data-for={`tooltip-segments-${data.id}`}
                    data-tip={additionalSegments.fullText.replace(
                      /,/g,
                      ",<br>"
                    )}
                    data-iscapture="true"
                  >
                    info
                    <CustomReactTooltip
                      id={`tooltip-segments-${data.id}`}
                      multiline={true}
                    />
                  </span>
                )}
              </span>
            ),
            companies: (
              <span className="datagrid_tooltip">
                {companiesTooltip.text}
                {companiesTooltip.ellipsis && (
                  <span
                    className="material-icons-round icon"
                    data-for={`tooltip-companies-${data.id}`}
                    data-tip={companiesTooltip.fullText.replace(/,/g, ",<br>")}
                    data-iscapture="true"
                  >
                    info
                    <CustomReactTooltip
                      id={`tooltip-companies-${data.id}`}
                      multiline={true}
                    />
                  </span>
                )}
              </span>
            ),
            updated_at: data.updated_at
              ? ComapnyDetailsDateTimeFormatter(data.updated_at, false)
              : ComapnyDetailsDateTimeFormatter(new Date(), false),
          };
        });
        this.setState({ rowData: clientData, count: count });
      })
      .catch((error) => {
        this.setState({ rowData: [], count: 0 });

        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  changeRoute = () => {
    let queryParams = "";
    if (this.state.sortOrder) {
      queryParams =
        "&sort_order=" +
        this.state.sortOrder +
        "&sort_column=" +
        this.state.sortColumn;
    }
    if (this.state.name !== "") {
      queryParams += "&search_column=name&search_value=" + this.state.name;
    }
    if (this.state.companyId !== "") {
      queryParams += "&companyId=" + this.state.companyId;
    }
    if (this.state.segmentId !== "") {
      queryParams += "&segmentId=" + this.state.segmentId;
    }
    this.props.history &&
      this.props.history.push({
        //for unit testing in jest (this.
        pathname: formatRoute(
          constants.APPLICATION_ROUTE.CUSTOMER_STUDIES.LIST.ROUTE,
          {}
        ),
        search:
          "?page=" +
          this.state.pageNo +
          "&page_size=" +
          this.state.pageSize +
          queryParams,
      });
  };

  pagePrevious = () => {
    this.setState(
      (prevState) => ({
        pageNo: prevState.pageNo - 1,
      }),
      () => this.getTableData()
    );
  };

  pageNext = () => {
    this.setState(
      (prevState) => ({
        pageNo: prevState.pageNo + 1,
      }),
      () => this.getTableData()
    );
  };

  handlePage = (input) => {
    this.setState({ pageNo: input }, () => this.getTableData());
  };

  handleSort = (clickedColumn, key) => {
    this.setState(
      {
        sortColumn: clickedColumn,
        segmentSort: false,
        sortOrder: this.state.sortOrder === "desc" ? "asc" : "desc",
      },
      () => this.getTableData()
    );
  };

  handleSearch = () => {
    this.setState({ pageNo: 1 }, () => {
      this.getTableData();
    });
  };

  handlePageSize = (e) => {
    this.setState({ pageSize: e.target.value, pageNo: 1 }, () => {
      this.getTableData();
    });
  };

  handleInput = (e) => {
    this.setState({ name: e.target.value });
  };

  rowAction = (action, data, e) => {
    e.stopPropagation();
  };

  handleSegmentChange = (e) => {
    let segments = [...this.props.home.segments];
    segments.forEach((c) => {
      c.is_selected = c.id === e.id ? true : false;
    });
    this.props.setSegments(segments);
    let segmentId = segments.find((c) => c.is_selected === true)
      ? segments.find((c) => c.is_selected === true).id
      : "All";
    this.setState(
      {
        segmentId: segmentId,
        pageNo: 1,
      },
      () => {
        this.getTableData();
      }
    );
  };

  handleCompanyChange = (e) => {
    let companies = [...this.state.companies];
    companies.forEach((c) => {
      c.is_selected = c.id === e.id ? true : false;
    });
    this.setState(
      {
        companies: companies,
        companyId: e.id,
        pageNo: 1,
      },
      () => {
        this.getTableData();
      }
    );
  };

  dailogModalAccept = (config) => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
    });
  };

  dailogModalDecline = () => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
    });
  };

  render() {
    let segments =
      this.props.home &&
      this.props.home.segments &&
      this.props.home.segments.length > 0
        ? this.props.home.segments.filter((segment) => {
            return segment?.name?.slice(-3) !== " IR";
          })
        : [];
    let companies = this.state.companies ? this.state.companies : [];
    return (
      <aside className="main_content">
        <div className="m-3 mb-0 bg-white px-3 py-3 br_8 main_content_height">
          <div className="d-flex align-items-center flex-wrap title-section breadcrumb-mb-0">
            <h1 className="text-ligntblue2 font-weight-bold mb-0">
              {constants.APPLICATION_ROUTE.CUSTOMER_STUDIES.LIST.HEADER_TITLE}
            </h1>
            <div className="ml-auto">
              <HelpModal category="Customer Studies List" />
            </div>
          </div>

          <div className="row align-items-center mb-18px">
            <div className="col-lg-3 col-md-6 col-sm-6 col-12 clients_dropdown">
              <div className="w-100-search border-search search-box-shadow-2">
                <Search
                  handleSearch={this.handleSearch}
                  handleInput={this.handleInput}
                  value={this.state.name}
                  placeHolder={"Filter Name"}
                />
              </div>
            </div>
            {segments && segments.length > 0 && (
              <div className="col-lg-3 col-md-6 col-sm-6 col-12 clients_dropdown">
                <div className="w-100 custom-combobox-list">
                  <CustomDropdownList
                    data={segments}
                    handleChange={this.handleSegmentChange}
                    placeholder={"Filter Segment"}
                  />
                </div>
              </div>
            )}
            {companies && companies.length > 0 && (
              <div className="col-lg-3 col-md-6 col-sm-6 col-12 clients_dropdown">
                <div className="w-100 custom-combobox-list">
                  <CustomDropdownList
                    data={companies}
                    handleChange={this.handleCompanyChange}
                    placeholder={"Filter Company"}
                  />
                </div>
              </div>
            )}
            <div className="d-flex justify-content-end align-items-center flex-wrap ml-auto col">
              <div className="table-list-legends d-flex flex-wrap pb-0">
                <span className="d-inline-flex align-items-center mr-2">
                  <span
                    className="material-icons-round icon allowed-access-icon icon-fs-14"
                    aria-hidden="true"
                  >
                    circle
                  </span>
                  <h3 className="mb-0">Active</h3>
                </span>
                <span className="d-inline-flex align-items-center mr-2">
                  <span
                    className="material-icons-round icon inactive-allowed-access-icon icon-fs-14"
                    aria-hidden="true"
                  >
                    circle
                  </span>
                  <h3 className="mb-0">Inactive</h3>
                </span>
                <span className="d-inline-flex align-items-center mr-2">
                  <span
                    className="material-icons-round icon not-allowed-access-icon icon-fs-14"
                    aria-hidden="true"
                  >
                    circle
                  </span>
                  <h3 className="mb-0">No Access</h3>
                </span>
              </div>
            </div>
          </div>
          <div className={""}>
            <div className="w-100 custom-table-block custom-table-block-2 marketforecast-list-table-block sort-table-block th-nowrap center-th-align">
              <DynamicTableComponent
                columnData={tableHeader}
                rowData={this.state.rowData}
                pagePrevious={this.pagePrevious}
                pageNext={this.pageNext}
                pageSize={this.state.pageSize}
                pageNo={this.state.pageNo}
                handlePage={this.handlePage}
                handleSort={this.handleSort}
                sortColumn={this.state.sortColumn}
                sortOrder={this.state.sortOrder}
                count={this.state.count}
                rowAction={this.rowAction}
                colSpan={tableHeader.length}
                showActions={showActions}
                showPagination={true}
                handlePageSize={this.handlePageSize}
                paginationClass={"center-pagination custom-pagination-new"}
              />
            </div>

            {/* Tooltips for icons in table, don't delete */}
            {this.state.rowData.map((data) => {
              return (
                <>
                  <CustomReactTooltip
                    id={`tooltip-${Slugify(
                      constants.APPLICATION_ROUTE.CUSTOMER_STUDIES.LIST
                        .EDIT_CUSTOMER_STUDY_TOOLTIP
                    )}-${data.id}`}
                    multiline={true}
                  />
                  <CustomReactTooltip
                    id={`tooltip-${Slugify(
                      constants.APPLICATION_ROUTE.CUSTOMER_STUDIES.LIST
                        .REMOVE_CUSTOMER_STUDY_TOOLTIP
                    )}-${data.id}`}
                    multiline={true}
                  />
                </>
              );
            })}

            <DailogNew
              isOpen={this.state.dailogModal}
              accept={this.dailogModalAccept}
              decline={this.dailogModalDecline}
              header={this.state.dailogModalHeader}
              content={this.state.dailogModalContent}
              config={this.state.dailogModalConfig}
              modalStyleType={this.state.dailogModalStyleType}
            />
          </div>
        </div>
      </aside>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    activeMenuTab: (tab) => {
      dispatch(activeMenuTab(tab));
    },
    setClients: (allClients) => {
      dispatch(clients(allClients));
    },
    setSegments: (segmentsList) => {
      dispatch(segments(segmentsList));
    },
  };
}
const mapStateToProps = (state) => {
  return {
    home: state.home,
    client: state.client,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListCustomerStudies);
