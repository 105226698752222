import $ from "jquery";
import queryString from "query-string";
import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { formatRoute } from "react-router-named-routes";
import CustomReactTooltip from "../Components/CustomReactTooltip";
import DailogNew from "../Components/DailogNew";
import DynamicTableComponent from "../Components/DynamicTableComponent";
import SearchComponent from "../Components/searchComponent";
import { CompanyServices } from "../Services/Company";
import { activeMenuTab, segments } from "../Store/Actions/User";
import constants from "../Utils/constants";
import DocumentTitle from "../Utils/DocumentTitle";
import MoneyFormatter from "../Utils/MoneyFormatter";
import Slugify from "../Utils/Slugify";
import Toast from "../Utils/Toast";
import HelpModal from "./HelpModal";
import gaEvent from "../Utils/GAEvents";
import decodeJWT from "../lib/decodeJWT";
import Select, { components } from "react-select";
import { downloadExcelFile } from "../Helpers/Helper";
import AclAction from "../Components/ACL/AclAction";

import {
  VendorProfile,
  VendorSnapshot,
  InitialResponse,
  BenchmarkCoverage,
  VendorReport,
  ActiveVendorReport,
  ActiveVendorProfile,
  ActiveVendorSnapshot,
  ActiveBenchmarkCoverage,
  InactiveVendorSnapshot,
  InactiveVendorProfile,
  InactiveBenchmarkCoverage,
  InactiveVendorReport,
  NoAccessVendorReport,
  NoAccessBenchmarkCoverage,
  NoAccessVendorProfile,
  NoAccessVendorSnapshot,
} from "../Components/SVGComponents/SVGComponent";

const coverageTypeList = [
  {
    value: "dedicated_analyst",
    label: "Company Research",
    isSelected: false,
    icon: <VendorReport />,
  },
  {
    value: "analyst_team",
    label: "Company Profile",
    isSelected: false,
    icon: <VendorProfile />,
  },
  {
    value: "vendor_snapshot",
    label: "Company Snapshot",
    isSelected: false,
    icon: <VendorSnapshot />,
  },
  {
    value: "benchmark_coverage",
    label: "Benchmark Coverage",
    isSelected: false,
    icon: <BenchmarkCoverage />,
  },
];
class ListCompanies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: [],
      sortColumn: constants.PAGINATION.COMPANY_DEFAULT_SORT_COLUMN,
      sortOrder: constants.PAGINATION.COMPANY_DEFAULT_SORT_ORDER,
      pageNo: constants.PAGINATION.DEFAULT_PAGE_NO,
      pageSize: constants.PAGINATION.DEFAULT_PAGE_SIZE,
      columnData: [],
      segmentData: [],
      segmentSort: false,
      name: "",
      totalData: [],
      downloadAction: null,
      dailogModal: false,
      dailogModalContent: "",
      dailogModalHeader: "",
      dailogModalConfig: {},
      dailogModalStyleType: "",
      showClientAdminPopup: true,
      segmentsForDropdown: [],
      coverageType: [],
    };
  }
  componentDidMount() {
    localStorage.removeItem("segmentId");
    localStorage.removeItem("isBenchmark");
    let params = queryString.parse(this.props.location.search);
    this.props.activeMenuTab(constants.APPLICATION_ROUTE.COMPANY.LIST.ROUTE);
    DocumentTitle(constants.APPLICATION_ROUTE.COMPANY.LIST.NAME);
    this.setState(
      {
        pageNo: params.page
          ? parseInt(params.page)
          : constants.PAGINATION.DEFAULT_PAGE_NO,
        pageSize: params.page_size
          ? parseInt(params.page_size)
          : constants.PAGINATION.DEFAULT_PAGE_SIZE,
        sortColumn: params.sort_column
          ? params.sort_column
          : constants.PAGINATION.COMPANY_DEFAULT_SORT_COLUMN,
        sortOrder: params.sort_order
          ? params.sort_order
          : constants.PAGINATION.COMPANY_DEFAULT_SORT_ORDER,
        segmentSort:
          params.sort_column && params.sort_column === "segment_id"
            ? true
            : false,
        name: params.search_value ? params.search_value : "",
      },
      () => {
        this.getTableData();
      }
    );
  }
  pagePrevious = () => {
    this.setState(
      (prevState) => ({
        pageNo: prevState.pageNo - 1,
      }),
      () => this.getTableData()
    );
  };
  pageNext = () => {
    this.setState(
      (prevState) => ({
        pageNo: prevState.pageNo + 1,
      }),
      () => this.getTableData()
    );
  };
  handlePage = (input) => {
    this.setState({ pageNo: input }, () => this.getTableData());
  };
  handleSort = (clickedColumn, key) => {
    this.setState(
      {
        sortColumn: clickedColumn,
        segmentSort: false,
        sortOrder: this.state.sortOrder === "desc" ? "asc" : "desc",
      },
      () => this.getTableData()
    );
  };
  handleSearch = () => {
    this.setState({ pageNo: 1 }, () => {
      this.getTableData();
    });
  };
  formatPrice = (price) => {
    if (price && parseInt(price))
      return (
        "$" +
        price
          .toFixed(0)
          .toString()
          .replace(/./g, function (c, i, a) {
            return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
          })
      );
    else return 0;
  };
  getTableData = () => {
    let queryParams = "";
    let urlParams = "";
    if (this.state.sortOrder) {
      let segmentsQ = this.state.sortColumn.split("||");
      queryParams =
        "&sort_order=" +
        this.state.sortOrder +
        "&sort_column=" +
        this.state.sortColumn;
      if (segmentsQ.length > 0 && segmentsQ[1]) {
        queryParams =
          "&sort_column=segment_id&sort_value=" +
          segmentsQ[1].toString() +
          "&sort_order=" +
          this.state.sortOrder;
      }
      urlParams =
        "&sort_order=" +
        this.state.sortOrder +
        "&sort_column=" +
        this.state.sortColumn;
    }
    if (this.state.name !== "") {
      queryParams += "&search_column=name&search_value=" + this.state.name;
      urlParams += "&search_column=name&search_value=" + this.state.name;
    }
    // Adding segment_id from segmentsForDropdown array
    if (
      this.state.segmentsForDropdown &&
      this.state.segmentsForDropdown.length > 0
    ) {
      const segmentIds = this.state.segmentsForDropdown
        .map((segment) => segment.value)
        .join(",");
      queryParams += `&segment_id=${segmentIds}`;
      urlParams += `&segment_id=${segmentIds}`;
    }
    // Add coverage_type from coverageType array
    if (this.state.coverageType && this.state.coverageType.length > 0) {
      const coverageTypes = this.state.coverageType
        .map((coverageType) => coverageType.value)
        .join(",");
      queryParams += `&coverage_type=${coverageTypes}`;
      urlParams += `&coverage_type=${coverageTypes}`;
    }
    this.props.history.push({
      pathname: "/company/list",
      search:
        "?page=" +
        this.state.pageNo +
        "&page_size=" +
        this.state.pageSize +
        urlParams,
    });
    Promise.all([
      this.getLobs(),
      CompanyServices.getCompanies(
        // "companies?page=" +
        "companieslist?page=" +
          this.state.pageNo +
          "&page_size=" +
          this.state.pageSize +
          queryParams
      ),
    ])
      .then((values) => {
        let segmentData = values[0].data.data;
        segmentData.sort((a, b) => (a.name > b.name ? 1 : -1));
        this.props.segments(segmentData);
        this.postResolvingOfGetCompanies(segmentData, values[1].data);
      })
      .catch((error) => {
        this.setState({ columnData: [], rowData: [], count: 0 });
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  postResolvingOfGetCompanies = (segments, data) => {
    let segmentData = segments.filter((segment) => segment.is_active > 0);
    segmentData = segmentData.map((segment, key) => {
      return {
        title: segment.name,
        id: `${segment.name}||${segment.id}`,
        is_active: segment.is_active,
        key: segment.id,
        className: `segment-col text-center`,
        sortable: true,
      };
    });
    let companyData = data.data;
    let count = (data && data.meta && data.meta.total_count) || 0;
    companyData = companyData.map((data) => {
      let company_access = data.allowed_access
        ? data.allowed_access
        : data.segments.filter((s) => s.allowed_access === true).length > 0;
      return {
        id: data.id,
        name: (
          <span
            className={"link"}
            onClick={() => {
              this.goToDetail(company_access, data);
            }}
          >
            <label className="mb-0">{data.name}</label>
          </span>
        ),
        annual_income: this.formatPrice(data.annual_income),
        quarterly_growth: MoneyFormatter(data.quarterly_growth, "0.00"),
        yearly_growth: MoneyFormatter(data.yearly_growth, "0.00"),
        segments: data.segments.map((data) => data.name),
        org_segments: data.segments,
        allowed_access: data.allowed_access ? data.allowed_access : false,
        is_active: data.is_active ? data.is_active : 0,
        custom_classes: [data.allowed_access ? "" : "not_allowed_access"],
      };
    });
    let segmentObj = {};

    let columnData = [
      {
        title: "Company",
        id: "name",
        sortable: true,
        style: {
          textAlign: "left",
          textTransform: "capitalize",
          width: "200px",
          zIndex: 9,
        },
        className: `company_name`,
      },
    ];
    let newSegmentData = segmentData.filter(
      (d) => !d.title.includes("Earnings Response")
    );
    // this filter is for segment thing
    const selectedFilterSegmentData = this.state.segmentsForDropdown;
    if (selectedFilterSegmentData.length > 0) {
      const selectedValues = selectedFilterSegmentData.map(
        (segment) => segment.value
      );
      newSegmentData = newSegmentData.filter((segment) =>
        selectedValues.includes(segment.key)
      );
    }

    columnData = [...columnData, ...newSegmentData];
    let rowData = [];
    for (let company = 0; company < companyData.length; company++) {
      for (let segment = 0; segment < segmentData.length; segment++) {
        let obj = {};
        let ind = companyData[company].org_segments.findIndex(
          (o) => o.id === segmentData[segment].key
        );

        if (ind >= 0) {
          companyData[company].org_segments[ind].allowed_access = companyData[
            company
          ].allowed_access
            ? companyData[company].allowed_access
            : companyData[company].org_segments[ind].allowed_access
            ? companyData[company].org_segments[ind].allowed_access
            : false;
          companyData[company].org_segments[ind].company_segment_ic_is_active =
            companyData[company].is_active === 1
              ? companyData[company].org_segments[ind]
                  .company_segment_ic_is_active === 1
                ? companyData[company].org_segments[ind]
                    .company_segment_ic_is_active
                : 0
              : 0;
          const filteredCoverageTypes = this.state.coverageType;
          let checkCoverageType = filteredCoverageTypes.map(
            (test) => test.value
          );
          let action;
          action = companyData[company].org_segments[ind].allowed_access ? (
            companyData[company].org_segments[ind].company_segment.is_active ===
            1 ? (
              <>
                <span>
                  {companyData[company].org_segments[ind].company_segment
                    .coverage_type === "analyst_team" ? (
                    <>
                      <i
                        className="allowed-access-icon"
                        onClick={() => {
                          this.goToSegment(
                            companyData[company].org_segments[ind]
                              .allowed_access,
                            companyData[company],
                            segmentData[segment]
                          );
                        }}
                        data-for={`tooltip-${companyData[company].id}-${segmentData[segment].id}-active`}
                        data-tip={
                          segmentData[segment].title.includes(
                            "Earnings Response"
                          )
                            ? "Active Earnings Response"
                            : "Active Full Report"
                        }
                      >
                        {/* <span class="material-icons-outlined">group</span> */}
                        <ActiveVendorProfile />
                      </i>
                      <CustomReactTooltip
                        id={`tooltip-${companyData[company].id}-${segmentData[segment].id}-active`}
                        multiline={true}
                      />
                    </>
                  ) : companyData[company].org_segments[ind].company_segment
                      .coverage_type === "dedicated_analyst" ? (
                    <>
                      <i
                        className="allowed-access-icon"
                        onClick={() => {
                          this.goToSegment(
                            companyData[company].org_segments[ind]
                              .allowed_access,
                            companyData[company],
                            segmentData[segment]
                          );
                        }}
                        data-for={`tooltip-${companyData[company].id}-${segmentData[segment].id}-active`}
                        data-tip={
                          segmentData[segment].title.includes(
                            "Earnings Response"
                          )
                            ? "Active Earnings Response"
                            : "Active Full Report"
                        }
                      >
                        {/* <span class="material-icons-outlined">
                          perm_identity
                        </span> */}
                        <ActiveVendorReport />
                      </i>
                      <CustomReactTooltip
                        id={`tooltip-${companyData[company].id}-${segmentData[segment].id}-active`}
                        multiline={true}
                      />
                    </>
                  ) : companyData[company].org_segments[ind].company_segment
                      .coverage_type === "benchmark_coverage" ? (
                    <>
                      <i
                        className="allowed-access-icon"
                        onClick={() => {
                          this.goToSegment(
                            companyData[company].org_segments[ind]
                              .allowed_access,
                            companyData[company],
                            segmentData[segment]
                          );
                        }}
                        data-for={`tooltip-${companyData[company].id}-${segmentData[segment].id}-active`}
                        data-tip={
                          segmentData[segment].title.includes(
                            "Earnings Response"
                          )
                            ? "Active Earnings Response"
                            : "Active Full Report"
                        }
                      >
                        {/* <span class="material-icons-outlined">
                          person_pin_circle
                        </span> */}
                        <ActiveBenchmarkCoverage />
                      </i>
                      <CustomReactTooltip
                        id={`tooltip-${companyData[company].id}-${segmentData[segment].id}-active`}
                        multiline={true}
                      />
                    </>
                  ) : (
                    <>
                      <i
                        className="allowed-access-icon"
                        onClick={() => {
                          this.goToSegment(
                            companyData[company].org_segments[ind]
                              .allowed_access,
                            companyData[company],
                            segmentData[segment]
                          );
                        }}
                        data-for={`tooltip-${companyData[company].id}-${segmentData[segment].id}-active`}
                        data-tip={
                          segmentData[segment].title.includes(
                            "Earnings Response"
                          )
                            ? "Active Earnings Response"
                            : "Active Full Report"
                        }
                      >
                        {/* <span class="material-icons-outlined">groups</span> */}
                        <ActiveVendorSnapshot />
                      </i>
                      <CustomReactTooltip
                        id={`tooltip-${companyData[company].id}-${segmentData[segment].id}-active`}
                        multiline={true}
                      />
                    </>
                  )}
                </span>
              </>
            ) : (
              <>
                <span>
                  {companyData[company].org_segments[ind].company_segment
                    .coverage_type === "analyst_team" ? (
                    <>
                      <i
                        className="inactive-allowed-access-icon"
                        onClick={() => {
                          this.goToSegment(
                            companyData[company].org_segments[ind]
                              .allowed_access,
                            companyData[company],
                            segmentData[segment]
                          );
                        }}
                        data-for={`tooltip-${companyData[company].id}-${segmentData[segment].id}-inactive`}
                        data-tip={
                          segmentData[segment].title.includes(
                            "Earnings Response"
                          )
                            ? "Inactive Earnings Response"
                            : "Inactive Full Report"
                        }
                      >
                        {/* <span class="material-icons-outlined">group</span> */}
                        <InactiveVendorProfile />
                      </i>
                      <CustomReactTooltip
                        id={`tooltip-${companyData[company].id}-${segmentData[segment].id}-inactive`}
                        multiline={true}
                      />
                    </>
                  ) : companyData[company].org_segments[ind].company_segment
                      .coverage_type === "dedicated_analyst" ? (
                    <>
                      <i
                        className="inactive-allowed-access-icon"
                        onClick={() => {
                          this.goToSegment(
                            companyData[company].org_segments[ind]
                              .allowed_access,
                            companyData[company],
                            segmentData[segment]
                          );
                        }}
                        data-for={`tooltip-${companyData[company].id}-${segmentData[segment].id}-inactive`}
                        data-tip={
                          segmentData[segment].title.includes(
                            "Earnings Response"
                          )
                            ? "Inactive Earnings Response"
                            : "Inactive Full Report"
                        }
                      >
                        {/* <span class="material-icons-outlined">
                          perm_identity
                        </span> */}
                        <InactiveVendorReport />
                      </i>
                      <CustomReactTooltip
                        id={`tooltip-${companyData[company].id}-${segmentData[segment].id}-inactive`}
                        multiline={true}
                      />
                    </>
                  ) : companyData[company].org_segments[ind].company_segment
                      .coverage_type === "benchmark_coverage" ? (
                    <>
                      <i
                        className="inactive-allowed-access-icon"
                        onClick={() => {
                          this.goToSegment(
                            companyData[company].org_segments[ind]
                              .allowed_access,
                            companyData[company],
                            segmentData[segment]
                          );
                        }}
                        data-for={`tooltip-${companyData[company].id}-${segmentData[segment].id}-inactive`}
                        data-tip={
                          segmentData[segment].title.includes(
                            "Earnings Response"
                          )
                            ? "Inactive Earnings Response"
                            : "Inactive Full Report"
                        }
                      >
                        {/* <span class="material-icons-outlined">
                          person_pin_circle
                        </span> */}
                        <InactiveBenchmarkCoverage />
                      </i>
                      <CustomReactTooltip
                        id={`tooltip-${companyData[company].id}-${segmentData[segment].id}-inactive`}
                        multiline={true}
                      />
                    </>
                  ) : (
                    <>
                      <i
                        className="inactive-allowed-access-icon"
                        onClick={() => {
                          this.goToSegment(
                            companyData[company].org_segments[ind]
                              .allowed_access,
                            companyData[company],
                            segmentData[segment]
                          );
                        }}
                        data-for={`tooltip-${companyData[company].id}-${segmentData[segment].id}-inactive`}
                        data-tip={
                          segmentData[segment].title.includes(
                            "Earnings Response"
                          )
                            ? "Inactive Earnings Response"
                            : "Inactive Full Report"
                        }
                      >
                        {/* <span class="material-icons-outlined">groups</span> */}
                        <InactiveVendorSnapshot />
                      </i>
                      <CustomReactTooltip
                        id={`tooltip-${companyData[company].id}-${segmentData[segment].id}-inactive`}
                        multiline={true}
                      />
                    </>
                  )}
                </span>
              </>
            )
          ) : (
            <>
              <span>
                {companyData[company].org_segments[ind].company_segment
                  .coverage_type === "analyst_team" ? (
                  <>
                    <i
                      className="not-allowed-access-icon"
                      onClick={() => {
                        this.goToSegment(
                          companyData[company].org_segments[ind].allowed_access,
                          companyData[company],
                          segmentData[segment]
                        );
                      }}
                      data-for={`tooltip-${companyData[company].id}-${segmentData[segment].id}-noaccess`}
                      data-tip={
                        segmentData[segment].title.includes("Earnings Response")
                          ? "No Access Earnings Response"
                          : "No Access Full Report"
                      }
                    >
                      {/* <span className="material-icons-outlined">group</span> */}
                      <NoAccessVendorProfile />
                    </i>
                    <CustomReactTooltip
                      id={`tooltip-${companyData[company].id}-${segmentData[segment].id}-noaccess`}
                      multiline={true}
                    />
                  </>
                ) : companyData[company].org_segments[ind].company_segment
                    .coverage_type === "dedicated_analyst" ? (
                  <>
                    <i
                      className="not-allowed-access-icon"
                      onClick={() => {
                        this.goToSegment(
                          companyData[company].org_segments[ind].allowed_access,
                          companyData[company],
                          segmentData[segment]
                        );
                      }}
                      data-for={`tooltip-${companyData[company].id}-${segmentData[segment].id}-noaccess`}
                      data-tip={
                        segmentData[segment].title.includes("Earnings Response")
                          ? "No Access Earnings Response"
                          : "No Access Full Report"
                      }
                    >
                      {/* <span className="material-icons-outlined">
                        perm_identity
                      </span> */}
                      <NoAccessVendorReport />
                    </i>
                    <CustomReactTooltip
                      id={`tooltip-${companyData[company].id}-${segmentData[segment].id}-noaccess`}
                      multiline={true}
                    />
                  </>
                ) : companyData[company].org_segments[ind].company_segment
                    .coverage_type === "benchmark_coverage" ? (
                  <>
                    <i
                      className="not-allowed-access-icon"
                      onClick={() => {
                        this.goToSegment(
                          companyData[company].org_segments[ind].allowed_access,
                          companyData[company],
                          segmentData[segment]
                        );
                      }}
                      data-for={`tooltip-${companyData[company].id}-${segmentData[segment].id}-noaccess`}
                      data-tip={
                        segmentData[segment].title.includes("Earnings Response")
                          ? "No Access Earnings Response"
                          : "No Access Full Report"
                      }
                    >
                      {/* <span className="material-icons-outlined">
                        person_pin_circle
                      </span> */}
                      <NoAccessBenchmarkCoverage />
                    </i>
                    <CustomReactTooltip
                      id={`tooltip-${companyData[company].id}-${segmentData[segment].id}-noaccess`}
                      multiline={true}
                    />
                  </>
                ) : (
                  <>
                    <i
                      className="not-allowed-access-icon"
                      onClick={() => {
                        this.goToSegment(
                          companyData[company].org_segments[ind].allowed_access,
                          companyData[company],
                          segmentData[segment]
                        );
                      }}
                      data-for={`tooltip-${companyData[company].id}-${segmentData[segment].id}-noaccess`}
                      data-tip={
                        segmentData[segment].title.includes("Earnings Response")
                          ? "No Access Earnings Response"
                          : "No Access Full Report"
                      }
                    >
                      {/* <span className="material-icons-outlined">groups</span> */}
                      <NoAccessVendorSnapshot />
                    </i>
                    <CustomReactTooltip
                      id={`tooltip-${companyData[company].id}-${segmentData[segment].id}-noaccess`}
                      multiline={true}
                    />
                  </>
                )}
              </span>
            </>
          );

          obj = {
            [segmentData[segment].id]: action,
          };
        } else {
          obj = { [segmentData[segment].id]: "-" };
        }
        segmentObj = { ...segmentObj, ...obj };
      }
      rowData.push({ ...companyData[company], ...segmentObj });
    }

    // Filter out IR segment and combine the row
    // let newRowData = [];
    // rowData.map((rowElement) => {
    //   let rowWithIR = [];
    //   let rowWithoutIR = [];
    //   // filter segment with IR and without IR
    //   Object.keys(rowElement).forEach(function (key, index) {
    //     if (key.includes("IR")) {
    //       rowWithIR.push({ [key]: rowElement[key] });
    //     } else {
    //       rowWithoutIR.push({ [key]: rowElement[key] });
    //     }
    //   });

    //   // combine the segment
    //   let newData = {};
    //   if (rowWithIR.length > 0) {
    //     for (let i = 0; i < rowWithIR.length; i++) {
    //       for (let y = 0; y < rowWithoutIR.length; y++) {
    //         const rowIRKey = Object.keys(rowWithIR[i])[0];
    //         const rowIRValue = Object.values(rowWithIR[i])[0];
    //         const rowWithoutIRKey = Object.keys(rowWithoutIR[y])[0];
    //         const rowWithoutIRValue = Object.values(rowWithoutIR[y])[0];
    //         if (rowIRKey.includes(rowWithoutIRKey.split("||")[0])) {
    //           newData[rowWithoutIRKey] =
    //             rowWithoutIRValue !== "-" && rowIRValue !== "-" ? (
    //               <span className="company-icons">
    //                 {" "}
    //                 {rowWithoutIRValue} &nbsp;|&nbsp; {rowIRValue}{" "}
    //                 <InitialResponse width={10} style={{ marginTop: "-5px" }} />
    //               </span>
    //             ) : rowWithoutIRValue === "-" && rowIRValue !== "-" ? (
    //               <span className="company-icons">
    //                 {rowIRValue}{" "}
    //                 <InitialResponse width={10} style={{ marginTop: "-5px" }} />
    //               </span>
    //             ) : (
    //               rowWithoutIRValue
    //             );
    //         }
    //       }
    //     }
    //     newRowData.push({ ...rowElement, ...newData });
    //   } else {
    //     rowData.push({ ...rowElement });
    //   }
    // });

    let newRowData = rowData.map((rowElement) => {
      const rowWithIR = {};
      const rowWithoutIR = {};

      // Separate the data into two objects
      Object.keys(rowElement).forEach(function (key) {
        if (key.includes("Earnings Response")) {
          rowWithIR[key] = rowElement[key];
        } else {
          rowWithoutIR[key] = rowElement[key];
        }
      });

      // Combine the data
      let newData = { ...rowElement };
      for (const rowIRKey in rowWithIR) {
        for (const rowWithoutIRKey in rowWithoutIR) {
          if (rowIRKey.includes(rowWithoutIRKey.split("||")[0])) {
            const rowIRValue = rowWithIR[rowIRKey];
            const rowWithoutIRValue = rowWithoutIR[rowWithoutIRKey];

            if (rowWithoutIRValue !== "-" && rowIRValue !== "-") {
              newData[rowWithoutIRKey] = (
                <span className="company-icons">
                  {rowWithoutIRValue}&nbsp;|&nbsp;{rowIRValue}
                  <InitialResponse width={10} style={{ marginTop: "-5px" }} />
                </span>
              );
            } else if (rowIRValue !== "-") {
              newData[rowWithoutIRKey] = (
                <span className="company-icons">
                  {rowIRValue}
                  <InitialResponse width={10} style={{ marginTop: "-5px" }} />
                </span>
              );
            } else {
              newData[rowWithoutIRKey] = rowWithoutIRValue;
            }
          }
        }
      }

      return newData;
    });

    this.setState(
      {
        columnData: columnData,
        rowData: newRowData,
        count: count,
        segmentData: segments,
      },
      () => {}
    );
  };
  getLobs = () => {
    if (this.state.segmentData && this.state.segmentData.length) {
      return {
        data: {
          data: this.state.segmentData,
        },
      };
    } else {
      return CompanyServices.getTotalSegments();
    }
  };
  goToDetail = (access, company) => {
    if (access) {
      this.props.history.push({
        pathname: formatRoute(constants.APPLICATION_ROUTE.COMPANY.VIEW.ROUTE, {
          id: company.id,
        }),
      });
    } else {
      if (company.is_active === 0) {
        let dailogModalHeader = (
          <>
            <h3 className="text-darkblue2 font-weight-bold">
              {constants.WARNING.CONTENT_INACTIVE_TITLE}
            </h3>

            {constants.WARNING.CONTENT_INACTIVE_TITLE}
          </>
        );
        let dailogModalContent = (
          <>
            <div className="inactive-body">
              <h2 className="font-weight-semibold mb-0">
                {constants.WARNING.CONTENT_INACTIVE_TEXT}
              </h2>
            </div>
          </>
        );
        this.setState({
          dailogModal: true,
          dailogModalHeader: dailogModalHeader,
          dailogModalContent: dailogModalContent,
          dailogModalConfig: { type: false, id: null },
          dailogModalStyleType: "inactive-modal",
        });
      } else {
        let dailogModalHeader = (
          <>
            <h3 className="text-darkblue2 font-weight-bold">
              {constants.WARNING.CONTENT_ACCESS_DENIED_TITLE}
            </h3>
          </>
        );
        let dailogModalContent = (
          <>
            <div className="noaccess-body">
              <h2 className="font-weight-semibold mb-0">
                {constants.WARNING.CONTENT_ACCESS_DENIED_TEXT}
              </h2>
            </div>
          </>
        );
        this.setState({
          dailogModal: true,
          dailogModalHeader: dailogModalHeader,
          dailogModalContent: dailogModalContent,
          dailogModalConfig: { type: false, id: null },
          dailogModalStyleType: "noaccess-modal",
        });
      }
    }
  };

  goToSegment = (
    access,
    company,
    segment,
    company_segment_ic_is_active = 1
  ) => {
    if (access && company_segment_ic_is_active === 1) {
      this.props.history.push({
        pathname: formatRoute(constants.APPLICATION_ROUTE.COMPANY.VIEW.ROUTE, {
          id: company.id,
        }),
        search: "?segment=" + segment.key,
      });
    } else {
      if (company_segment_ic_is_active === 0) {
        let dailogModalHeader = (
          <>
            <h3 className="text-darkblue2 font-weight-bold">
              {constants.WARNING.CONTENT_INACTIVE_TITLE}
            </h3>
          </>
        );
        let dailogModalContent = (
          <>
            <div className="inactive-body">
              <h2 className="font-weight-semibold mb-0">
                {constants.WARNING.CONTENT_INACTIVE_TEXT}
              </h2>
            </div>
          </>
        );
        this.setState({
          dailogModal: true,
          dailogModalHeader: dailogModalHeader,
          dailogModalContent: dailogModalContent,
          dailogModalConfig: { type: false, id: null },
          dailogModalStyleType: "inactive-modal",
        });
      } else {
        let dailogModalHeader = (
          <>
            <h3 className="text-darkblue2 font-weight-bold">
              {constants.WARNING.CONTENT_ACCESS_DENIED_TITLE}
            </h3>
          </>
        );
        let dailogModalContent = (
          <>
            <div className="noaccess-body">
              <h2 className="font-weight-semibold mb-0">
                {constants.WARNING.CONTENT_ACCESS_DENIED_TEXT}
              </h2>
            </div>
          </>
        );
        this.setState({
          dailogModal: true,
          dailogModalHeader: dailogModalHeader,
          dailogModalContent: dailogModalContent,
          dailogModalConfig: { type: false, id: null },
          dailogModalStyleType: "noaccess-modal",
        });
      }
    }
  };
  exportList = () => {
    let queryParams = "";
    if (this.state.sortOrder) {
      let segmentsQ = this.state.sortColumn.split("||");
      queryParams =
        "&sort_order=" +
        this.state.sortOrder +
        "&sort_column=" +
        this.state.sortColumn;
      if (segmentsQ.length > 0 && segmentsQ[1]) {
        queryParams =
          "&sort_column=segment_id&sort_value=" +
          segmentsQ[1].toString() +
          "&sort_order=" +
          this.state.sortOrder;
      }
    }
    if (this.state.name !== "") {
      queryParams += "&search_column=name&search_value=" + this.state.name;
    }
    CompanyServices.getTotalCompanies("companies?1=1" + queryParams)
      .then((res) => {
        let segmentData = this.state.segmentData.filter(
          (segment) => segment.is_active > 0
        );
        segmentData = segmentData.map((segment, key) => {
          return {
            title: segment.name,
            id: `${segment.name}||${segment.id}`,
            is_active: segment.is_active,
            key: segment.id,
          };
        });
        let companyData = res.data.data;
        companyData = companyData.map((data) => {
          return {
            name: data.name,
            annual_income: data.annual_income,
            quarterly_growth: MoneyFormatter(data.quarterly_growth, "0.00"),
            yearly_growth: MoneyFormatter(data.yearly_growth, "0.00"),
            segments: data.segments.map((data) => data.name),
            org_segments: data.segments,
          };
        });
        let segmentObj = {};
        let rowData = [];
        for (let company = 0; company < companyData.length; company++) {
          for (let segment = 0; segment < segmentData.length; segment++) {
            let obj = {};
            if (
              companyData[company].org_segments.findIndex(
                (o) => o.id === segmentData[segment].key
              ) >= 0
            )
              obj = {
                [segmentData[segment].id]:
                  segmentData[segment].is_active === 0 ? "No" : "Yes",
              };
            else {
              obj = { [segmentData[segment].id]: "No" };
            }
            segmentObj = { ...segmentObj, ...obj };
          }
          rowData.push({ ...companyData[company], ...segmentObj });
        }
        this.setState(
          {
            totalData: rowData,
            downloadAction:
              this.state.downloadAction === null
                ? true
                : !this.state.downloadAction,
          },
          () => {
            CompanyServices.postDownloadDataReport({
              title: "Companies",
              service: "CompaniesList",
              doc_type: "XLSX",
              source_type: "Website",
            }).then((res) => {
              // Filter the data to include only the defined columns
              const processedColumns = this.state.columnData.map((col) => ({
                ...col,
                accessor: col.id === "name" ? col.title : col.id.split("||")[0], // Extract the part before "||"
              }));

              // Filter the data to include only the processed columns
              const filteredData = this.state.totalData.map((row) =>
                processedColumns.reduce((obj, col) => {
                  obj[col.accessor] = row[col.id] || row[col.accessor];
                  return obj;
                }, {})
              );
              downloadExcelFile(
                "COMPANIES",
                "Companies",
                filteredData,
                processedColumns
              );
            });
            gaEvent.sendEventToGA(constants.GA.CUSTOM_EVENTS.DOWNLOAD_VIEW, {
              fileName: "Companies",
              fileFormat: "xlsx",
              moduleName: constants.APPLICATION_ROUTE.COMPANY.LIST.NAME,
              pageUrl:
                this.props.history.location.pathname +
                this.props.history.location.search,
            });
          }
        );
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  handlePageSize = (e) => {
    this.setState({ pageSize: e.target.value, pageNo: 1 }, () => {
      this.getTableData();
    });
  };
  handleInput = (e) => {
    this.setState({ name: e.target.value });
  };
  stickyColumns = () => {
    let firstcolWidth = $(".comapany_table").find("tr > td:first").innerWidth();
    let secondcolWidth = $(".comapany_table")
      .find("tr > td:nth-child(2)")
      .innerWidth();
    let thirdcolWidth = $(".comapany_table")
      .find("tr > td:nth-child(3)")
      .innerWidth();
    if (window.innerWidth > 768) {
      $(".comapany_table")
        .find("tr > th:nth-child(2)")
        .css("left", firstcolWidth);
      $(".comapany_table")
        .find("tr > td:nth-child(2)")
        .css("left", firstcolWidth);

      $(".comapany_table")
        .find("tr > th:nth-child(3)")
        .css("left", firstcolWidth + secondcolWidth);
      $(".comapany_table")
        .find("tr > td:nth-child(3)")
        .css("left", firstcolWidth + secondcolWidth);

      $(".comapany_table")
        .find("tr > th:nth-child(4)")
        .css("left", firstcolWidth + secondcolWidth + thirdcolWidth);
      $(".comapany_table")
        .find("tr > td:nth-child(4)")
        .css("left", firstcolWidth + secondcolWidth + thirdcolWidth);
    } else {
      $(".comapany_table").find("tr > th:nth-child(2)").css("left", "");
      $(".comapany_table").find("tr > td:nth-child(2)").css("left", "");

      $(".comapany_table").find("tr > th:nth-child(3)").css("left", "");
      $(".comapany_table").find("tr > td:nth-child(3)").css("left", "");

      $(".comapany_table").find("tr > th:nth-child(4)").css("left", "");
      $(".comapany_table").find("tr > td:nth-child(4)").css("left", "");
    }
    $(".comapany_table").css(
      "min-width",
      $(".comapany_table").find("thead").innerWidth()
    );
  };
  dailogModalAccept = (config) => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
    });
  };
  dailogModalDecline = () => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
    });
  };
  breadcrumbs = () => {
    let breadcrumbs = [
      {
        title: constants.APPLICATION_ROUTE.DASHBOARD.NAME,

        link: null,
        is_active: false,
      },
      {
        title: constants.APPLICATION_ROUTE.COMPANY.LIST.NAME,
        link: null,
        is_active: true,
      },
    ];
    return breadcrumbs;
  };

  closeTheClientAdminPopup = () => {
    let hostName = window.location.hostname;
    let url =
      "company/list?page=1&page_size=10&sort_order=desc&sort_column=updated_at";
    setTimeout(() => {
      if (hostName === constants.APP_URLS.CLIENT.LOCAL) {
        window.location = `${constants.APP_URLS.ADMIN.LOCAL}/${url}`;
      } else if (hostName === constants.APP_URLS.CLIENT.TEST) {
        window.location = `${constants.APP_URLS.ADMIN.TEST}/${url}`;
      } else if (hostName === constants.APP_URLS.CLIENT.STAGE) {
        window.location = `${constants.APP_URLS.ADMIN.STAGE}/${url}`;
      } else if (hostName === constants.APP_URLS.CLIENT.PROD) {
        window.location = `${constants.APP_URLS.ADMIN.PROD}/${url}`;
      }
    }, 50);
  };

  handleFilterDataBySegment = (selectedOptions) => {
    this.setState({ segmentsForDropdown: selectedOptions }, () => {
      this.getTableData();
    });
  };

  handleCoverageTypeChange = (selectedOptions) => {
    this.setState({ coverageType: selectedOptions }, () => {
      this.getTableData();
    });
  };

  render() {
    let tokenDetails = decodeJWT(localStorage.getItem("token"));

    const multiStyles = {
      control: (styles, state) => ({
        ...styles,
        height: "35px",
        minHeight: "35px",
        marginBottom: "6px",
        border: "1px solid #E9EBEE",
        "&:hover": {
          border: "1px solid #E9EBEE",
        },
      }),
      valueContainer: (provided) => ({
        ...provided,
        display: "flex",
        overflowX: "auto",
        flexWrap: "nowrap",
      }),
      menu: (provided) => ({
        ...provided,
        zIndex: 999,
      }),
      multiValue: (styles, { data }) => {
        return {
          ...styles,
          flexShrink: 0,
          backgroundColor: "#EBF3FF",
          color: "#2E6AD2",
        };
      },
    };

    const InputOption = ({
      getStyles,
      Icon,
      isDisabled,
      isFocused,
      isSelected,
      children,
      innerProps,
      data,
      ...rest
    }) => {
      const [isActive, setIsActive] = useState(false);
      const onMouseDown = () => setIsActive(true);
      const onMouseUp = () => setIsActive(false);
      const onMouseLeave = () => setIsActive(false);

      // styles
      let bg = "transparent";
      if (isFocused) bg = "#EBF3FF";
      if (isActive) bg = "#B2D4FF";

      const style = {
        alignItems: "center",
        backgroundColor: bg,
        color: "inherit",
        display: "flex ",
        zIndex: "9",
      };

      // prop assignment
      const props = {
        ...innerProps,
        onMouseDown,
        onMouseUp,
        onMouseLeave,
        style,
      };

      return (
        <components.Option
          {...rest}
          isDisabled={isDisabled}
          isFocused={isFocused}
          isSelected={isSelected}
          getStyles={getStyles}
          innerProps={props}
        >
          <input
            className="mr-2"
            type="checkbox"
            checked={isSelected}
            readOnly
          />
          {data.icon && <span className="mr-2">{data.icon}</span>}
          <label className="pt-2">{children}</label>
        </components.Option>
      );
    };

    const transformedSegmentData = this.state.segmentData
      .filter((segment) => !segment.name.includes("Earnings Response"))
      .map((segment) => ({
        label: segment.name,
        value: segment.id,
      }));

    return (
      <aside className="main_content">
        {/* {tokenDetails.role.short_name === "admin" && (
          <div className="client-admin-popup m-3">
            <h4 className="text-white mt-2 ml-2">Client View</h4>
            <div
              data-tip
              data-for="goToAdminTip"
              onClick={this.closeTheClientAdminPopup}
              className="popup-cancle-btn"
            >
              <CloseSVG />
              <ReactTooltip id="goToAdminTip" place="bottom" effect="solid">
                Click here to move back to Admin view
              </ReactTooltip>
            </div>
          </div>
        )} */}
        <div className="m-3 mb-0 bg-white px-3 pb-3 br_8 main_content_height">
          <div className="d-flex align-items-center flex-wrap title-section breadcrumb-mb-0 pt-3">
            <h1 className="text-ligntblue2 font-weight-bold ">
              Company Research
            </h1>
            <div className="ml-auto d-flex align-items-center justify-content-end title-right-block mb-0">
              <div className="icon-group d-flex">
                <AclAction
                  type={constants.ACL.PERMISSION_KEY}
                  entity={constants.ACL.COMPANY_RESOURCE.TITLE}
                  action={constants.ACL.COMPANY_RESOURCE.ACTIONS.EXPORT}
                >
                  <button
                    className="icon-btn-primary icon-btn d-flex align-items-center justify-content-center"
                    onClick={this.exportList}
                    data-for={`tooltip-${Slugify(
                      constants.COMPANY.VIEW.DOWNLOAD_TOOLTIP
                    )}`}
                    data-tip={constants.COMPANY.VIEW.DOWNLOAD_TOOLTIP}
                    data-iscapture="true"
                    data-testid="list-companies-download"
                    tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
                  >
                    <span class="material-icons-round icon">download</span>
                    <CustomReactTooltip
                      id={`tooltip-${Slugify(
                        constants.COMPANY.VIEW.DOWNLOAD_TOOLTIP
                      )}`}
                      multiline={true}
                    />
                  </button>
                </AclAction>
                <HelpModal category="Company List" />
              </div>
            </div>
          </div>

          <div className="col-xl-12 col-sm-offset-right-1">
            <div className="row justify-content-start">
              <div className="col-6"></div>
              <div className="col-6"></div>
              <aside className="col-lg-3 col-md-6 col-sm-6 col-6"></aside>

              <aside className="col-lg-2 col-md-6 col-sm-6 col-6"></aside>
              <aside className="col-lg-2 col-md-6 col-sm-6 col-6 align-self-center disable"></aside>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
              <div className="w-100-search border-search search-box-shadow-2">
                <SearchComponent
                  data-testid="list-companies-search"
                  handleSearch={this.handleSearch}
                  handleInput={this.handleInput}
                  value={this.state.name}
                  placeHolder={"Search for a company"}
                />
              </div>
            </div>
            {/* This Option is for selecting Segments */}
            <div className="col-lg-3 col-md-6 col-sm-6 col-12 clients_dropdown">
              <div className="custom-combobox-list">
                <Select
                  options={transformedSegmentData}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  onChange={this.handleFilterDataBySegment}
                  value={this.state.segmentsForDropdown}
                  placeholder={"Select Segments"}
                  styles={multiStyles}
                  components={{
                    Option: InputOption,
                  }}
                />
              </div>
            </div>
            {/* This Option is for selecting coverage type */}
            <div className="col-lg-3 col-md-6 col-sm-6 col-12 clients_dropdown">
              <div className="custom-combobox-list">
                <Select
                  options={coverageTypeList}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  onChange={this.handleCoverageTypeChange}
                  value={this.state.coverageType}
                  placeholder={"Select Coverage Type"}
                  styles={multiStyles}
                  components={{
                    Option: InputOption,
                  }}
                />
              </div>
            </div>

            <div className="d-flex justify-content-end align-items-center flex-wrap col">
              <div className="table-list-legends d-flex flex-wrap pb-0">
                <span className="d-inline-flex align-items-center mr-2">
                  <span
                    className="material-icons-round icon allowed-access-icon icon-fs-14"
                    aria-hidden="true"
                  >
                    circle
                  </span>
                  <h3 className="mb-0">Active</h3>
                </span>
                <span className="d-inline-flex align-items-center mr-2">
                  <span
                    className="material-icons-round icon inactive-allowed-access-icon icon-fs-14"
                    aria-hidden="true"
                  >
                    circle
                  </span>
                  <h3 className="mb-0">Inactive</h3>
                </span>
                <span className="d-inline-flex align-items-center mr-2">
                  <span
                    className="material-icons-round icon not-allowed-access-icon icon-fs-14"
                    aria-hidden="true"
                  >
                    circle
                  </span>
                  <h3 className="mb-0">No Access</h3>
                </span>
              </div>
            </div>
          </div>
          <div className="table-list-legends d-flex flex-wrap mr-0 mr-sm-4 ml-1">
            <span className="d-inline-flex align-items-center mr-2">
              {/* <span className="material-icons-outlined icon text-grey icon-fs-20" aria-hidden="true">perm_identity</span> */}
              <VendorReport />
              <h3 className="mb-0">Company Research</h3>
            </span>
            <span className="d-inline-flex align-items-center mr-2">
              {/* <span className="material-icons-outlined icon text-grey icon-fs-20" aria-hidden="true">group</span> */}
              <VendorProfile />
              <h3 className="mb-0">Company Profile</h3>
            </span>
            <span className="d-inline-flex align-items-center mr-2">
              {/* <span className="material-icons-outlined icon text-grey icon-fs-20" aria-hidden="true">groups</span> */}
              <VendorSnapshot />
              <h3 className="mb-0">Company Snapshot</h3>
            </span>
            <span className="d-inline-flex align-items-center mr-2">
              <BenchmarkCoverage />
              <h3 className="mb-0">Benchmark Coverage</h3>
            </span>
            <span className="d-inline-flex align-items-center mr-2">
              {/* <span className="material-icons-outlined icon text-grey icon-fs-20" aria-hidden="true">hotel_class</span> */}
              <InitialResponse />
              <h3 className="mb-0">
                <sup>*</sup>indicates Earnings Response
              </h3>
            </span>
          </div>
          <div className="custom-table-block first-col-fixed-block companies-list-table-block nowrap-table-block th-nowrap sort-table-block center-th-align">
            <DynamicTableComponent
              columnData={this.state.columnData}
              rowData={this.state.rowData}
              pagePrevious={this.pagePrevious}
              pageNext={this.pageNext}
              pageSize={this.state.pageSize}
              pageNo={this.state.pageNo}
              handlePage={this.handlePage}
              handleSort={this.handleSort}
              sortColumn={this.state.sortColumn}
              sortOrder={this.state.sortOrder}
              handlePageSize={this.handlePageSize}
              count={this.state.count}
              // table={{ className: 'comapany_table' }}
              showPagination={true}
              paginationClass={"center-pagination custom-pagination-new"}
            />
          </div>

          <DailogNew
            isOpen={this.state.dailogModal}
            accept={this.dailogModalAccept}
            decline={this.dailogModalDecline}
            header={this.state.dailogModalHeader}
            content={this.state.dailogModalContent}
            config={this.state.dailogModalConfig}
            modalStyleType={this.state.dailogModalStyleType}
          />
        </div>
      </aside>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    home: state.home,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    segments: (payload) => {
      dispatch(segments(payload));
    },
    activeMenuTab: (tab) => {
      dispatch(activeMenuTab(tab));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListCompanies);
