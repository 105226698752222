import React, { useState, useEffect, useRef } from "react";
import PreferenceCenterSettings from "../../Containers/V2/PreferenceCenter/PreferenceCenterSettings";
import PreferenceCenterSidebar from "../../Containers/V2/PreferenceCenter/PreferenceCenterSidebar";
import Toast from "../../Utils/Toast";
import constants from "../../Utils/constants";
import { CompanyServices } from "../../Services/Company";
const PreferenceCenter = (props) => {
  const divRef = useRef(null);
  const [segmentData, setSegmentData] = useState([]);
  const [selectedSegment, setSelectedSegment] = useState({});
  const [allSegmentsData, setAllSegments] = useState([]);
  const modifiedAPIDataToSegmentData = (data = []) => {
    let temp = [];
    data.forEach((item, index) => {
      if (!item.name.includes("Earnings Response")) {
        temp.push({
          id: item.id,
          name: item.name,
          is_arrow_selected: index === 0,
        });
      }
    });
    return temp;
  };

  const goToScroll = (id) => {
    if (divRef.current) {
      document.querySelector(id).scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const fetchAllReportSegmentData = async () => {
    try {
      const companiesSegment = await CompanyServices.getTotalSegments();
      const modifiedCmpSegment = modifiedAPIDataToSegmentData(
        companiesSegment?.data?.data
      );
      setSelectedSegment(modifiedCmpSegment[0]);
      setSegmentData(modifiedCmpSegment);
      setAllSegments(companiesSegment.data.data);
    } catch (error) {
      Toast(
        error && error.data && error.data.error
          ? error.data.error
          : constants.ERROR.SOMETHING_WENT_WRONG,
        "error"
      );
    }
  };
  const handleCollaps = (key) => {
    let temp = [...segmentData];
    temp.map((item, index) => {
      if (index === key) {
        item.is_arrow_selected = !item.is_arrow_selected;
      } else {
        item.is_arrow_selected = false;
      }
      return item;
    });
    setSegmentData(temp);
    setSelectedSegment(temp[key]);
  };
  useEffect(() => {
    fetchAllReportSegmentData();
  }, []);
  return (
    <div className='preference-container'>
      <PreferenceCenterSidebar
        segmentData={segmentData}
        setSegmentData={setSegmentData}
        handleCollaps={handleCollaps}
        selectedSegment={selectedSegment}
        goToScroll={goToScroll}
      />
      <PreferenceCenterSettings
        mainRef={divRef}
        selectedSegment={selectedSegment}
        segmentData={segmentData}
        allSegmentsData={allSegmentsData}
        handleCollaps={handleCollaps}
      />
    </div>
  );
};
export default PreferenceCenter;
