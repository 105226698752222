import React, { memo } from 'react'
import { Route, Redirect } from 'react-router'
import constants from '../Utils/constants'

const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  return (
    <Route {...rest} render={props => (
      localStorage.getItem("token") !== null && restricted ?
        <Redirect to={constants.APPLICATION_ROUTE.COMPANY.LIST.ROUTE} />
        : <Component {...props} />
    )} />
  )
}

export default memo(PublicRoute)
