import React, { Component } from 'react'
import { connect } from 'react-redux'
import { categories, companySegments } from '../Store/Actions/CompanyDetails'
import Slugify from '../Utils/Slugify'
import gotoSection from '../Utils/ScrollToTop'
import Scrollspy from 'react-scrollspy'
import Collapsible from 'react-collapsible';

class CompanyDetailsCategories extends Component {

  findTotal(res, company_content) {
    let total = 0;
    var anyBoxesChecked = [];
    var numeroPerguntas = res.subcategories.length;
    for (var i = 0; i < numeroPerguntas; i++) {
      anyBoxesChecked.push(true);
    }
    res.subcategories.map((sub, index) => {
      company_content.find((re_sub) => {
        if (re_sub.subcategory_id === sub.id && anyBoxesChecked[index]) {
          anyBoxesChecked[index] = false;
          total++;
        }
      })
    })
    return total;
  }
  toggleCheckbox = (e, type, category_id, sub_category_id) => {
    let original_categories = [...this.props.company_details.categories]
    let company_content = [...this.props.company_details.companyContent]
    let checkbox_selection = e.target.checked
    if (type === 'category') {
      let cat = original_categories.findIndex((c) => c.id === category_id)
      original_categories[cat].is_selected = checkbox_selection
      if (original_categories[cat].subcategories) {
        original_categories[cat].subcategories.forEach((res_sub) => {
          if (company_content.length > 0 && company_content.find((segment) => segment.subcategory_id === res_sub.id) || res_sub.is_selected)
            res_sub.is_selected = checkbox_selection
        })
      }
    }
    else if (type === 'sub_category') {
      original_categories.forEach((res) => {
        if (res.id === category_id) {
          if (res.subcategories) {
            res.subcategories.forEach((res_sub) => {
              if (res_sub.id === sub_category_id)
                res_sub.is_selected = checkbox_selection
            })
            let total = this.findTotal(res, company_content);
            let total_selected = res.subcategories.filter(row => row.is_selected === true).length
            if (total_selected === total && total > 0) {
              res.is_selected = true
            }
            else {
              res.is_selected = false
            }
          }
        }
      })
    }
    this.props.categories(original_categories)
  }

  handleCategorySelection = (name, cat_id, sub_cat_id) => {
    setTimeout(() => {
      if (sub_cat_id) {
        gotoSection(`#${Slugify(name)}_${sub_cat_id}`)
      } else {
        gotoSection(`#${Slugify(name)}`)
      }
    }, 500)

    if (cat_id) {
      let cat = this.props.company_details.categories.findIndex((c) => c.id === cat_id)
      this.props.company_details.categories[cat].is_open = true
      this.props.categories([...this.props.company_details.categories])
    }
  }

  render() {
    let companyContent = (this.props.company_details && this.props.company_details.companyContent) ? this.props.company_details.companyContent : []
    return (
      this.props.company_details && this.props.company_details.categories && this.props.company_details.categories.length > 0 &&
      <aside className=" inner-sidebar" >
        <div className="sidebar_nav2 d-flex flex-column h-100">
          <div className="d-flex icon-wrapper">
            <span className="material-icons-outlined icon" onClick={this.props.toggleInnerSidebar}>
              keyboard_double_arrow_left
            </span>
          </div>
          <div className="opened-innersidebar-content">
            {companyContent.length > 0 ?
              <div className="custom-collapser mt-1 inner-sidebar-collapser">
                {
                  this.props.company_details.categories.map((category, index) =>
                    companyContent.length > 0 && companyContent.find((cc) => cc.category_id === category.id) &&
                    <div className="mb-1" key={category.id}>
                      {
                        companyContent.length > 0 &&
                        <Scrollspy items={[`${Slugify(category.name)}_${category.id}`]} currentClassName="is-current-parent" >
                          <div className="custom-collapser-item">
                            <Collapsible open={true}
                              trigger={<>
                                <a style={{ display: 'none' }} href={`#${Slugify(category.name)}_${category.id}`} >{category.name}</a>
                                <div className="d-flex align-items-center ml-auto" onClick={() => this.props.handleSelectedCategory(category.name)}>
                                  <span class="material-icons-outlined material-icon">chevron_right</span>
                                </div>
                              </>}
                              triggerSibling={() => (
                                <div className="d-flex align-items-center trigger-block" onClick={() => this.props.handleSelectedCategory(category.name)}>

                                  <input className="custom-checkbox-new" type="checkbox" name={category.name} id={Slugify(category.name)} checked={category.is_selected} onChange={(e) => { e.stopPropagation(); this.toggleCheckbox(e, 'category', category.id) }} />
                                  <h5 className="font-weight-semibold mb-0 title"
                                  
                                  >
                                    <span className="ellipsis">
                                      {category.name}
                                    </span>
                                  </h5>

                                </div>
                              )}
                            >
                              <div className="collapse-panel">
                                <div className="collapse-panel-content" key={category.id}>
                                  <Scrollspy items={category.subcategories.map((s) => `${Slugify(s.name)}_${s.id}`)} currentClassName="is-current-child">
                                    {category.subcategories.map((sub_category, index_sub_cat) =>
                                      companyContent.find((cc) => cc.subcategory_id === sub_category.id) &&
                                      <div className="item d-flex align-items-center" key={sub_category.id}>
                                        <input className="custom-checkbox-new" key={sub_category.id} type="checkbox" name={Slugify(category.name) + '_' + Slugify(sub_category.name) + '_' + (sub_category.id)} id={Slugify(category.name) + '_' + Slugify(sub_category.name) + '_' + (sub_category.id)} checked={sub_category.is_selected} onChange={(e) => { this.toggleCheckbox(e, 'sub_category', category.id, sub_category.id) }} />
                                        <label className='sub-category-label mb-0'
                                          
                                          onClick={() => this.handleCategorySelection(sub_category.name, category.id, sub_category.id)}
                                        >
                                          <span className="ellipsis" >
                                            {sub_category.name}
                                          </span>
                                        </label>
                                        <a style={{ display: 'none' }} href={`${Slugify(sub_category.name)}_${sub_category.id}`} >{sub_category.name}</a>
                                      </div>
                                    )}
                                  </Scrollspy>
                                </div>
                              </div>
                            </Collapsible>
                          </div>
                        </Scrollspy>
                      }
                      {
                        companyContent.length === 0 &&
                        <>
                          <li className="active">
                            <input className="custom-checkbox-new" type="checkbox" name={category.name} id={Slugify(category.name)} checked={category.is_selected} onChange={(e) => { e.stopPropagation(); this.toggleCheckbox(e, 'category', category.id) }} /><label className='category-label' htmlFor={Slugify(category.name)}>{category.name}</label>
                            <a style={{ display: 'none' }} href={`#${Slugify(category.name)}_${category.id}`} >{category.name}</a>
                            <ul className="checkbox_list" key={category.id}>
                              {category.subcategories.map((sub_category, index_sub_cat) =>
                                <li key={sub_category.id}>
                                  <input className="custom-checkbox-new" key={sub_category.id} type="checkbox" name={Slugify(category.name) + '_' + Slugify(sub_category.name) + '_' + (sub_category.id)} id={Slugify(category.name) + '_' + Slugify(sub_category.name) + '_' + (sub_category.id)} checked={sub_category.is_selected} onChange={(e) => { this.toggleCheckbox(e, 'sub_category', category.id, sub_category.id) }} /><label className='sub-category-label' htmlFor={Slugify(category.name) + '_' + Slugify(sub_category.name) + '_' + (sub_category.id)}>{sub_category.name}</label>
                                  <a style={{ display: 'none' }} href={`#${Slugify(sub_category.name)}_${sub_category.id}`} >{sub_category.name}</a>
                                </li>
                              )}
                            </ul>
                          </li>
                        </>
                      }
                    </div>
                  )
                }
              </div>
              :
              <div className="content text-center mt-1">
                No Content
              </div>}
          </div>
          <div className="closed-innersidebar-content d-flex flex-grow-1">
            <div className="content text-center font-weight-semibold">
              CONTENTS
            </div>
          </div>


         
        </div>
      </aside>
    )
  }
}

const mapStateToProps = state => {
  return {
    company_details: state.company_details
  }
}

const mapDispatchToProps = dispatch => {
  return {
    categories: (payload) => {
      dispatch(categories(payload))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetailsCategories)
