import React, { Component } from "react";
import { connect } from "react-redux";
import { formatRoute } from "react-router-named-routes";
import {
  ButtonToggle,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Toast,
} from "reactstrap";

import Notification from "../Components/Notification";
import logo from "../Images/INSIGHTCENTER@2x.png";
import login_building from "../Images/login_building.png";
import login_map from "../Images/login_map.png";
import TBRLogo from "../Images/TBRLogo";
import { UserServices } from "../Services/User";
import {
  loginUserFailure,
  loginUserSuccess,
  openSearchBar,
} from "../Store/Actions/User";
import constants from "../Utils/constants";
import DocumentTitle from "../Utils/DocumentTitle";
import ReactGA from "react-ga4";
import React_GA_Intialise from "../Utils/InitializeGa";
import { ClientServices } from "../Services/Client";
import decodeJWT from "../lib/decodeJWT.js";
import { FeedBackService } from "../Services/FeedBack.js";

class Login extends Component {
  state = {
    loginEmail: "",
    loginPassword: "",
    apiErrors: "",
  };

  componentDidMount() {
    DocumentTitle("Login");
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    this.props.openSearchBar(false);
    UserServices.getUserLogin({
      email: this.state.loginEmail,
      password: this.state.loginPassword,
    })
      .then(async (data) => {
        localStorage.setItem("clientId", data.data.data.clientId);
        localStorage.setItem("token", data.data.data.token.access_token);
        localStorage.setItem(
          "refresh_token",
          data.data.data.token.refresh_token
        );
        localStorage.setItem("display_name", data.data.data.display_name);
        this.props.loginSuccess(data.data.data);

        let feedBackFromRes = await FeedBackService.showFeedBack();
        if (feedBackFromRes.data.data) {
          localStorage.setItem("show_feedback_form", feedBackFromRes.data.data);
        }

        // check if he is admin
        // set the initialisation with user Id
        let is_admin = data.data.data.is_admin;

        if (!is_admin) {
          React_GA_Intialise(data.data.data.id);
          ReactGA.event(constants.GA.CUSTOM_EVENTS.LOGIN);
        }

        const logindata = this.props?.home?.loginUserSuccess;
        const tokenDetails = decodeJWT(localStorage.getItem("token"));
        if (
          !window.location.href.includes(
            `https://${constants.APP_URLS.CLIENT.TEST}/`
          ) ||
          !window.location.href.includes(
            `https://${constants.APP_URLS.CLIENT.STAGE}/`
          )
        ) {
          window.Produktly.identifyUser(logindata?.id, {
            name: `${logindata?.first_name} ${logindata?.last_name}`,
            email: logindata?.email,
            companyId: tokenDetails?.client?.name,
          });
        }

        const searchParams = new URLSearchParams(document.location.search);
        if (searchParams.get("redirect_url")) {
          window.location.href = searchParams.get("redirect_url");
        } else {
          this.props.history.push(
            formatRoute(constants.APPLICATION_ROUTE.DASHBOARD_LIST.ROUTE, {})
          );
        }
      })
      .catch((error) => {
        this.setState({
          apiErrors:
            error && error.data && error.data.error
              ? error.data.error
              : constants.ERROR.SOMETHING_WENT_WRONG,
        });
      });
  };
  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  render() {
    return (
      <div className="wrapper login_page">
        <main>
          <Container fluid>
            <Row>
              <aside className="col-md-6 px-0 hide-in-mobille">
                <div className="login_left">
                  <figure className="login_banner">
                    <img
                      src={login_building}
                      className="img-fluid"
                      alt="login banner"
                      title="login banner"
                    />
                  </figure>
                  <a href="/">
                    <img
                      style={{ width: "120px", marginLeft: "20px" }}
                      src="https://tbr-ggk.s3.us-east-2.amazonaws.com/production/TBR_2color_tagline.svg"
                      alt="TBR logo"
                    />
                    {/* <TBRLogo />
                    <span>{constants.PAGE.LOGIN.LOGO_TEXT}</span> */}
                  </a>
                </div>
              </aside>
              <aside className="col-md-6 px-0">
                <div className="login_right">
                  <figure>
                    <img
                      src={login_map}
                      className="img-fluid"
                      alt="map Banner"
                    />
                  </figure>
                  <Form onSubmit={this.handleSubmit} method="post">
                    <span className="login_formlogos">
                      <img src={logo} className="img-fluid" alt="logo" />
                    </span>
                    {this.state.apiErrors.length > 0 && (
                      <Notification
                        color={"danger"}
                        message={this.state.apiErrors}
                      />
                    )}
                    <FormGroup>
                      <Label htmlFor="loginEmail">Email</Label>
                      <Input
                        type="email"
                        placeholder="Email"
                        id="loginEmail"
                        name="loginEmail"
                        onChange={this.handleInputChange}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor="loginPassword">Password</Label>
                      <Input
                        type="password"
                        placeholder="Password"
                        id="loginPassword"
                        name="loginPassword"
                        onChange={this.handleInputChange}
                      />
                    </FormGroup>
                    <FormGroup>
                      <ButtonToggle
                        className={
                          this.state.loginEmail && this.state.loginPassword
                            ? ""
                            : "disabled"
                        }
                        disabled={
                          !(this.state.loginEmail && this.state.loginPassword)
                        }
                        color="primary"
                        type="submit"
                      >
                        Submit
                      </ButtonToggle>
                      <div className="text-center">
                        <a href="/forgot-password">Forgot Password ?</a>
                      </div>
                    </FormGroup>
                  </Form>
                  <div className="terms-conditions">
                    <a
                      className="links"
                      href="https://tbrdevfiles.s3.amazonaws.com/documents/TBR+Terms+of+Service.pdf"
                      target="_blank"
                    >
                      Terms and Conditions
                    </a>{" "}
                    |
                    <a
                      className="links"
                      href="https://tbrdevfiles.s3.amazonaws.com/documents/TBR+Privacy+Policy.pdf"
                      target="_blank"
                    >
                      Privacy Policy
                    </a>
                  </div>
                </div>
              </aside>
            </Row>
          </Container>
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    home: state.home,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    loginSuccess: (user) => {
      dispatch(loginUserSuccess(user));
    },
    loginFailure: (user) => {
      dispatch(loginUserFailure(user));
    },
    openSearchBar: (data) => {
      dispatch(openSearchBar(data));
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Login);
