import { company_details as DETAILS } from '../actionConstants'

const companyCategories = (state = {}, action) => {
  switch (action.type) {
    case DETAILS.COMPANY_CATEGORIES:
      return action.payload
    default:
      return state
  }
}

const companySegments = (state = {}, action) => {
  switch (action.type) {
    case DETAILS.COMPANY_SEGMENTS:
      return action.payload
    default:
      return state
  }
}

const companyDetails = (state = {}, action) => {
  switch (action.type) {
    case DETAILS.COMPANY_DETAILS:
      return action.payload
    default:
      return state
  }
}

const categories = (state = [], action) => {
  switch (action.type) {
    case DETAILS.CATEGORIES:
      return action.payload
    default:
      return state
  }
}

const companyContent = (state = {}, action) => {
  switch (action.type) {
    case DETAILS.COMPANY_CONTENT:
      return action.payload
    default:
      return state
  }
}

const companyFinancialDetails = (state = {}, action) => {
  switch (action.type) {
    case DETAILS.COMPANY_FINANCIAL_DETAILS:
      return action.payload
    default:
      return state
  }
}

const companyFinancialRadarChartDetails = (state = {}, action) => {
  switch (action.type) {
    case DETAILS.COMPANY_FINANCIAL_RADAR_CHART_DETAILS:
      return action.payload
    default:
      return state
  }
}

export {
  companyCategories,
  companySegments,
  companyDetails,
  categories,
  companyContent,
  companyFinancialDetails,
  companyFinancialRadarChartDetails
}
