import React, { useState, useRef, useEffect } from "react";
import { formatRoute } from "react-router-named-routes";
import { connect } from "react-redux";
import * as am4core from "@amcharts/amcharts4/core";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import DynamicTableComponent from "../Components/DynamicTableComponent";
import BoardDashboard from "../Components/BoardDashboard";
import backgroud_image from "../Images/dashboard_header.svg";
import arrow from "../Images/right-arrow.svg";
import constants from "../Utils/constants";
import Toast from "../Utils/Toast";
import MoneyFormatter from "../Utils/MoneyFormatter";
import CustomReactTooltip from "../Components/CustomReactTooltip";
import CustomEllipsis from "../Utils/CustomEllipsis";
import ComapnyDetailsDateTimeFormatter from "../Utils/ComapnyDetailsDateTimeFormatter";
import ComapnyDetailsDateFormatter from "../Utils/ComapnyDetailsDateFormatter";
import decodeJWT from "../lib/decodeJWT";
import { boardType } from "../Store/Actions/Board";
import TimeSince from "../Utils/TimeSince";
import { useHistory } from "react-router-dom";
import {
  activeMenuTab,
  openSearchBar,
  getSearchText,
} from "../Store/Actions/User";
import { DashboardServices } from "../Services/Dashboard";
import { BoardServices } from "../Services/Board";
import { VisualizationServices } from "../Services/Visualization";
import { CompanyServices } from "../Services/Company";
import { OpenSearchServices } from "../Services/OpenSearch";
import DailogNew from "../Components/DailogNew";
import { CalendarServices } from "../Services/Calendar";
import {
  ActiveVendorReport,
  InitialResponse,
  VendorProfile,
  VendorReport,
  BenchmarkCoverage,
  VendorSnapshot,
  ActiveVendorProfile,
  ActiveVendorSnapshot,
  ActiveBenchmarkCoverage,
  InactiveVendorSnapshot,
  InactiveVendorProfile,
  InactiveBenchmarkCoverage,
  InactiveVendorReport,
  NoAccessVendorReport,
  NoAccessBenchmarkCoverage,
  NoAccessVendorProfile,
  NoAccessVendorSnapshot,
} from "../Components/SVGComponents/SVGComponent";
import NoRecordFoundImage from "./SvgIcons/NoRecordFound.svg";
import DataNotAvailable from "../Pages/SvgIcons/ReportNotFound.svg";

import { debounce, orderBy } from "lodash";
import PieChartDashboard from "../Components/PieChartDashboard";
import Dropdown from "react-multilevel-dropdown";
import { DashboardTables } from "../Utils/DashboardTables";
import ReportUsageTable from "../Components/ReportUsageTable";
import AccessReportStatus from "../Components/AccessReportStatus";
import RatingForm from "../Components/RatingFrom";
import UpcomingEventsDashboard from "../Components/UpcomingEventsDashboard";
// import GenAi_icon from "../../src/Images/GenAi_icon.gif";

const selectionTab = [
  {
    label: "Company Research",
    value: "company",
    items: [
      {
        label: "Company Research",
        value: "vendor_reports",
      },
      {
        label: "Company Profile",
        value: "vendor_profiles",
      },
      {
        label: "Company Snapshots",
        value: "vendor_snapshot",
      },
      {
        label: "Benchmark Coverage",
        value: "benchmark_coverage",
      },
    ],
  },
  {
    label: "Benchmarks",
    value: "benchmark",
  },
  {
    label: "Market Forecasts",
    value: "market_forecast",
  },
  {
    label: "Market Landscapes",
    value: "market_landscape",
  },
  {
    label: "Special Reports",
    value: "special_report",
  },
  {
    label: "Customer Studies",
    value: "customer_study",
  },
  {
    label: "Ecosystems",
    value: "ecosystem",
  },
];
const dashboardTopCard = [
  {
    id: 1,
    title: "Company Research",
    isHovered: false,
    sub_type: "company",
    type: "company",
    icon: "meeting_room",
    view_count: 0,
    total_counts: 0,
  },
  {
    id: 2,
    title: "Benchmarks",
    isHovered: false,
    type: "benchmark",
    sub_type: "benchmarks",
    icon: "speed",
    view_count: 0,
    total_counts: 0,
  },
  {
    id: 3,
    title: "Market Forecasts",
    isHovered: false,
    type: "forecast",
    sub_type: "forecasts",
    icon: "trending_up",
    view_count: 0,
    total_counts: 0,
  },
  {
    id: 4,
    title: "Market Landscapes",
    isHovered: false,
    type: "landscape",
    sub_type: "landscapes",
    icon: "analytics",
    view_count: 0,
    total_counts: 0,
  },
  {
    id: 5,
    title: "Special Reports",
    isHovered: false,
    type: "special_reports",
    sub_type: "special_report",
    icon: "summarize",
    view_count: 0,
    total_counts: 0,
  },
  {
    id: 6,
    title: "Customer Studies",
    isHovered: false,
    type: "customer_study",
    sub_type: "customer_study",
    icon: "portrait",
    view_count: 0,
    total_counts: 0,
  },
  {
    id: 7,
    title: "Ecosystems",
    isHovered: false,
    type: "ecosystem",
    sub_type: "ecosystem",
    icon: "public",
    view_count: 0,
    total_counts: 0,
  },
];

const boardOptions = [
  { id: 1, type: "my", isOpen: false },
  { id: 2, type: "tbri", isOpen: false },
  { id: 3, type: "shared", isOpen: false },
  { id: 4, type: "enterprise", isOpen: false },
];

const chartOptions = [
  { id: 1, type: "", isOpen: false },
  { id: 2, type: "tbri", isOpen: false },
  { id: 3, type: "shared", isOpen: false },
  { id: 4, type: "enterprise", isOpen: false },
];

const regex = /(<([^>]+)>)/gi;

const whatsNewReportTypes = [
  {
    id: 1,
    name: "All Reports",
    slug: "all",
    is_checked: true,
    table: DashboardTables.tbrAllReportTable,
  },
  {
    id: 3,
    name: "Company Research",
    slug: "company",
    is_checked: false,
    table: DashboardTables.companyTable,
  },
  {
    id: 4,
    name: "Benchmarks",
    slug: "benchmark",
    is_checked: false,
    table: DashboardTables.reportTable,
  },
  {
    id: 5,
    name: "Market Forecasts",
    slug: "market_forecast",
    is_checked: false,
    table: DashboardTables.reportTable,
  },
  {
    id: 6,
    name: "Market Landscapes",
    slug: "market_landscape",
    is_checked: false,
    table: DashboardTables.reportTable,
  },
  {
    id: 7,
    name: "Special Reports",
    slug: "special_report",
    is_checked: false,
    table: DashboardTables.reportTable,
  },
  {
    id: 8,
    name: "Customer Studies",
    slug: "customer_study",
    is_checked: false,
    table: DashboardTables.reportTable,
  },
  {
    id: 9,
    name: "Ecosystems",
    slug: "ecosystem",
    is_checked: false,
    table: DashboardTables.reportTable,
  },
];

const Dashboard = (props) => {
  let history = useHistory();
  const inputRef = useRef(null);
  const [SRCompaniesList, setSRCompaniesList] = useState("");
  const [checked, setChecked] = useState(true);
  const [toggleReport, setToggleReport] = useState(true);
  const [reportUsageData, setReportUsageData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [currentSearchSuggestion, setCurrentSearchSuggestion] = useState(0);
  const [searchSuggestionsLength, setSearchSuggestionsLength] = useState(0);
  const [selectedTableTab, setSelectedTableTab] = useState("company");
  const [segmentData, setSegmentData] = useState([]);
  const [whatsNewData, setWhatsNewData] = useState([]);
  const [company, setCompany] = useState({ rowData: [], columnData: [] });
  const [benchmarks, setBenchmarks] = useState({ rowData: [] });
  const [forecasts, setForecasts] = useState({ rowData: [] });
  const [landscapes, setLandscapes] = useState({ rowData: [] });
  const [special_reports, setSpecialReports] = useState({ rowData: [] });
  const [customer_study, setCustomer_study] = useState({ rowData: [] });
  const [ecosystems, setEcosystems] = useState({ rowData: [] });
  const [recentlyViewData, setRecentlyViewData] = useState([]);
  const [activeWhatsNewTab, setActiveWhatsNewTab] = useState("all");
  const [activeRecentlyViewTab, setActiveRecentlyViewTab] = useState("company");
  const [activeWhatsNewSubTab, setActiveWhatsNewSubTab] =
    useState("vendor_reports");
  const [topBoardCard, setTopBoardCard] = useState(dashboardTopCard);
  const [reportTypes, setReportTypes] = useState(whatsNewReportTypes);
  const [selectedReportViewIsPie, setSelectedReportViewIsPie] = useState(false);

  // board data
  const [allBoardData, setAllBoardData] = useState([]);
  const [myBoardData, setMyBoardData] = useState([]);
  const [TBRIBoardData, setTBRIBoardData] = useState([]);
  const [sharedBoardData, setSharedBoardData] = useState([]);
  const [enterpriseBoardData, setEnterpriseBoardData] = useState([]);

  // visualization data
  const [allVisualizations, setAllVisualizations] = useState([]);
  const [myVisualizations, setMyVisualizations] = useState([]);
  const [TBRIVisualizations, setTBRIVisualizations] = useState([]);
  const [sharedVisualizations, setSharedVisualizations] = useState([]);
  const [finalChartData, setFinalChartData] = useState([]);
  const [enterPriseVisualizations, setEnterPriseVisualizations] = useState([]);
  const [dailogModal, setDailogModal] = useState(false);
  const [boardIsOpen, setBoardIsOpen] = useState(boardOptions);
  const [chartIsOpen, setChartIsOpen] = useState(chartOptions);

  // Upcoming events data
  const [upcomingEvents, setUpcomingEvents] = useState([]);

  let totalVendorProfileData = reportUsageData?.total_vendor_profile;
  let totalVendorReportData = reportUsageData?.total_vendor_reports;
  let totalVendorSnapshotData = reportUsageData?.total_vendor_snapshot;
  let totalBenchmarkCoverageData = reportUsageData?.total_benchmark_coverage;
  let totalMarketForecastData = reportUsageData?.total_market_forecasts;
  let totalMarketLandscapeData = reportUsageData?.total_market_landscapes;
  let totalBenchmarkData = reportUsageData?.total_benchmarks;
  let totalSpecialReportData = reportUsageData?.total_special_reports;
  let totalCustomerStudyData = reportUsageData?.total_customer_studies;
  let totalEcosystemData = reportUsageData?.total_ecosystems;

  let tokenDetails = decodeJWT(localStorage.getItem("token"));
  const getLobs = () => {
    if (segmentData && segmentData.length) {
      return {
        data: {
          data: segmentData,
        },
      };
    } else {
      return CompanyServices.getTotalSegments();
    }
  };

  const modifiedChartAPIData = () => {
    const finalData = [];
    let array = [
      {
        name: "Benchmark",
        value: totalBenchmarkData,
        color: "#239DFF",
      },
      {
        name: "Special Report",
        value: totalSpecialReportData,
        color: "#1D86DC",
      },
      {
        name: "Market Forecast",
        value: totalMarketForecastData,
        color: "#176FB8",
      },
      {
        name: "Market Landscape",
        value: totalMarketLandscapeData,
        color: "#105895",
      },
      {
        name: "Customer Study",
        value: totalCustomerStudyData,
        color: "#0A4171",
      },
      {
        name: "Ecosystem",
        value: totalEcosystemData,
        color: "#011E3C",
      },
    ];
    let vendorSpecificReportObject = {
      name: "Company-Specific Research",
      value:
        totalVendorProfileData +
        totalBenchmarkCoverageData +
        totalVendorReportData +
        totalVendorSnapshotData,
      color: am4core.color("#77C3FE"),
      legendColor: "#77C3FE",
    };
    finalData.push(vendorSpecificReportObject);
    array.forEach((item) => {
      let object = {
        name: item.name,
        value: item.value,
        color: am4core.color(item.color),
        legendColor: item.color,
      };
      finalData.push(object);
    });
    setFinalChartData(finalData);
  };
  useEffect(() => {
    modifiedChartAPIData();
  }, [reportUsageData]);

  // autofocus on search bar
  const autoInputFocus = () => {
    inputRef.current.focus();
  };

  const dailogModalAccept = () => setDailogModal(false);

  const dailogModalDecline = () => setDailogModal(false);

  //fetching Report Usage API Data
  const updateOtherReportsData = (reportData) => {
    let updatedReportData = {};
    if (reportData["others"]) {
      reportData.company = reportData?.reports?.map((report) => report.name);
      return reportData;
    }
    updatedReportData[reportData.name] = reportData.views;
    if (reportData?.company?.length > 0) {
      updatedReportData.company = reportData?.company?.map(
        (companyData) => companyData.name
      );
    } else {
      updatedReportData.company = [];
    }
    return updatedReportData;
  };

  const updateReportsUsageData = (data) => {
    let updatedData = Object.assign({}, data);
    updatedData.vendor_reports_analytics = data.vendor_reports_analytics.map(
      updateOtherReportsData
    );
    updatedData.vendor_profile_analytics = data.vendor_profile_analytics.map(
      updateOtherReportsData
    );
    updatedData.vendor_snapshot_analytics = data.vendor_snapshot_analytics.map(
      updateOtherReportsData
    );
    updatedData.benchmark_coverage_analytics =
      data.benchmark_coverage_analytics.map(updateOtherReportsData);
    updatedData.market_landscape_analytics =
      data.market_landscape_analytics.map(updateOtherReportsData);
    updatedData.benchmark_analytics = data.benchmark_analytics.map(
      updateOtherReportsData
    );
    updatedData.market_forecast_analytics = data.market_forecast_analytics.map(
      updateOtherReportsData
    );
    updatedData.special_report_analytics = data.special_report_analytics.map(
      updateOtherReportsData
    );
    updatedData.customer_study_analytics = data.customer_study_analytics.map(
      updateOtherReportsData
    );
    updatedData.ecosystem_analytics = data.ecosystem_analytics.map(
      updateOtherReportsData
    );
    setReportUsageData(updatedData);
  };

  const fetchingReportUsage = async () => {
    try {
      let url = "mostViewedCompanyReports?";
      let params = "is_table";
      const response = await DashboardServices.getReportUsage(
        `${url}${selectedReportViewIsPie ? "" : params}`
      );
      const data = response.data;
      if (selectedReportViewIsPie) {
        updateReportsUsageData(data);
      } else {
        setReportUsageData(data);
      }
    } catch (error) {
      setReportUsageData([]);
      Toast(
        error && error.data && error.data.error
          ? error.data.error
          : constants.ERROR.SOMETHING_WENT_WRONG,
        "error"
      );
    }
  };

  useEffect(() => {
    fetchingReportUsage();
  }, [selectedReportViewIsPie]);

  // fetching the Dashboard API data
  const fetchingDashboardData = () => {
    Promise.all([getLobs(), DashboardServices.getRecentValues(`recentUpdates`)])
      .then((values) => {
        if (values && values.length) {
          let segmentData = values[0]?.data?.data;
          segmentData.sort((a, b) => (a.name > b.name ? 1 : -1));
          formatCompanyData(segmentData, values[1]?.data?.companies);
          formatBenchmarkData(values[1]?.data?.benchmarks);
          formatForecastData(values[1]?.data?.forecasts);
          formatLandscapesData(values[1]?.data?.landscapes);
          formatSpecialReportsData(values[1]?.data?.special_reports);
          formatCustomerStudyData(values[1]?.data?.customer_studies);
          formatEcosystemData(values[1]?.data?.ecosystems);
          const typeData = values[1]?.data;
          const heroCards = topBoardCard.map((card) => {
            const values = typeData[card.sub_type + "_total_count"];
            if (values) {
              card.view_count = values.view_count ?? 0;
              card.total_counts = values.total_count ?? 0;
            }
            return card;
          });
          setTopBoardCard(heroCards);
        }
      })
      .catch((error) => {
        setCompany({ rowData: [] });
        setBenchmarks({ rowData: [] });
        setForecasts({ rowData: [] });
        setForecasts({ rowData: [] });
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  // handle search input suggestion
  const handleSearchInput = (e) => {
    setSearchInput(e.target.value);
    if (searchInput?.length >= 3) {
      getSearchSuggestions();
    }
  };

  // when user click on search suggestions element
  const navigateToGlobalSearchBar = (searchText) => {
    let strippedHTML = searchText.replace(regex, "");
    const queryParam = encodeURIComponent(strippedHTML);
    const url = `/search?q=${queryParam}`;
    history.push(url);
  };

  // when user click on search icon
  const searchOnGlobal = () => {
    const queryParam = encodeURIComponent(searchInput);
    const url = `/search?q=${queryParam}`;
    history.push(url);
  };

  // getting the Search Suggestions results
  const getSearchSuggestions = debounce(() => {
    let body = {
      search: searchInput,
    };
    OpenSearchServices.fetchSearchSuggestions(body).then((res) => {
      let data = res?.data?.data;
      data = [
        ...new Set(data?.map((ele) => ele?.highlight?.subcategory_title[0])),
      ];
      setSearchResult(data);
      setSearchSuggestionsLength(data?.length);
    });
  }, 200);

  const handleKeywordShortcut = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const queryParam = encodeURIComponent(searchInput);
      const url = `/search?q=${queryParam}`;
      history.push(url);
    } else if (e.key === "ArrowDown") {
      e.preventDefault();
      if (e.target.id === "searchInput") {
        let searchText =
          currentSearchSuggestion === searchSuggestionsLength
            ? 1
            : currentSearchSuggestion + 1;
        setCurrentSearchSuggestion(searchText);
      }
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      if (e.target.id === "searchInput") {
        let searchText =
          currentSearchSuggestion <= 1
            ? searchSuggestionsLength
            : currentSearchSuggestion - 1;
        setCurrentSearchSuggestion(searchText);
      }
    }
  };

  const redirectToBigTable = (selectedTab) => {
    let path = "";
    switch (selectedTab) {
      case "company":
        path = constants.APPLICATION_ROUTE.COMPANY.LIST.ROUTE;
        break;
      case "benchmark":
        path = constants.APPLICATION_ROUTE.BENCHMARK.LIST.ROUTE;
        break;
      case "forecast":
      case "market_forecast":
        path = constants.APPLICATION_ROUTE.MARKET_FORECAST.LIST.ROUTE;
        break;
      case "landscape":
      case "market_landscape":
        path = constants.APPLICATION_ROUTE.MARKET_LANDSCAPE.LIST.ROUTE;
        break;
      case "special_reports":
      case "special_report":
        path = constants.APPLICATION_ROUTE.SPECIAL_REPORTS.LIST.ROUTE;
        break;
      case "customer_study":
        path = constants.APPLICATION_ROUTE.CUSTOMER_STUDIES.LIST.ROUTE;
        break;
      case "ecosystem":
        path = constants.APPLICATION_ROUTE.ECOSYSTEM.LIST.ROUTE;
        break;
    }
    history.push(formatRoute(path, {}));
  };

  useEffect(() => {
    let searchTextWithoutRegex = searchResult[currentSearchSuggestion - 1];
    let searchTextWithRegex = searchTextWithoutRegex?.replace(regex, "");
    setSearchInput(searchTextWithRegex);
  }, [currentSearchSuggestion]);

  // clear the search input, when user come back from to main search bar screen
  useEffect(() => {
    setSearchInput("");
    setCurrentSearchSuggestion(0);
  }, [props.home.openSearchBar]);

  useEffect(() => {
    props.getSearchText("");
    props.activeMenuTab(constants.APPLICATION_ROUTE.DASHBOARD_LIST.ROUTE);
    fetchingDashboardData();
    autoInputFocus();
    getIndividualBoardData("all");
    getIndividualVisulizationData("all");
  }, [checked]);

  useEffect(() => {
    getIndividualBoardData("all");
    getIndividualVisulizationData("all");
    getCalendarEvents();
    const unHoveredAllTopCards = () => {
      topBoardCard.map((board) => {
        board.isHovered = false;
      });
    };
    unHoveredAllTopCards();
    // default - the first one report to be select on initial render
    const updateReportTypes = reportTypes.map((report, index) => ({
      ...report,
      is_checked: index === 0,
    }));
    setReportTypes(updateReportTypes);
  }, []);

  useEffect(() => {
    if (activeWhatsNewTab === "reports") {
      reportTypes.forEach((report) => {
        if (report.is_checked) getWhatsNewApiData(report.slug);
      });
    } else {
      getWhatsNewApiData(activeWhatsNewTab);
    }
  }, [activeWhatsNewTab, checked, reportTypes]);

  useEffect(() => {
    getRecentlyViewApiData(activeRecentlyViewTab);
  }, [activeRecentlyViewTab]);

  const callBoardApiWithType = (type) => getIndividualBoardData(type);
  const callVisulizationWithType = (type) =>
    getIndividualVisulizationData(type);

  const formatPrice = (price) => {
    if (price && parseInt(price))
      return (
        "$" +
        price
          .toFixed(0)
          .toString()
          .replace(/./g, function (c, i, a) {
            return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
          })
      );
    else return 0;
  };

  // Go to Companies List Page
  const goToCompanySegment = (
    access,
    company,
    segment,
    company_segment_ic_is_active = 1
  ) => {
    if (access && company_segment_ic_is_active === 1) {
      history.push({
        pathname: formatRoute(constants.APPLICATION_ROUTE.COMPANY.VIEW.ROUTE, {
          id: company.id,
        }),
        search: "?segment=" + segment.key,
      });
    }
  };

  const goToCompanyDetail = (type, access, company) => {
    if (access) {
      let path;

      switch (type) {
        case "company":
          path = constants.APPLICATION_ROUTE.COMPANY.VIEW.ROUTE;
          break;
        case "benchmark":
          path = constants.APPLICATION_ROUTE.BENCHMARK.VIEW.ROUTE;
          break;
        case "forecast":
          path = constants.APPLICATION_ROUTE.MARKET_FORECAST.VIEW.ROUTE;
          break;
        case "special_report":
          path = constants.APPLICATION_ROUTE.SPECIAL_REPORTS.VIEW.ROUTE;
          break;
        case "customer_study":
          path = constants.APPLICATION_ROUTE.CUSTOMER_STUDIES.VIEW.ROUTE;
          break;
        case "ecosystem":
          path = constants.APPLICATION_ROUTE.ECOSYSTEM.VIEW.ROUTE;
          break;
        default:
          path = constants.APPLICATION_ROUTE.MARKET_LANDSCAPE.VIEW.ROUTE;
          break;
      }

      history.push({
        pathname: formatRoute(path, {
          id: company.id,
        }),
      });
    }
  };

  // Recently Viewed Detail Page Method
  const goToReportViewedDetailPage = (type, data) => {
    let id;
    let path;
    if (type === "company") {
      history.push({
        pathname: formatRoute(constants.APPLICATION_ROUTE.COMPANY.VIEW.ROUTE, {
          id: data.company_id,
        }),
        search: "?segment=" + data.segment_id,
      });
    } else {
      switch (type) {
        case "projects":
          path = constants.APPLICATION_ROUTE.PROJECT.VIEW.ROUTE;
          id = data.id;
          break;
        case "benchmark":
          path = constants.APPLICATION_ROUTE.BENCHMARK.VIEW.ROUTE;
          id = data.id;
          break;
        case "market_forecast":
          path = constants.APPLICATION_ROUTE.MARKET_FORECAST.VIEW.ROUTE;
          id = data.id;
          break;
        case "market_landscape":
          path = constants.APPLICATION_ROUTE.MARKET_LANDSCAPE.VIEW.ROUTE;
          id = data.id;
          break;
        case "special_report":
          path = constants.APPLICATION_ROUTE.SPECIAL_REPORTS.VIEW.ROUTE;
          id = data.id;
          break;
        case "customer_study":
          path = constants.APPLICATION_ROUTE.CUSTOMER_STUDIES.VIEW.ROUTE;
          id = data.id;
          break;
        case "ecosystem":
          path = constants.APPLICATION_ROUTE.ECOSYSTEM.VIEW.ROUTE;
          id = data.id;
          break;
        case "boards":
          path = constants.APPLICATION_ROUTE.MY_BOARDS.DASHBOARD.ROUTE;
          id = data.id;
          break;
        case "visualization":
          path =
            constants.APPLICATION_ROUTE.VISUALIZATION.PREVIEW_WITH_ID.ROUTE;
          id = data.id;
          break;

        default:
          break;
      }
      history.push({
        pathname: formatRoute(path, { id }),
      });
    }
  };

  const formatCompanyData = (segments, companyData) => {
    let segmentData = segments.filter((segment) => segment?.is_active > 0);
    segmentData =
      segmentData &&
      segmentData.length &&
      segmentData.map((segment, key) => {
        return {
          title: segment.name,
          id: `${segment.name}||${segment.id}`,
          is_active: segment.is_active,
          key: segment.id,
          className: `segment-col text-center`,
          sortable: false,
        };
      });
    companyData = companyData.map((data) => {
      let company_access = data.allowed_access
        ? data.allowed_access
        : data?.segments?.filter((s) => s.allowed_access === true).length > 0;
      return {
        id: data.id,
        name: (
          <span
            className={"link"}
            onClick={() => {
              goToCompanyDetail("company", company_access, data);
            }}
          >
            <label className="mb-0">{data.name}</label>
          </span>
        ),
        annual_income: formatPrice(data.annual_income),
        quarterly_growth: MoneyFormatter(data.quarterly_growth, "0.00"),
        yearly_growth: MoneyFormatter(data.yearly_growth, "0.00"),
        segments: data?.segments?.map((data) => data.name),
        org_segments: data.segments,
        allowed_access: data.allowed_access ? data.allowed_access : false,
        is_active: data.is_active ? data.is_active : 0,
        custom_classes: [data.allowed_access ? "" : "not_allowed_access"],
      };
    });
    let segmentObj = {};

    let columnData = [
      {
        title: "Company",
        id: "name",
        sortable: false,
        style: {
          textAlign: "left",
          textTransform: "capitalize",
          width: "200px",
          zIndex: 9,
        },
        className: `company_name`,
      },
    ];
    const newSegmentData = segmentData.filter(
      (d) => !d.title.includes("Earnings Response")
    );
    columnData = [...columnData, ...newSegmentData];
    let rowData = [];
    for (let company = 0; company < companyData.length; company++) {
      for (let segment = 0; segment < segmentData.length; segment++) {
        let obj = {};
        let ind = companyData[company].org_segments.findIndex(
          (o) => o.id === segmentData[segment].key
        );

        if (ind >= 0) {
          companyData[company].org_segments[ind].allowed_access = companyData[
            company
          ].allowed_access
            ? companyData[company].allowed_access
            : companyData[company].org_segments[ind].allowed_access
            ? companyData[company].org_segments[ind].allowed_access
            : false;
          companyData[company].org_segments[ind].company_segment_ic_is_active =
            companyData[company].is_active === 1
              ? companyData[company].org_segments[ind]
                  .company_segment_ic_is_active === 1
                ? companyData[company].org_segments[ind]
                    .company_segment_ic_is_active
                : 0
              : 0;

          let action;
          action = companyData[company].org_segments[ind].allowed_access ? (
            companyData[company].org_segments[ind].company_segment.is_active ===
            1 ? (
              <>
                <span>
                  {companyData[company].org_segments[ind].company_segment
                    .coverage_type === "analyst_team" ? (
                    <>
                      <i
                        className="allowed-access-icon"
                        onClick={() => {
                          goToCompanySegment(
                            companyData[company].org_segments[ind]
                              .allowed_access,
                            companyData[company],
                            segmentData[segment]
                          );
                        }}
                        data-for={`tooltip-${companyData[company].id}-${segmentData[segment].id}-active`}
                        data-tip={
                          segmentData[segment].title.includes(
                            "Earnings Response"
                          )
                            ? "Active Earnings Response"
                            : "Active Full Report"
                        }
                      >
                        {/* <span class="material-icons-outlined">group</span> */}
                        <ActiveVendorProfile />
                      </i>
                      <CustomReactTooltip
                        id={`tooltip-${companyData[company].id}-${segmentData[segment].id}-active`}
                        multiline={true}
                      />
                    </>
                  ) : companyData[company].org_segments[ind].company_segment
                      .coverage_type === "dedicated_analyst" ? (
                    <>
                      <i
                        className="allowed-access-icon"
                        onClick={() => {
                          goToCompanySegment(
                            companyData[company].org_segments[ind]
                              .allowed_access,
                            companyData[company],
                            segmentData[segment]
                          );
                        }}
                        data-for={`tooltip-${companyData[company].id}-${segmentData[segment].id}-active`}
                        data-tip={
                          segmentData[segment].title.includes(
                            "Earnings Response"
                          )
                            ? "Active Earnings Response"
                            : "Active Full Report"
                        }
                      >
                        {/* <span class="material-icons-outlined">
                          perm_identity
                        </span> */}
                        <ActiveVendorReport />
                      </i>
                      <CustomReactTooltip
                        id={`tooltip-${companyData[company].id}-${segmentData[segment].id}-active`}
                        multiline={true}
                      />
                    </>
                  ) : companyData[company].org_segments[ind].company_segment
                      .coverage_type === "benchmark_coverage" ? (
                    <>
                      <i
                        className="allowed-access-icon"
                        onClick={() => {
                          goToCompanySegment(
                            companyData[company].org_segments[ind]
                              .allowed_access,
                            companyData[company],
                            segmentData[segment]
                          );
                        }}
                        data-for={`tooltip-${companyData[company].id}-${segmentData[segment].id}-active`}
                        data-tip={
                          segmentData[segment].title.includes(
                            "Earnings Response"
                          )
                            ? "Active Earnings Response"
                            : "Active Full Report"
                        }
                      >
                        {/* <span class="material-icons-outlined">
                          person_pin_circle
                        </span> */}
                        <ActiveBenchmarkCoverage />
                      </i>
                      <CustomReactTooltip
                        id={`tooltip-${companyData[company].id}-${segmentData[segment].id}-active`}
                        multiline={true}
                      />
                    </>
                  ) : (
                    <>
                      <i
                        className="allowed-access-icon"
                        onClick={() => {
                          goToCompanySegment(
                            companyData[company].org_segments[ind]
                              .allowed_access,
                            companyData[company],
                            segmentData[segment]
                          );
                        }}
                        data-for={`tooltip-${companyData[company].id}-${segmentData[segment].id}-active`}
                        data-tip={
                          segmentData[segment].title.includes(
                            "Earnings Response"
                          )
                            ? "Active Earnings Response"
                            : "Active Full Report"
                        }
                      >
                        {/* <span class="material-icons-outlined">groups</span> */}
                        <ActiveVendorSnapshot />
                      </i>
                      <CustomReactTooltip
                        id={`tooltip-${companyData[company].id}-${segmentData[segment].id}-active`}
                        multiline={true}
                      />
                    </>
                  )}
                </span>
              </>
            ) : (
              <>
                <span>
                  {companyData[company].org_segments[ind].company_segment
                    .coverage_type === "analyst_team" ? (
                    <>
                      <i
                        className="inactive-allowed-access-icon"
                        onClick={() => {
                          goToCompanySegment(
                            companyData[company].org_segments[ind]
                              .allowed_access,
                            companyData[company],
                            segmentData[segment]
                          );
                        }}
                        data-for={`tooltip-${companyData[company].id}-${segmentData[segment].id}-inactive`}
                        data-tip={
                          segmentData[segment].title.includes(
                            "Earnings Response"
                          )
                            ? "Inactive Earnings Response"
                            : "Inactive Full Report"
                        }
                      >
                        {/* <span class="material-icons-outlined">group</span> */}
                        <InactiveVendorProfile />
                      </i>
                      <CustomReactTooltip
                        id={`tooltip-${companyData[company].id}-${segmentData[segment].id}-inactive`}
                        multiline={true}
                      />
                    </>
                  ) : companyData[company].org_segments[ind].company_segment
                      .coverage_type === "dedicated_analyst" ? (
                    <>
                      <i
                        className="inactive-allowed-access-icon"
                        onClick={() => {
                          goToCompanySegment(
                            companyData[company].org_segments[ind]
                              .allowed_access,
                            companyData[company],
                            segmentData[segment]
                          );
                        }}
                        data-for={`tooltip-${companyData[company].id}-${segmentData[segment].id}-inactive`}
                        data-tip={
                          segmentData[segment].title.includes(
                            "Earnings Response"
                          )
                            ? "Inactive Earnings Response"
                            : "Inactive Full Report"
                        }
                      >
                        {/* <span class="material-icons-outlined">
                          perm_identity
                        </span> */}
                        <InactiveVendorReport />
                      </i>
                      <CustomReactTooltip
                        id={`tooltip-${companyData[company].id}-${segmentData[segment].id}-inactive`}
                        multiline={true}
                      />
                    </>
                  ) : companyData[company].org_segments[ind].company_segment
                      .coverage_type === "benchmark_coverage" ? (
                    <>
                      <i
                        className="inactive-allowed-access-icon"
                        onClick={() => {
                          goToCompanySegment(
                            companyData[company].org_segments[ind]
                              .allowed_access,
                            companyData[company],
                            segmentData[segment]
                          );
                        }}
                        data-for={`tooltip-${companyData[company].id}-${segmentData[segment].id}-inactive`}
                        data-tip={
                          segmentData[segment].title.includes(
                            "Earnings Response"
                          )
                            ? "Inactive Earnings Response"
                            : "Inactive Full Report"
                        }
                      >
                        {/* <span class="material-icons-outlined">
                          person_pin_circle
                        </span> */}
                        <InactiveBenchmarkCoverage />
                      </i>
                      <CustomReactTooltip
                        id={`tooltip-${companyData[company].id}-${segmentData[segment].id}-inactive`}
                        multiline={true}
                      />
                    </>
                  ) : (
                    <>
                      <i
                        className="inactive-allowed-access-icon"
                        onClick={() => {
                          goToCompanySegment(
                            companyData[company].org_segments[ind]
                              .allowed_access,
                            companyData[company],
                            segmentData[segment]
                          );
                        }}
                        data-for={`tooltip-${companyData[company].id}-${segmentData[segment].id}-inactive`}
                        data-tip={
                          segmentData[segment].title.includes(
                            "Earnings Response"
                          )
                            ? "Inactive Earnings Response"
                            : "Inactive Full Report"
                        }
                      >
                        {/* <span class="material-icons-outlined">groups</span> */}
                        <InactiveVendorSnapshot />
                      </i>
                      <CustomReactTooltip
                        id={`tooltip-${companyData[company].id}-${segmentData[segment].id}-inactive`}
                        multiline={true}
                      />
                    </>
                  )}
                </span>
              </>
            )
          ) : (
            <>
              <span>
                {companyData[company].org_segments[ind].company_segment
                  .coverage_type === "analyst_team" ? (
                  <>
                    <i
                      className="not-allowed-access-icon"
                      onClick={() => {
                        goToCompanySegment(
                          companyData[company].org_segments[ind].allowed_access,
                          companyData[company],
                          segmentData[segment]
                        );
                      }}
                      data-for={`tooltip-${companyData[company].id}-${segmentData[segment].id}-noaccess`}
                      data-tip={
                        segmentData[segment].title.includes("Earnings Response")
                          ? "No Access Earnings Response"
                          : "No Access Full Report"
                      }
                    >
                      {/* <span className="material-icons-outlined">group</span> */}
                      <NoAccessVendorProfile />
                    </i>
                    <CustomReactTooltip
                      id={`tooltip-${companyData[company].id}-${segmentData[segment].id}-noaccess`}
                      multiline={true}
                    />
                  </>
                ) : companyData[company].org_segments[ind].company_segment
                    .coverage_type === "dedicated_analyst" ? (
                  <>
                    <i
                      className="not-allowed-access-icon"
                      onClick={() => {
                        goToCompanySegment(
                          companyData[company].org_segments[ind].allowed_access,
                          companyData[company],
                          segmentData[segment]
                        );
                      }}
                      data-for={`tooltip-${companyData[company].id}-${segmentData[segment].id}-noaccess`}
                      data-tip={
                        segmentData[segment].title.includes("Earnings Response")
                          ? "No Access Earnings Response"
                          : "No Access Full Report"
                      }
                    >
                      {/* <span className="material-icons-outlined">
                        perm_identity
                      </span> */}
                      <NoAccessVendorReport />
                    </i>
                    <CustomReactTooltip
                      id={`tooltip-${companyData[company].id}-${segmentData[segment].id}-noaccess`}
                      multiline={true}
                    />
                  </>
                ) : companyData[company].org_segments[ind].company_segment
                    .coverage_type === "benchmark_coverage" ? (
                  <>
                    <i
                      className="not-allowed-access-icon"
                      onClick={() => {
                        goToCompanySegment(
                          companyData[company].org_segments[ind].allowed_access,
                          companyData[company],
                          segmentData[segment]
                        );
                      }}
                      data-for={`tooltip-${companyData[company].id}-${segmentData[segment].id}-noaccess`}
                      data-tip={
                        segmentData[segment].title.includes("Earnings Response")
                          ? "No Access Earnings Response"
                          : "No Access Full Report"
                      }
                    >
                      {/* <span className="material-icons-outlined">
                        person_pin_circle
                      </span> */}
                      <NoAccessBenchmarkCoverage />
                    </i>
                    <CustomReactTooltip
                      id={`tooltip-${companyData[company].id}-${segmentData[segment].id}-noaccess`}
                      multiline={true}
                    />
                  </>
                ) : (
                  <>
                    <i
                      className="not-allowed-access-icon"
                      onClick={() => {
                        goToCompanySegment(
                          companyData[company].org_segments[ind].allowed_access,
                          companyData[company],
                          segmentData[segment]
                        );
                      }}
                      data-for={`tooltip-${companyData[company].id}-${segmentData[segment].id}-noaccess`}
                      data-tip={
                        segmentData[segment].title.includes("Earnings Response")
                          ? "No Access Earnings Response"
                          : "No Access Full Report"
                      }
                    >
                      {/* <span className="material-icons-outlined">groups</span> */}
                      <NoAccessVendorSnapshot />
                    </i>
                    <CustomReactTooltip
                      id={`tooltip-${companyData[company].id}-${segmentData[segment].id}-noaccess`}
                      multiline={true}
                    />
                  </>
                )}
              </span>
            </>
          );
          obj = {
            [segmentData[segment].id]: action,
          };
        } else {
          obj = { [segmentData[segment].id]: "-" };
        }
        segmentObj = { ...segmentObj, ...obj };
      }
      rowData.push({ ...companyData[company], ...segmentObj });
    }

    let newRowData = rowData.map((rowElement) => {
      const rowWithIR = {};
      const rowWithoutIR = {};

      // Separate the data into two objects
      Object.keys(rowElement).forEach(function (key) {
        if (key.includes("Earnings Response")) {
          rowWithIR[key] = rowElement[key];
        } else {
          rowWithoutIR[key] = rowElement[key];
        }
      });

      // Combine the data
      let newData = { ...rowElement };
      for (const rowIRKey in rowWithIR) {
        for (const rowWithoutIRKey in rowWithoutIR) {
          if (rowIRKey.includes(rowWithoutIRKey.split("||")[0])) {
            const rowIRValue = rowWithIR[rowIRKey];
            const rowWithoutIRValue = rowWithoutIR[rowWithoutIRKey];

            if (rowWithoutIRValue !== "-" && rowIRValue !== "-") {
              newData[rowWithoutIRKey] = (
                <span className="company-icons">
                  {rowWithoutIRValue}&nbsp;|&nbsp;{rowIRValue}
                  <InitialResponse width={10} style={{ marginTop: "-5px" }} />
                </span>
              );
            } else if (rowIRValue !== "-") {
              newData[rowWithoutIRKey] = (
                <span className="company-icons">
                  {rowIRValue}
                  <InitialResponse width={10} style={{ marginTop: "-5px" }} />
                </span>
              );
            } else {
              newData[rowWithoutIRKey] = rowWithoutIRValue;
            }
          }
        }
      }
      return newData;
    });

    setSegmentData(segments);
    setCompany({
      columnData: columnData,
      rowData: newRowData,
    });
  };

  const formatBenchmarkData = (clientData) => {
    clientData = clientData.map((data) => {
      let companiesTooltip = CustomEllipsis(
        data.companies && data.companies.length > 0
          ? data.companies.map((c) => c.name).join(", ")
          : "-"
      );
      return {
        id: data.id,
        name: (
          <span
            style={{ color: "#046CD9", cursor: "pointer" }}
            onClick={() => {
              goToCompanyDetail(
                "benchmark",
                data.is_allowed ? true : false,
                data
              );
            }}
          >
            {data.name}
          </span>
        ),
        is_active: data.is_allowed ? (
          data.is_active === 1 ? (
            <span
              onClick={() =>
                goToCompanyDetail(
                  "benchmark",
                  data.is_allowed ? true : false,
                  data
                )
              }
              style={{ marginRight: "25px" }}
              className="material-icons-round icon allowed-access text-green"
              aria-hidden="true"
              data-for={`tooltip-${data.id}-active`}
              data-tip={"Active"}
              data-iscapture="true"
            >
              circle
              <CustomReactTooltip
                id={`tooltip-${data.id}-active`}
                multiline={true}
              />
            </span>
          ) : (
            <span
              style={{ marginRight: "25px" }}
              className="material-icons-round icon text-orange allowed-access allowed-access-warning"
              aria-hidden="true"
              data-for={`tooltip-${data.id}-inactive`}
              data-tip={"Inactive"}
              data-iscapture="true"
            >
              circle
              <CustomReactTooltip
                id={`tooltip-${data.id}-inactive`}
                multiline={true}
              />
            </span>
          )
        ) : (
          <>
            <span
              style={{ marginRight: "25px" }}
              className="material-icons-round icon not-allowed-access text-grey"
              aria-hidden="true"
              data-for={`tooltip-${data.id}-noaccess`}
              data-tip={"No Access"}
              data-iscapture="true"
            >
              circle
              <CustomReactTooltip
                id={`tooltip-${data.id}-noaccess`}
                multiline={true}
              />
            </span>
          </>
        ),
        segment_id: `${
          data.segment && data.segment.id ? data.segment.name : "-"
        }`,
        companies: (
          <span className="datagrid_tooltip d-flex align-items-center">
            {companiesTooltip.text}
            {companiesTooltip.ellipsis && (
              <span
                className="material-icons-round icon"
                data-for={`tooltip-companies-${data.id}`}
                data-tip={companiesTooltip.fullText.replace(/,/g, ",<br>")}
                data-iscapture="true"
              >
                info
                <CustomReactTooltip
                  id={`tooltip-companies-${data.id}`}
                  multiline={true}
                />
              </span>
            )}
          </span>
        ),
        created_by: `${
          data.created_by_user && data.created_by_user.id
            ? `${data.created_by_user.first_name} ${data.created_by_user.last_name}`
            : "-"
        }`,
        updated_at: data.updated_at
          ? ComapnyDetailsDateTimeFormatter(data.updated_at, false)
          : ComapnyDetailsDateTimeFormatter(new Date(), false),
      };
    });
    setBenchmarks({ rowData: clientData });
  };

  const formatForecastData = (clientData) => {
    clientData = clientData.map((data) => {
      let companiesTooltip = CustomEllipsis(
        data.companies && data.companies.length > 0
          ? data.companies.map((c) => c.name).join(", ")
          : "-"
      );
      return {
        id: data.id,
        name: (
          <span
            style={{ padding: 0, color: "#046CD9", cursor: "pointer" }}
            onClick={() =>
              goToCompanyDetail(
                "forecast",
                data.is_allowed ? true : false,
                data
              )
            }
          >
            {data.name}
          </span>
        ),
        is_active: data.is_allowed ? (
          data.is_active === 1 ? (
            <span
              onClick={() =>
                goToCompanyDetail(
                  "forecast",
                  data.is_allowed ? true : false,
                  data
                )
              }
              style={{ marginRight: "25px" }}
              className="material-icons-round icon allowed-access text-green"
              aria-hidden="true"
              data-for={`tooltip-${data.id}-active`}
              data-tip={"Active"}
              data-iscapture="true"
            >
              circle
              <CustomReactTooltip
                id={`tooltip-${data.id}-active`}
                multiline={true}
              />
            </span>
          ) : (
            <span
              style={{ marginRight: "25px" }}
              className="material-icons-round icon text-orange allowed-access allowed-access-warning"
              aria-hidden="true"
              data-for={`tooltip-${data.id}-inactive`}
              data-tip={"Inactive"}
              data-iscapture="true"
            >
              circle
              <CustomReactTooltip
                id={`tooltip-${data.id}-inactive`}
                multiline={true}
              />
            </span>
          )
        ) : (
          <span
            style={{ marginRight: "25px" }}
            className="material-icons-round icon not-allowed-access text-grey"
            aria-hidden="true"
            data-for={`tooltip-${data.id}-noaccess`}
            data-tip={"No Access"}
            data-iscapture="true"
          >
            circle
            <CustomReactTooltip
              id={`tooltip-${data.id}-noaccess`}
              multiline={true}
            />
          </span>
        ),
        segment_id: `${
          data.segment && data.segment.id ? data.segment.name : "-"
        }`,
        companies: (
          <span className="datagrid_tooltip">
            {companiesTooltip.text}
            {companiesTooltip.ellipsis && (
              <span
                className="material-icons-round icon"
                data-for={`tooltip-companies-${data.id}`}
                data-tip={companiesTooltip.fullText.replace(/,/g, ",<br>")}
                data-iscapture="true"
              >
                info
                <CustomReactTooltip
                  id={`tooltip-companies-${data.id}`}
                  multiline={true}
                />
              </span>
            )}
          </span>
        ),
        created_by: `${
          data.created_by_user && data.created_by_user.id
            ? `${data.created_by_user.first_name} ${data.created_by_user.last_name}`
            : "-"
        }`,
        updated_at: data.updated_at
          ? ComapnyDetailsDateTimeFormatter(data.updated_at, false)
          : ComapnyDetailsDateTimeFormatter(new Date(), false),
      };
    });
    setForecasts({ rowData: clientData });
  };

  const formatCustomerStudyData = (clientData) => {
    clientData = clientData?.map((data) => {
      let companiesTooltip = CustomEllipsis(
        data?.companies && data?.companies.length > 0
          ? data.companies.map((c) => c.name).join(", ")
          : "-"
      );
      return {
        id: data.id,
        name: (
          <span
            style={{ padding: 0, color: "#046CD9", cursor: "pointer" }}
            onClick={() =>
              goToCompanyDetail(
                "customer_study",
                data.is_allowed ? true : false,
                data
              )
            }
          >
            {data.name}
          </span>
        ),
        is_active: data.is_allowed ? (
          data.is_active === 1 ? (
            <span
              onClick={() =>
                goToCompanyDetail(
                  "customer_study",
                  data.is_allowed ? true : false,
                  data
                )
              }
              style={{ marginRight: "25px" }}
              className="material-icons-round icon allowed-access text-green"
              aria-hidden="true"
              data-for={`tooltip-${data.id}-active`}
              data-tip={"Active"}
              data-iscapture="true"
            >
              circle
              <CustomReactTooltip
                id={`tooltip-${data.id}-active`}
                multiline={true}
              />
            </span>
          ) : (
            <span
              style={{ marginRight: "25px" }}
              className="material-icons-round icon text-orange allowed-access allowed-access-warning"
              aria-hidden="true"
              data-for={`tooltip-${data.id}-inactive`}
              data-tip={"Inactive"}
              data-iscapture="true"
            >
              circle
              <CustomReactTooltip
                id={`tooltip-${data.id}-inactive`}
                multiline={true}
              />
            </span>
          )
        ) : (
          <span
            style={{ marginRight: "25px" }}
            className="material-icons-round icon not-allowed-access text-grey"
            aria-hidden="true"
            data-for={`tooltip-${data.id}-noaccess`}
            data-tip={"No Access"}
            data-iscapture="true"
          >
            circle
            <CustomReactTooltip
              id={`tooltip-${data.id}-noaccess`}
              multiline={true}
            />
          </span>
        ),
        segment_id: `${
          data.segment && data.segment.id ? data.segment.name : "-"
        }`,
        companies: (
          <span className="datagrid_tooltip">
            {companiesTooltip.text}
            {companiesTooltip.ellipsis && (
              <span
                className="material-icons-round icon"
                data-for={`tooltip-companies-${data.id}`}
                data-tip={companiesTooltip.fullText.replace(/,/g, ",<br>")}
                data-iscapture="true"
              >
                info
                <CustomReactTooltip
                  id={`tooltip-companies-${data.id}`}
                  multiline={true}
                />
              </span>
            )}
          </span>
        ),
        created_by: `${
          data.created_by_user && data.created_by_user.id
            ? `${data.created_by_user.first_name} ${data.created_by_user.last_name}`
            : "-"
        }`,
        updated_at: data.updated_at
          ? ComapnyDetailsDateTimeFormatter(data.updated_at, false)
          : ComapnyDetailsDateTimeFormatter(new Date(), false),
      };
    });
    setCustomer_study({ rowData: clientData });
  };

  const formatLandscapesData = (clientData) => {
    clientData = clientData?.map((data) => {
      let companiesTooltip = CustomEllipsis(
        data?.companies && data?.companies.length > 0
          ? data.companies.map((c) => c.name).join(", ")
          : "-"
      );
      return {
        id: data.id,
        name: (
          <span
            style={{ padding: 0, color: "#1085c6", cursor: "pointer" }}
            onClick={() =>
              goToCompanyDetail(
                "landscape",
                data.is_allowed ? true : false,
                data
              )
            }
          >
            {data.name}
          </span>
        ),
        is_active: data.is_allowed ? (
          data.is_active === 1 ? (
            <span
              onClick={() =>
                goToCompanyDetail(
                  "landscape",
                  data.is_allowed ? true : false,
                  data
                )
              }
              style={{ marginRight: "25px" }}
              className="material-icons-round icon allowed-access text-green"
              aria-hidden="true"
              data-for={`tooltip-${data.id}-active`}
              data-tip={"Active"}
              data-iscapture="true"
            >
              circle
              <CustomReactTooltip
                id={`tooltip-${data.id}-active`}
                multiline={true}
              />
            </span>
          ) : (
            <span
              style={{ marginRight: "25px" }}
              className="material-icons-round icon text-orange allowed-access allowed-access-warning"
              aria-hidden="true"
              data-for={`tooltip-${data.id}-inactive`}
              data-tip={"Inactive"}
              data-iscapture="true"
            >
              circle
              <CustomReactTooltip
                id={`tooltip-${data.id}-inactive`}
                multiline={true}
              />
            </span>
          )
        ) : (
          <>
            <span
              style={{ marginRight: "25px" }}
              className="material-icons-round icon not-allowed-access text-grey"
              aria-hidden="true"
              data-for={`tooltip-${data.id}-noaccess`}
              data-tip={"No Access"}
              data-iscapture="true"
            >
              circle
              <CustomReactTooltip
                id={`tooltip-${data.id}-noaccess`}
                multiline={true}
              />
            </span>
          </>
        ),
        segment_id: `${
          data.segment && data.segment.id ? data.segment.name : "-"
        }`,
        companies: (
          <span className="datagrid_tooltip">
            {companiesTooltip.text}
            {companiesTooltip.ellipsis && (
              <span
                className="material-icons-round icon"
                data-for={`tooltip-companies-${data.id}`}
                data-tip={companiesTooltip.fullText.replace(/,/g, ",<br>")}
                data-iscapture="true"
              >
                info
                <CustomReactTooltip
                  id={`tooltip-companies-${data.id}`}
                  multiline={true}
                />
              </span>
            )}
          </span>
        ),
        created_by: `${
          data.created_by_user && data.created_by_user.id
            ? `${data.created_by_user.first_name} ${data.created_by_user.last_name}`
            : "-"
        }`,
        updated_at: data.updated_at
          ? ComapnyDetailsDateTimeFormatter(data.updated_at, false)
          : ComapnyDetailsDateTimeFormatter(new Date(), false),
      };
    });
    setLandscapes({ rowData: clientData });
  };

  const formatSpecialReportsData = (clientData) => {
    clientData = clientData?.map((data) => {
      let companiesTooltip = CustomEllipsis(
        data?.companies && data?.companies.length > 0
          ? data.companies.map((c) => c.name).join(", ")
          : "-"
      );
      return {
        id: data.id,
        name: (
          <span
            style={{ padding: 0, color: "#1085c6", cursor: "pointer" }}
            onClick={() =>
              goToCompanyDetail(
                "special_report",
                data.is_allowed ? true : false,
                data
              )
            }
          >
            {data.name}
          </span>
        ),
        is_active: data.is_allowed ? (
          data.is_active === 1 ? (
            <span
              onClick={() =>
                goToCompanyDetail(
                  "special_report",
                  data.is_allowed ? true : false,
                  data
                )
              }
              style={{ marginRight: "25px" }}
              className="material-icons-round icon allowed-access text-green"
              aria-hidden="true"
              data-for={`tooltip-${data.id}-active`}
              data-tip={"Active"}
              data-iscapture="true"
            >
              circle
              <CustomReactTooltip
                id={`tooltip-${data.id}-active`}
                multiline={true}
              />
            </span>
          ) : (
            <span
              style={{ marginRight: "25px" }}
              className="material-icons-round icon text-orange allowed-access allowed-access-warning"
              aria-hidden="true"
              data-for={`tooltip-${data.id}-inactive`}
              data-tip={"Inactive"}
              data-iscapture="true"
            >
              circle
              <CustomReactTooltip
                id={`tooltip-${data.id}-inactive`}
                multiline={true}
              />
            </span>
          )
        ) : (
          <>
            <span
              style={{ marginRight: "25px" }}
              className="material-icons-round icon not-allowed-access text-grey"
              aria-hidden="true"
              data-for={`tooltip-${data.id}-noaccess`}
              data-tip={"No Access"}
              data-iscapture="true"
            >
              circle
              <CustomReactTooltip
                id={`tooltip-${data.id}-noaccess`}
                multiline={true}
              />
            </span>
          </>
        ),
        segment_id: `${
          data.segment && data.segment.id ? data.segment.name : "-"
        }`,
        companies: (
          <span className="datagrid_tooltip">
            {companiesTooltip.text}
            {companiesTooltip.ellipsis && (
              <span
                className="material-icons-round icon"
                data-for={`tooltip-companies-${data.id}`}
                data-tip={companiesTooltip.fullText.replace(/,/g, ",<br>")}
                data-iscapture="true"
              >
                info
                <CustomReactTooltip
                  id={`tooltip-companies-${data.id}`}
                  multiline={true}
                />
              </span>
            )}
          </span>
        ),
        created_by: `${
          data.created_by_user && data.created_by_user.id
            ? `${data.created_by_user.first_name} ${data.created_by_user.last_name}`
            : "-"
        }`,
        updated_at: data.updated_at
          ? ComapnyDetailsDateTimeFormatter(data.updated_at, false)
          : ComapnyDetailsDateTimeFormatter(new Date(), false),
      };
    });
    setSpecialReports({ rowData: clientData });
  };

  const formatEcosystemData = (clientData) => {
    clientData = clientData?.map((data) => {
      let companiesTooltip = CustomEllipsis(
        data?.companies && data?.companies.length > 0
          ? data.companies.map((c) => c.name).join(", ")
          : "-"
      );
      return {
        id: data.id,
        name: (
          <span
            style={{ padding: 0, color: "#046CD9", cursor: "pointer" }}
            onClick={() =>
              goToCompanyDetail(
                "ecosystem",
                data.is_allowed ? true : false,
                data
              )
            }
          >
            {data.name}
          </span>
        ),
        is_active: data.is_allowed ? (
          data.is_active === true ? (
            <span
              onClick={() =>
                goToCompanyDetail(
                  "ecosystem",
                  data.is_allowed ? true : false,
                  data
                )
              }
              style={{ marginRight: "25px" }}
              className="material-icons-round icon allowed-access text-green"
              aria-hidden="true"
              data-for={`tooltip-${data.id}-active`}
              data-tip={"Active"}
              data-iscapture="true"
            >
              circle
              <CustomReactTooltip
                id={`tooltip-${data.id}-active`}
                multiline={true}
              />
            </span>
          ) : (
            <span
              style={{ marginRight: "25px" }}
              className="material-icons-round icon text-orange allowed-access allowed-access-warning"
              aria-hidden="true"
              data-for={`tooltip-${data.id}-inactive`}
              data-tip={"Inactive"}
              data-iscapture="true"
            >
              circle
              <CustomReactTooltip
                id={`tooltip-${data.id}-inactive`}
                multiline={true}
              />
            </span>
          )
        ) : (
          <span
            style={{ marginRight: "25px" }}
            className="material-icons-round icon not-allowed-access text-grey"
            aria-hidden="true"
            data-for={`tooltip-${data.id}-noaccess`}
            data-tip={"No Access"}
            data-iscapture="true"
          >
            circle
            <CustomReactTooltip
              id={`tooltip-${data.id}-noaccess`}
              multiline={true}
            />
          </span>
        ),
        segment_id: `${
          data.segment && data.segment.id ? data.segment.name : "-"
        }`,
        companies: (
          <span className="datagrid_tooltip">
            {companiesTooltip.text}
            {companiesTooltip.ellipsis && (
              <span
                className="material-icons-round icon"
                data-for={`tooltip-companies-${data.id}`}
                data-tip={companiesTooltip.fullText.replace(/,/g, ",<br>")}
                data-iscapture="true"
              >
                info
                <CustomReactTooltip
                  id={`tooltip-companies-${data.id}`}
                  multiline={true}
                />
              </span>
            )}
          </span>
        ),
        created_by: `${
          data.created_by_user && data.created_by_user.id
            ? `${data.created_by_user.first_name} ${data.created_by_user.last_name}`
            : "-"
        }`,
        updated_at: data.updated_at
          ? ComapnyDetailsDateTimeFormatter(data.updated_at, false)
          : ComapnyDetailsDateTimeFormatter(new Date(), false),
      };
    });
    setEcosystems({ rowData: clientData });
  };

  // method to fetch the board data
  const getIndividualBoardData = (param) => {
    let url = `/boards?is_dashboard=1&board_type=${param}&filter_days=${
      checked ? 7 : 14
    }`;
    const updatedBoardStatus = boardIsOpen.map((item) => ({
      ...item,
      isOpen: item.type === param,
    }));
    setBoardIsOpen(updatedBoardStatus);
    BoardServices.getBoards(url)
      .then((res) => {
        let data = res.data.data;
        if (param === "all") {
          setAllBoardData(data);
        } else if (param === "my") {
          setMyBoardData(data);
        } else if (param === "tbri") {
          setTBRIBoardData(data);
        } else if (param === "shared") {
          setSharedBoardData(data);
        } else {
          setEnterpriseBoardData(data);
        }
      })
      .catch((error) => {
        if (param === "all") {
          setAllBoardData([]);
        } else if (param === "my") {
          setMyBoardData([]);
        } else if (param === "tbri") {
          setTBRIBoardData([]);
        } else if (param === "shared") {
          setSharedBoardData([]);
        } else {
          setEnterpriseBoardData([]);
        }
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  // method to fetch the visualization data
  const getIndividualVisulizationData = (param) => {
    let url = `/visualization_chart/getAll?template_type=${param}&is_dashboard=1`;
    const updateChartStatus = chartIsOpen.map((item) => ({
      ...item,
      isOpen: item.type === param,
    }));
    setChartIsOpen(updateChartStatus);
    VisualizationServices.getTemplates(url)
      .then((res) => {
        let data = res.data.rows;
        if (param === "all") {
          setAllVisualizations(data);
        } else if (param === "") {
          setMyVisualizations(data);
        } else if (param === "enterprise") {
          setEnterPriseVisualizations(data);
        } else if (param === "tbri") {
          setTBRIVisualizations(data);
        } else {
          if (data && data.length)
            setSharedVisualizations(data.map((d) => d.chart));
        }
      })
      .catch((error) => {
        if (param === "all") {
          setAllVisualizations([]);
        } else if (param === "") {
          setMyVisualizations([]);
        } else if (param === "shared") {
          setSharedVisualizations([]);
        } else if (param === "tbri") {
          setTBRIVisualizations([]);
        } else {
          setSharedVisualizations([]);
        }
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  // This is for GenAI Carousal
  // const responsiveforGenAI = {
  //   desktop: {
  //     breakpoint: { max: 3000, min: 1024 },
  //     items: 2, // Display 4 items in total
  //   },
  //   tablet: {
  //     breakpoint: { max: 1024, min: 464 },
  //     items: 2,
  //     slidesToSlide: 1, // optional, default to 1.
  //   },
  //   mobile: {
  //     breakpoint: { max: 464, min: 0 },
  //     items: 1,
  //     slidesToSlide: 1, // optional, default to 1.
  //   },
  // };

  // select the table tab which we have to display
  const setSelectedTable = (resp) => {
    setSelectedTableTab(resp);
  };
  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const {
      carouselState: { currentSlide, slidesToShow, totalItems },
      total,
    } = rest;
    return (
      <div className="carousel-button-group">
        <span
          className={
            currentSlide === 0
              ? "disable material-icons-round mr-2 icon-fs-18 arrow-icon"
              : "material-icons-round mr-2 icon-fs-18 arrow-icon"
          }
          onClick={() => previous()}
        >
          arrow_back
        </span>
        <span
          className={
            currentSlide >= totalItems - slidesToShow
              ? "disable material-icons-round mr-2 icon-fs-18 arrow-icon"
              : "material-icons-round mr-2 icon-fs-18 arrow-icon"
          }
          onClick={() => next()}
        >
          arrow_forward
        </span>
      </div>
    );
  };

  const getCoverageType = (type) => {
    switch (type) {
      case "vendor_reports":
      case "dedicated_analyst":
        return "Company Research";
      case "vendor_profiles":
      case "analyst_team":
        return "Company Profile";
      case "vendor_snapshot":
      case "research_team":
        return "Company Snapshots";
      case "benchmark_coverage":
        return "Benchmark Coverage";
      default:
        return "-";
    }
  };

  // get the special report companies list
  const getCompaniesList = (companies) => {
    let companiesList = "";
    companies.forEach((item) => {
      companiesList += item + ", ";
    });
    let lastIndex = companiesList.lastIndexOf(",");
    setSRCompaniesList(companiesList.slice(0, lastIndex));
  };

  const getWhatsNewApiData = (report_name) => {
    DashboardServices.getWhatsNewData(report_name, checked ? 7 : 14)
      .then((res) => {
        let result = res.data.reports;
        if (report_name !== "boards" && report_name !== "visualization") {
          if (report_name === "company") {
            let companyData = res.data;
            formatWhatsNewTable(companyData, report_name);
          } else {
            formatWhatsNewTable(result, report_name);
          }
        } else {
          formatWhatsNewTable(result, activeWhatsNewTab);
        }
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  const formatWhatsNewTable = (result, type) => {
    const updateData = result.map((data) => {
      let RepoName = CustomEllipsis(data.name);
      data.name = (
        <span className="datagrid_tooltip">
          {RepoName.text}
          {RepoName.ellipsis && (
            <span
              className="material-icons-round icon"
              data-for={`tooltip-companies-${data.id}`}
              data-tip={RepoName.fullText}
              data-iscapture="true"
            >
              info
              <CustomReactTooltip
                id={`tooltip-companies-${data.id}`}
                multiline={true}
              />
            </span>
          )}
        </span>
      );
      return data;
    });
    if (type === "boards") {
      result = result.map((data) => {
        return {
          id: data.id,
          board_name: (
            <div
              className="table-report-title"
              onClick={() => goToReportViewedDetailPage("boards", data)}
            >
              {data.name}
            </div>
          ),
          pins: data.total_pinned_content_count,
          created_on: data.created_at
            ? ComapnyDetailsDateTimeFormatter(data.created_at, false)
            : "-",
          created_by: `${data.owner.first_name} ${data.owner.last_name}`,
          updated_at: ComapnyDetailsDateTimeFormatter(data.updated_at, false),
        };
      });
    } else if (type === "visualization") {
      result = result.map((data) => {
        return {
          id: data.id,
          visualization_name: (
            <div
              className="table-report-title"
              onClick={() => goToReportViewedDetailPage("visualization", data)}
            >
              {data.name}
            </div>
          ),
          chart_type: data.chart_type,
          created_on: data.created_at
            ? ComapnyDetailsDateTimeFormatter(data.created_at, false)
            : "-",
          created_by: `${data.created_by_user.first_name} ${data.created_by_user.last_name}`,
          updated_at: data.updated_at
            ? ComapnyDetailsDateTimeFormatter(data.updated_at, false)
            : ComapnyDetailsDateTimeFormatter(new Date(), false),
        };
      });
    } else if (type === "projects") {
      result = result.map((data) => {
        return {
          id: data.id,
          report_name: (
            <div
              className="table-report-title"
              onClick={() => goToReportViewedDetailPage(data.report_type, data)}
            >
              {data.name}
            </div>
          ),
          project_manager: `${data.project_manager.first_name} ${data.project_manager.last_name}`,
          client: data.client.name,
          updated_at: data.updated_at
            ? ComapnyDetailsDateTimeFormatter(data.updated_at, false)
            : ComapnyDetailsDateTimeFormatter(new Date(), false),
        };
      });
    } else if (
      type === "market_forecast" ||
      type === "market_landscape" ||
      type === "special_report" ||
      type === "benchmark" ||
      type === "customer_study" ||
      type === "ecosystem"
    ) {
      result = result.map((data) => {
        let companiesTooltip = CustomEllipsis(
          data?.companies && data?.companies.length > 0
            ? data.companies.map((c) => c.name).join(", ")
            : "-"
        );
        return {
          id: data.id,
          report_name: data.is_allowed ? (
            <div
              className="table-report-title"
              onClick={() => goToReportViewedDetailPage(data.report_type, data)}
            >
              {data.name}
            </div>
          ) : (
            <div
              className="report-title_details"
              onClick={() => setDailogModal(true)}
            >
              {data.name}
              <span className="material-icons-outlined icon-fs-14"> lock</span>
            </div>
          ),
          segment: data.segment.name,
          company_list: (
            <span className="datagrid_tooltip d-flex align-items-center">
              {companiesTooltip.text}
              {companiesTooltip.ellipsis && (
                <span
                  className="material-icons-round icon"
                  data-for={`tooltip-companies-${data.id}`}
                  data-tip={companiesTooltip.fullText.replace(/,/g, ",<br>")}
                  data-iscapture="true"
                >
                  info
                  <CustomReactTooltip
                    id={`tooltip-companies-${data.id}`}
                    multiline={true}
                  />
                </span>
              )}
            </span>
          ),
          updated_at: data.updated_at
            ? ComapnyDetailsDateTimeFormatter(data.updated_at, false)
            : ComapnyDetailsDateTimeFormatter(new Date(), false),
        };
      });
    } else if (type === "all") {
      const updatedResult = result.filter((item) => {
        if (item.report_type !== "projects") return item;
      });
      result = updatedResult.map((data) => {
        let companiesTooltip = CustomEllipsis(
          data?.companies && data?.companies.length > 0
            ? data.companies.map((c) => c.name).join(", ")
            : "-"
        );
        let coverageType =
          data.coverage_type === "analyst_team"
            ? "Vendor Profiles"
            : data.coverage_type === "dedicated_analyst"
            ? "Vendor Reports"
            : data.coverage_type === "research_team"
            ? "Vendor Snapshots"
            : "Benchmark Coverage";
        return {
          id: data.id ? data.id : data.company_id,
          type: data.report_type,
          report_type: (
            <div
              className="report-title_details table-report-title"
              onClick={() =>
                data.is_allowed || data.allowed_access
                  ? goToReportViewedDetailPage(data.report_type, data)
                  : setDailogModal(true)
              }
            >
              {reportTypes.find((r) => r.slug === data.report_type)?.name ??
                "-"}
            </div>
          ),
          report_name:
            data.report_type === "company" ? data.company_name : data.name,
          segment:
            data.report_type === "company"
              ? data.segment_name
              : data.segment.name,
          company_list:
            data.report_type === "company" ? (
              "-"
            ) : (
              <span className="datagrid_tooltip d-flex align-items-center">
                {companiesTooltip.text}
                {companiesTooltip.ellipsis && (
                  <span
                    className="material-icons-round icon"
                    data-for={`tooltip-companies-${data.id}`}
                    data-tip={companiesTooltip.fullText.replace(/,/g, ",<br>")}
                    data-iscapture="true"
                  >
                    info
                    <CustomReactTooltip
                      id={`tooltip-companies-${data.id}`}
                      multiline={true}
                    />
                  </span>
                )}
              </span>
            ),
          updated_at: data.updated_at
            ? ComapnyDetailsDateTimeFormatter(data.updated_at, false)
            : ComapnyDetailsDateTimeFormatter(new Date(), false),
        };
      });
    } else if (type === "company") {
      result = result.map((data) => {
        return {
          id: data.company_id,
          report_name: data.allowed_access ? (
            <div
              className="table-report-title"
              onClick={() => goToReportViewedDetailPage(data.report_type, data)}
            >
              {data.company_name}
            </div>
          ) : (
            <div
              className="report-title_details"
              onClick={() => setDailogModal(true)}
            >
              {data.company_name}
              <span className="material-icons-outlined icon-fs-14"> lock</span>
            </div>
          ),
          company_list: "-",
          segment: data.segment_name,
          report_type:
            data.coverage_type === "analyst_team"
              ? "Company Profiles"
              : data.coverage_type === "dedicated_analyst"
              ? "Company Research"
              : data.coverage_type === "research_team"
              ? "Company Snapshots"
              : "Benchmark Coverage",
          updated_at: data.updated_at
            ? ComapnyDetailsDateTimeFormatter(data.updated_at, false)
            : ComapnyDetailsDateTimeFormatter(new Date(), false),
        };
      });
    }
    setWhatsNewData(result);
  };

  const formatWhatsNewData = (results, report_name, isRecentlyView) => {
    const data = [];
    if (results) {
      if (report_name === "projects" && results.length > 0) {
        results.map((result) =>
          data.push({
            id: result?.id,
            report_name: result?.name,
            client_name: result.client ? result.client?.name : "N/A",
            project_manager: result?.project_manager
              ? `${result?.project_manager?.first_name} ${result?.project_manager?.last_name}`
              : "N/A",
            updated_at: result?.updated_at,
            last_seen_at: result?.last_seen_at,
            owner: result?.created_by_user
              ? `${result?.created_by_user?.first_name} ${result?.created_by_user?.last_name}`
              : "N/A",
            created_at: result?.created_at,
          })
        );
      } else if (
        (report_name === "boards" || report_name === "visualization") &&
        results.length > 0
      ) {
        results.map((result) =>
          data.push({
            id: result?.id,
            report_name: result?.name,
            client_name: "-",
            pinned: result?.total_pinned_content_count || 0,
            chart_type: result?.chart_type,
            last_seen_at: result?.last_seen_at,
            updated_at: result?.updated_at,
            owner: result?.owner
              ? `${result.owner?.first_name} ${result.owner?.last_name}`
              : result?.created_by_user
              ? `${result.created_by_user?.first_name} ${result.created_by_user?.last_name}`
              : "N/A",
            created_at: result?.created_at,
          })
        );
      } else if (report_name === "company" && isRecentlyView) {
        results.map((result) =>
          data.push({
            company_id: result?.company_id,
            report_name: result?.company?.original_name,
            coverage_type: result?.coverage_type,
            segment_id: result?.segment_id,
            segment_name: result?.segment?.name || "-",
            last_seen_at: result?.last_seen_at,
            owner: result?.company?.created_by_user
              ? `${result.company?.created_by_user?.first_name} ${result.company?.created_by_user?.last_name}`
              : "N/A",
            created_at: result?.company?.created_at,
          })
        );
      } else if (report_name === "company") {
        const newValue = results[activeWhatsNewSubTab];
        if (newValue && newValue.length > 0) {
          newValue.map((result) =>
            data.push({
              company_id: result?.company_id,
              company_name: result?.company_name,
              segment_id: result?.segment_id,
              segment_name: result?.segment_name || "-",
              updated_at: result?.updated_at,
              last_seen_at: result?.last_seen_at,
              owner: result?.created_by_name ? result?.created_by_name : "N/A",
              created_at: result?.created_at,
            })
          );
        }
      } else {
        if (results.length > 0) {
          results.map((result) =>
            data.push({
              id: result?.id,
              report_name: result?.name,
              segment_name: result?.segment?.name || "-",
              company: result?.companies?.map((v) => v.original_name) || [],
              updated_at: result?.updated_at,
              last_seen_at: result?.last_seen_at,
              owner: result?.created_by_user
                ? `${result?.created_by_user?.first_name} ${result?.created_by_user?.last_name}`
                : "N/A",
              created_at: result?.created_at,
            })
          );
        }
      }
    }
    const sortedDataByLastSeen = orderBy(data, "last_seen_at", "desc");
    if (isRecentlyView) setRecentlyViewData(sortedDataByLastSeen);
    else setWhatsNewData(data);
  };

  const topCardIsHovered = (id) => {
    const updatedBoard = topBoardCard.map((board) => {
      if (board.id === id) {
        board.isHovered = true;
        return board;
      } else return board;
    });
    setTopBoardCard(updatedBoard);
  };

  const topCardIsUnHovered = (id) => {
    const updatedBoard = topBoardCard.map((board) => {
      if (board.id === id) {
        board.isHovered = false;
        return board;
      } else return board;
    });
    setTopBoardCard(updatedBoard);
  };

  const getRecentlyViewApiData = (report_name) => {
    DashboardServices.getRecentlyViewData(report_name)
      .then((res) => {
        let result = res.data.reports;
        formatWhatsNewData(result, report_name, true);
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  const selectReportType = (reportId) => {
    const newReportTypes = reportTypes.map((report) => {
      report.is_checked = report.id === reportId;
      return report;
    });
    setReportTypes(newReportTypes);
  };

  let tbrReportDataIsEmpty =
    totalVendorProfileData === 0 &&
    totalVendorReportData === 0 &&
    totalVendorSnapshotData === 0 &&
    totalBenchmarkCoverageData === 0 &&
    totalMarketForecastData === 0 &&
    totalMarketLandscapeData === 0 &&
    totalBenchmarkData === 0 &&
    totalSpecialReportData === 0 &&
    totalCustomerStudyData === 0 &&
    totalEcosystemData === 0;

  const calculateProgressWidth = (view, total) => {
    const calculated = (view / total) * 100 + "%";
    return calculated;
  };

  const getCalendarEvents = () => {
    CalendarServices.getUpcomingEvent()
      .then((res) => {
        let dateIndex = {};
        const data = res.data.map((item) => {
          const dateTimeString = item?.published_date;
          const timezoneOffsetEvent = new Date().getTimezoneOffset();
          let eventStartTime = new Date(dateTimeString);

          eventStartTime.setMinutes(
            eventStartTime.getMinutes() + timezoneOffsetEvent + 60
          );

          let eventStartDateUpcoming = eventStartTime.getDate();
          if (
            dateIndex[eventStartDateUpcoming] ||
            dateIndex[eventStartDateUpcoming] >= 0
          ) {
            dateIndex[eventStartDateUpcoming] =
              dateIndex[eventStartDateUpcoming] + 1;
          } else {
            dateIndex[eventStartDateUpcoming] = 0;
          }
          eventStartTime.setHours(dateIndex[eventStartDateUpcoming]);
          item["published_date"] = eventStartTime;
          return item;
        });
        setUpcomingEvents(data);
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  return (
    <aside className="main_content">
      <div className="ml-3 mr-3 mb-3 br_8">
        {/* HEADER SECTION */}
        <div className="dashboard_header_section">
          <img src={backgroud_image} className="dashboard_img" alt="" />
          <div className="heading-section">
            <h1
              className="header"
              data-for="headerName"
              data-tip={props.home.displayName}
            >
              Hello {props.home.displayName}!
            </h1>
            <CustomReactTooltip id="headerName" />
            <div
              className={
                searchResult?.length > 0 && searchInput?.length >= 3
                  ? `search-bar-without-border position-relative`
                  : `search-bar position-relative`
              }
            >
              <input
                ref={inputRef}
                className="search-bar-input"
                value={searchInput}
                onChange={(e) => handleSearchInput(e)}
                onKeyDown={(e) => handleKeywordShortcut(e)}
                type="text"
                id="searchInput"
                placeholder="Search"
                autoComplete={"off"}
              />
              {searchInput?.length >= 3 && (
                <div>
                  <span
                    onClick={() => setSearchInput("")}
                    class="material-icons-outlined dashboard-close-icon"
                  >
                    close
                  </span>
                </div>
              )}

              <div className="line"></div>
              <div onClick={() => searchOnGlobal()} className="icon-div">
                <span className="icon-fs-18 icon-color material-icons-outlined">
                  search
                </span>
              </div>
            </div>
            {searchInput?.length >= 3 && searchResult?.length > 0 && (
              <div className="search-bar-option">
                <ul>
                  {searchResult.map((ele, index) => (
                    <li
                      onClick={() => navigateToGlobalSearchBar(ele)}
                      className={
                        index === currentSearchSuggestion - 1
                          ? "selected-search-element search-element"
                          : "search-element"
                      }
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ele,
                        }}
                      />
                      <span class="material-icons-outlined icon-color icon-fs-18">
                        search
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>

        {/* TOTAL COMPANIES,BENCHMARK SECTION */}
        {/* <div className="d-flex flex-wrap justify-content-between mr-3 ml-3 dashboard-total-section">
          want to remove the padding after adding 6th item
          {topBoardCard.map((board) => (
            <div className="mb-4">
              <div
                className="card dashboard-card"
                onMouseEnter={() => topCardIsHovered(board.id)}
                onMouseLeave={() => topCardIsUnHovered(board.id)}
                onClick={() => redirectToBigTable(board.type)}
              >
                <div className="name-tiles">{board.title}</div>
                <div
                  style={{
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  className="d-flex"
                >
                  <div class="radialProgressBar progress-90 mt-1">
                    <div class="overlay">
                      <span className="material-icons-outlined overlay-icon">
                        {board.icon}
                      </span>
                      <div className="top-card-value">
                        {board.type === "company"
                          ? total_counts.company_total_count
                          : board.type === "benchmark"
                          ? total_counts.benchmarks_total_count
                          : board.type === "forecast"
                          ? total_counts.forecasts_total_count
                          : board.type === "special_reports"
                          ? total_counts.special_report_total_count
                          : board.type === "customer_studies"
                          ? total_counts.customer_study_total_count
                          : total_counts.landscapes_total_count}
                      </div>
                    </div>
                  </div>
                  <div className="company-name-section">
                    <div className="board-arrow">
                      {board.isHovered && (
                        <span className="material-icons-outlined icon-fs-16">
                          arrow_forward
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div> */}

        {/* New Hero card design with linearProgressbar */}
        <div className="dashboard-herocard-section">
          {topBoardCard.map((board) => (
            <div className="dashboard-card-container">
              <div
                className="dashboard-card"
                onMouseEnter={() => topCardIsHovered(board.id)}
                onMouseLeave={() => topCardIsUnHovered(board.id)}
                onClick={() => redirectToBigTable(board.type)}
              >
                <span className="material-icons-outlined card-top-icon">
                  {board.icon}
                </span>
                <div className="name-tiles">{board.title}</div>
                <div class="linearProgressBar">
                  <div
                    className="currentProgress"
                    style={{
                      width: calculateProgressWidth(
                        board.view_count,
                        board.total_counts
                      ),
                    }}
                  />
                </div>
                <div className="current-total">
                  {board.view_count}/{board.total_counts}
                </div>
                <div className="board-arrow">
                  {board.isHovered && (
                    <span className="material-icons-outlined icon-fs-16">
                      arrow_forward
                    </span>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
        {/* End of new hero design */}

        {/* DASHBOARD MIDDLE SECTION */}
        <div className="dashboard-middle-section">
          {/* DASHBOARD LEFT SIDE SECTION */}
          <div className="dashboard-left">
            {/* GenAI Reports */}
            {/* Commenting GenAI reports portion */}
            {/* <div className='mt-4  d-flex align-items-center flex-wrap title-section breadcrumb-mb-0 dashboard-content'>
              <div className="GenAIHeader">
                <div className="header-content">
                  <span className="mr-3">
                    <img src={GenAi_icon} alt="GENAI Logo"style={{height:"28px"}} />
                  </span>
                  <span className="header-title">GenAI Reports</span>
                </div>
              </div>
              <div
                className='mt-3 mb-4 pr-3 pl-3 carousel-main-container'
                style={{ width: "100%" }}
              >
                {recentlyViewData && recentlyViewData.length > 0 ? (
                  <Carousel
                    arrows={false}
                    customButtonGroup={<ButtonGroup />}
                    renderButtonGroupOutside={true}
                    partialVisbile={false}
                    swipeable={true}
                    draggable={false}
                    showDots={false}
                    responsive={responsiveforGenAI}
                    keyBoardControl={true}
                    transitionDuration={500}
                    containerClass='carousel-container'
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                    dotListClass='custom-dot-list-style'
                    itemClass='carousel-item-padding-40-px'
                  >
                    {recentlyViewData.map((data) => (
                      <div
                        className='GenAI-report-card'
                        key={data.id}
                        onClick={() =>
                          goToReportViewedDetailPage(
                            activeRecentlyViewTab,
                            data
                          )
                        }
                      >
                        <div className='d-flex justify-content-between'>
                          <h3
                            data-text={data?.report_name}
                            className='report-heading css-tooltip'
                          >
                            {`${
                              data?.report_name?.length <= 25
                                ? `${data?.report_name}`
                                : `${data?.report_name?.substring(0, 25)}...`
                            }`}
                          </h3>
                        </div>
                        <div
                          className='report-text-container d-flex align-items-center pb-3'
                          style={{
                            fontSize: "14px",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <span
                              style={{
                                fontWeight: "600",
                              }}
                              className='mr-1'
                            >
                              Report Type:
                            </span>
                            <span
                              data-text={data?.owner}
                              className='css-tooltip'
                            >
                            {data?.owner}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Carousel>
                ) : (
                  <div className={"col-sm-12 text-center no-boards"}>
                    <img src={NoRecordFoundImage} alt='img' />
                    <h5 className='not-found-text'>No record found</h5>
                  </div>
                )}
              </div>
            </div> */}

            {/* WHATS NEW SECTION */}
            <div className="mt-3 pt-3 pr-3 pl-3 d-flex align-items-center flex-wrap title-section breadcrumb-mb-0 dashboard-content">
              <div className="dashboard-content-header">
                <h1 className="mb-0 dashboard-title">What's New</h1>
                <div className="d-flex align-items-center">
                  <h3 className="status-para">7 Days</h3>

                  <label className="toggle-switch">
                    <input
                      type="checkbox"
                      onClick={() => setChecked(!checked)}
                    />
                    <span className="status-slider"></span>
                  </label>

                  <h3 className="status-para">14 Days</h3>
                </div>
              </div>

              <div
                className="segments-card-wrapper btn-items-card-wrapper bg-transparent m-0"
                style={{ width: "100%" }}
              >
                <div className="ml-2 mt-2 custom-card segments-card btn-items-card open-card">
                  <ul className="btn-items d-flex flex-wrap">
                    <li>
                      <Dropdown
                        position="right"
                        title={
                          <div className="dropdown-button">
                            {activeWhatsNewTab == "all"
                              ? "TBR Reports"
                              : activeWhatsNewTab == "company"
                              ? "Company Research"
                              : activeWhatsNewTab == "benchmark"
                              ? "Benchmarks"
                              : activeWhatsNewTab == "market_forecast"
                              ? "Market Forecasts"
                              : activeWhatsNewTab == "market_landscape"
                              ? "Market Landscapes"
                              : activeWhatsNewTab == "special_report"
                              ? "Special Reports"
                              : activeWhatsNewTab == "customer_study"
                              ? "Customer Studies"
                              : activeWhatsNewTab == "ecosystem"
                              ? "Ecosystems"
                              : "TBR Reports"}{" "}
                            <span className="material-icons icon ml-2 text-black icon-fs-20">
                              expand_more
                            </span>
                          </div>
                        }
                        className={
                          activeWhatsNewTab == "benchmark" ||
                          activeWhatsNewTab == "company" ||
                          activeWhatsNewTab == "market_forecast" ||
                          activeWhatsNewTab == "market_landscape" ||
                          activeWhatsNewTab == "special_report" ||
                          activeWhatsNewTab == "customer_study" ||
                          activeWhatsNewTab == "ecosystem" ||
                          activeWhatsNewTab == "all"
                            ? "filterReports activeReports"
                            : "filterReports"
                        }
                      >
                        {reportTypes.map((report) => (
                          <Dropdown.Item
                            onClick={() => {
                              setActiveWhatsNewTab(report.slug);
                              selectReportType(report.id);
                            }}
                          >
                            <span className="subMenu-company">
                              {report.name}
                            </span>
                          </Dropdown.Item>
                        ))}
                      </Dropdown>
                    </li>
                    <li
                      onClick={() => setActiveWhatsNewTab("boards")}
                      className={`${
                        activeWhatsNewTab == "boards" ? "active" : ""
                      }`}
                    >
                      <div className="content font-weight-semibold d-flex align-items-center pl-5 pr-5">
                        <span>Boards</span>
                      </div>
                    </li>
                    <li
                      onClick={() => setActiveWhatsNewTab("visualization")}
                      className={`${
                        activeWhatsNewTab == "visualization" ? "active " : ""
                      }`}
                    >
                      <div className="content font-weight-semibold d-flex align-items-center pl-5 pr-5">
                        <span>Visualization</span>
                      </div>
                    </li>
                    <li
                      onClick={() => setActiveWhatsNewTab("projects")}
                      className={`${
                        activeWhatsNewTab == "projects" ? "active " : ""
                      }`}
                    >
                      <div className="content font-weight-semibold d-flex align-items-center pl-5 pr-5">
                        <span>Projects</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div style={{ width: "100%" }} className="ml-2 mt-2">
                <div className="mt-1 pb-3 custom-table-block first-col-fixed-block companies-list-table-block nowrap-table-block th-nowrap sort-table-block center-th-align dashboard-company-content1">
                  {activeWhatsNewTab === "boards" ? (
                    <DynamicTableComponent
                      columnData={DashboardTables.boardTable}
                      rowData={whatsNewData}
                      showPagination={false}
                    />
                  ) : activeWhatsNewTab === "visualization" ? (
                    <DynamicTableComponent
                      columnData={DashboardTables.visualizationTable}
                      rowData={whatsNewData}
                      showPagination={false}
                    />
                  ) : activeWhatsNewTab === "projects" ? (
                    <DynamicTableComponent
                      columnData={DashboardTables.projectTable}
                      rowData={whatsNewData}
                      showPagination={false}
                    />
                  ) : (
                    reportTypes.map(
                      (report) =>
                        report.is_checked && (
                          <DynamicTableComponent
                            columnData={report.table}
                            rowData={whatsNewData}
                            showPagination={false}
                            tableHeight="table-height"
                          />
                        )
                    )
                  )}
                </div>
              </div>
            </div>

            {/* RECENTLY VIEWED SECTION */}
            <div className="mt-4 pt-3 pr-3 pl-3 d-flex align-items-center flex-wrap title-section breadcrumb-mb-0 dashboard-content">
              <div className="dashboard-content-header">
                <h1 className="mb-0 dashboard-title">Recently Viewed</h1>
              </div>

              <div
                className="segments-card-wrapper btn-items-card-wrapper bg-transparent m-0"
                style={{ width: "100%" }}
              >
                <div className="ml-2 mt-2 custom-card segments-card btn-items-card open-card">
                  <ul className="btn-items d-flex flex-wrap">
                    <li>
                      <Dropdown
                        position="right"
                        title={
                          <div className="dropdown-button">
                            {activeRecentlyViewTab == "company"
                              ? "Company Research"
                              : activeRecentlyViewTab == "benchmark"
                              ? "Benchmark"
                              : activeRecentlyViewTab == "market_forecast"
                              ? "Market Forecasts"
                              : activeRecentlyViewTab == "market_landscape"
                              ? "Market Landscapes"
                              : activeRecentlyViewTab == "special_report"
                              ? "Special Reports"
                              : activeRecentlyViewTab == "customer_study"
                              ? "Customer Studies"
                              : activeRecentlyViewTab == "ecosystem"
                              ? "Ecosystems"
                              : "TBR Reports"}
                            <span className="material-icons icon ml-2 text-black icon-fs-20">
                              expand_more
                            </span>
                          </div>
                        }
                        className={
                          activeRecentlyViewTab == "benchmark" ||
                          activeRecentlyViewTab == "company" ||
                          activeRecentlyViewTab == "market_forecast" ||
                          activeRecentlyViewTab == "market_landscape" ||
                          activeRecentlyViewTab == "special_report" ||
                          activeRecentlyViewTab == "customer_study" ||
                          activeRecentlyViewTab == "ecosystem"
                            ? "filterReports activeReports"
                            : "filterReports"
                        }
                      >
                        {selectionTab.map((report) => (
                          <Dropdown.Item
                            onClick={() =>
                              setActiveRecentlyViewTab(report.value)
                            }
                          >
                            <span className="subMenu-company">
                              {report.label}
                            </span>
                          </Dropdown.Item>
                        ))}
                      </Dropdown>
                    </li>

                    <li
                      onClick={() => setActiveRecentlyViewTab("boards")}
                      className={`${
                        activeRecentlyViewTab == "boards" ? "active" : ""
                      }`}
                    >
                      <div className="content font-weight-semibold d-flex align-items-center pl-5 pr-5">
                        <span>Boards</span>
                      </div>
                    </li>
                    <li
                      onClick={() => setActiveRecentlyViewTab("visualization")}
                      className={`${
                        activeRecentlyViewTab == "visualization"
                          ? "active "
                          : ""
                      }`}
                    >
                      <div className="content font-weight-semibold d-flex align-items-center pl-5 pr-5">
                        <span>Visualization</span>
                      </div>
                    </li>
                    <li
                      onClick={() => setActiveRecentlyViewTab("projects")}
                      className={`${
                        activeRecentlyViewTab == "projects" ? "active" : ""
                      }`}
                    >
                      <div className="content font-weight-semibold d-flex align-items-center pl-5 pr-5">
                        <span>Projects</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div
                className="mt-3 mb-4 carousel-main-container"
                style={{ width: "100%" }}
              >
                {recentlyViewData && recentlyViewData.length > 0 ? (
                  <Carousel
                    arrows={false}
                    customButtonGroup={<ButtonGroup />}
                    renderButtonGroupOutside={true}
                    partialVisbile={false}
                    swipeable={true}
                    draggable={false}
                    showDots={false}
                    responsive={responsive}
                    keyBoardControl={true}
                    transitionDuration={500}
                    containerClass="carousel-container"
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                  >
                    {recentlyViewData.map((data) => (
                      <div
                        className="dashboard-report-card"
                        key={data.id}
                        onClick={() =>
                          goToReportViewedDetailPage(
                            activeRecentlyViewTab,
                            data
                          )
                        }
                      >
                        <div className="d-flex justify-content-between">
                          <h3
                            data-text={data?.report_name}
                            className="report-heading css-tooltip"
                          >
                            {`${
                              data?.report_name?.length <= 25
                                ? `${data?.report_name}`
                                : `${data?.report_name?.substring(0, 25)}...`
                            }`}
                          </h3>
                          <div style={{ color: "black", fontSize: "14px" }}>
                            {TimeSince(data?.last_seen_at)}
                          </div>
                        </div>
                        <div className="report-text-container d-flex align-items-center pb-3">
                          {activeRecentlyViewTab !== "boards" && (
                            <>
                              <div
                                style={{
                                  fontWeight: "600",
                                }}
                                className="mr-1"
                              >
                                {activeRecentlyViewTab === "visualization"
                                  ? ""
                                  : activeRecentlyViewTab === "projects"
                                  ? "Client: "
                                  : activeRecentlyViewTab === "company"
                                  ? "Type: "
                                  : "Company: "}
                              </div>
                              {activeRecentlyViewTab === "projects" ? (
                                <div
                                  data-text={data?.client_name}
                                  className="css-tooltip"
                                >
                                  {`${
                                    data?.client_name?.length <= 15
                                      ? `${data?.client_name}`
                                      : `${data?.client_name?.substring(
                                          0,
                                          15
                                        )}...`
                                  }`}
                                  &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;
                                </div>
                              ) : (
                                activeRecentlyViewTab !== "visualization" && (
                                  <div
                                    className={`${
                                      data?.company?.length > 1
                                        ? "css-tooltip-2"
                                        : ""
                                    } d-flex align-items-center justify-content-center`}
                                    data-text={SRCompaniesList}
                                    onMouseOver={() => {
                                      if (activeRecentlyViewTab !== "company")
                                        getCompaniesList(data?.company);
                                    }}
                                  >
                                    <span>
                                      {activeRecentlyViewTab === "company"
                                        ? getCoverageType(data.coverage_type)
                                        : data?.company
                                        ? data?.company[0]
                                        : "-"}
                                    </span>
                                    {data?.company?.length > 1 && (
                                      <span className="ml-1 material-icons-round icon-fs-16 icon not-allowed-access text-grey">
                                        info
                                      </span>
                                    )}
                                    &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                                  </div>
                                )
                              )}
                            </>
                          )}
                          <div
                            style={{
                              fontWeight: "600",
                            }}
                            className="mr-1"
                          >
                            {activeRecentlyViewTab === "visualization"
                              ? "Chart type: "
                              : activeRecentlyViewTab === "projects"
                              ? "Project Manager: "
                              : activeRecentlyViewTab === "boards"
                              ? ""
                              : "Segment: "}
                          </div>
                          <div>
                            {activeRecentlyViewTab === "visualization"
                              ? data?.chart_type
                              : activeRecentlyViewTab === "projects"
                              ? `${
                                  data?.project_manager?.length <= 15
                                    ? `${data?.project_manager}`
                                    : `${data?.project_manager?.substring(
                                        0,
                                        15
                                      )}...`
                                }`
                              : activeRecentlyViewTab === "boards"
                              ? ""
                              : data?.segment_name?.length > 25
                              ? `${data?.segment_name.substring(0, 25)}...`
                              : `${data?.segment_name}`}
                          </div>
                        </div>
                        <div className="report-line"></div>
                        <div
                          className="report-text-container d-flex align-items-center pb-3"
                          style={{
                            fontSize: "14px",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <span
                              style={{
                                fontWeight: "600",
                              }}
                              className="mr-1"
                            >
                              Created By:
                            </span>
                            <span
                              data-text={data?.owner}
                              className="css-tooltip"
                            >{`${
                              data?.owner?.length <= 10
                                ? `${data?.owner}`
                                : `${data?.owner?.substring(0, 10)}...`
                            }`}</span>
                          </div>
                          <div>
                            <span
                              style={{
                                fontWeight: "600",
                                marginLeft: "10px",
                              }}
                              className="mr-1"
                            >
                              Created On:
                            </span>
                            <span>
                              {ComapnyDetailsDateFormatter(
                                data?.created_at,
                                false
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Carousel>
                ) : (
                  <div className={"col-sm-12 text-center no-boards"}>
                    <img src={NoRecordFoundImage} alt="img" />
                    <h5 className="not-found-text">No record found</h5>
                  </div>
                )}
              </div>
            </div>

            {/* COMPANIES, BENCHMARK, FORECAST, LANDSCAPE TABLE SECTION */}
            <div className="mt-4 pt-3 pr-3 pl-3 d-flex align-items-center flex-wrap title-section breadcrumb-mb-0 dashboard-content">
              <div className="ml-3 dashboard-content-header">
                <h1 className="mb-0 dashboard-title">TBR Reports</h1>
              </div>
              <div
                className="segments-card-wrapper btn-items-card-wrapper bg-transparent w-100 m-0"
                style={{ width: "100%" }}
              >
                <div className="mt-2 d-flex justify-content-between align-items-center">
                  <div className="col-sm-12 col-md-3 d-flex justify-content-start">
                    <ul className="btn-items">
                      <li>
                        <Dropdown
                          position="right"
                          title={
                            <div className="dropdown-button">
                              {selectedTableTab == "company"
                                ? "Company Research"
                                : selectedTableTab == "benchmark"
                                ? "Benchmark"
                                : selectedTableTab == "market_forecast"
                                ? "Market Forecasts"
                                : selectedTableTab == "market_landscape"
                                ? "Market Landscapes"
                                : selectedTableTab == "special_report"
                                ? "Special Reports"
                                : selectedTableTab == "customer_study"
                                ? "Customer Studies"
                                : selectedTableTab == "ecosystem"
                                ? "Ecosystems"
                                : "Companies"}
                              <span className="material-icons icon ml-2 text-black icon-fs-20">
                                expand_more
                              </span>
                            </div>
                          }
                          className={
                            selectedTableTab == "benchmark" ||
                            selectedTableTab == "company" ||
                            selectedTableTab == "market_forecast" ||
                            selectedTableTab == "market_landscape" ||
                            selectedTableTab == "special_report" ||
                            selectedTableTab == "customer_study" ||
                            selectedTableTab == "ecosystem"
                              ? "filterReports activeReports"
                              : "filterReports"
                          }
                        >
                          {selectionTab.map((report) => (
                            <Dropdown.Item
                              onClick={() => setSelectedTable(report.value)}
                            >
                              <span className="subMenu-company">
                                {report.label}
                              </span>
                            </Dropdown.Item>
                          ))}
                        </Dropdown>
                      </li>
                    </ul>
                  </div>
                  {selectedTableTab === "company" ? (
                    <div
                      className="col-sm-12 col-md-9 d-flex justify-content-start align-items-center flex-wrap"
                      style={{ flex: "0 0 76%", maxWidth: "76%" }}
                    >
                      <div className="table-list-legends d-flex pb-0">
                        <span className="d-inline-flex align-items-center mr-1">
                          <VendorReport />
                          <h3 className="mb-0">Company Research</h3>
                        </span>
                        <span className="d-inline-flex align-items-center mr-1">
                          <VendorProfile />
                          <h3 className="mb-0">Company Profile</h3>
                        </span>
                        <span className="d-inline-flex align-items-center mr-1">
                          <VendorSnapshot />
                          <h3 className="mb-0">Company Snapshot</h3>
                        </span>
                        <span className="d-inline-flex align-items-center mr-1">
                          <BenchmarkCoverage />
                          <h3 className="mb-0">Benchmark Coverage</h3>
                        </span>
                        <span className="d-inline-flex align-items-center">
                          <InitialResponse />
                          <h3 className="mb-0">
                            <sup>*</sup>indicates Earnings Response
                          </h3>
                        </span>
                      </div>
                    </div>
                  ) : (
                    <AccessReportStatus />
                  )}
                </div>
                <div className="ml-3 mt-2 custom-card segments-card btn-items-card open-card"></div>
              </div>

              <div className="col-md-12 col-lg-12 col-sm-12 mb-3">
                {/* COMPANY */}
                <div className="mt-1 p-0 pb-1 d-flex align-items-center flex-wrap title-section breadcrumb-mb-0">
                  <div className="custom-table-block first-col-fixed-block companies-list-table-block nowrap-table-block th-nowrap sort-table-block center-th-align dashboard-company-content">
                    {selectedTableTab === "company" ? (
                      <>
                        <DynamicTableComponent
                          columnData={company.columnData}
                          rowData={company.rowData}
                          showPagination={false}
                        />
                      </>
                    ) : selectedTableTab === "benchmark" ? (
                      <div>
                        <DynamicTableComponent
                          columnData={DashboardTables.tableHeader}
                          rowData={benchmarks.rowData}
                          showPagination={false}
                        />
                      </div>
                    ) : selectedTableTab === "market_forecast" ? (
                      <div>
                        <DynamicTableComponent
                          columnData={DashboardTables.tableHeader}
                          rowData={forecasts.rowData}
                          showPagination={false}
                        />
                      </div>
                    ) : selectedTableTab === "market_landscape" ? (
                      <div>
                        <DynamicTableComponent
                          columnData={DashboardTables.tableHeader}
                          rowData={landscapes.rowData}
                          showPagination={false}
                        />
                      </div>
                    ) : selectedTableTab === "special_report" ? (
                      <div>
                        <DynamicTableComponent
                          columnData={DashboardTables.tableHeader}
                          rowData={special_reports.rowData}
                          showPagination={false}
                        />
                      </div>
                    ) : selectedTableTab === "customer_study" ? (
                      <div>
                        <DynamicTableComponent
                          columnData={DashboardTables.tableHeader}
                          rowData={customer_study.rowData}
                          showPagination={false}
                        />
                      </div>
                    ) : (
                      <div>
                        <DynamicTableComponent
                          columnData={DashboardTables.tableHeader}
                          rowData={ecosystems.rowData}
                          showPagination={false}
                        />
                      </div>
                    )}
                  </div>
                  <div
                    className="ml-auto d-flex align-items-center view-all-text-table"
                    onClick={() => redirectToBigTable(selectedTableTab)}
                  >
                    View All <img src={arrow} />
                  </div>
                </div>
              </div>
            </div>

            {/* TBR REPORT USAGE SECTION */}
            <div className="mt-4 pt-3 pr-3 pl-3 d-flex align-items-center flex-wrap title-section breadcrumb-mb-0 dashboard-content">
              <div className="dashboard-content-header">
                <h1 className="mb-0 dashboard-title">
                  Report Usage Trends At TBR
                </h1>
                <div className="d-flex align-items-center">
                  <div
                    data-for={`tooltip-table-chart-active`}
                    data-tip={"Table View"}
                    onClick={() => setSelectedReportViewIsPie(false)}
                    className={
                      !selectedReportViewIsPie
                        ? "report-usage-toggle-set"
                        : "report-usage-toggle"
                    }
                  >
                    <span
                      className={`material-icons-outlined icon-fs-20 ${
                        !selectedReportViewIsPie
                          ? "toggle-white"
                          : "toggle-blue"
                      }`}
                    >
                      table_chart
                    </span>
                    <CustomReactTooltip
                      id={`tooltip-table-chart-active`}
                      multiline={true}
                    />
                  </div>
                  <div
                    data-for={`tooltip-pie-chart-active`}
                    data-tip={"Pie Chart View"}
                    onClick={() => setSelectedReportViewIsPie(true)}
                    className={
                      selectedReportViewIsPie
                        ? "report-usage-toggle-set ml-3"
                        : "report-usage-toggle ml-3"
                    }
                  >
                    <span
                      className={`material-icons-outlined icon-fs-20 ${
                        selectedReportViewIsPie ? "toggle-white" : "toggle-blue"
                      }`}
                    >
                      pie_chart
                    </span>
                    <CustomReactTooltip
                      id={`tooltip-pie-chart-active`}
                      multiline={true}
                    />
                  </div>
                </div>
              </div>
              <div style={{ width: "100%" }}>
                {selectedReportViewIsPie ? (
                  <div className="p-4 d-flex justify-content-between align-items-center">
                    {tbrReportDataIsEmpty ? (
                      <div className="data-not-found">
                        <img src={DataNotAvailable} alt="" />
                        <p>No TBR Report Usage data found yet.</p>
                      </div>
                    ) : (
                      <div>
                        <PieChartDashboard
                          type="all-data"
                          chartData={finalChartData}
                          dataIsAvailable={reportUsageData}
                          toggleReport={toggleReport}
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  <ReportUsageTable
                    data={reportUsageData}
                    redirectToBigTable={redirectToBigTable}
                    goToReportViewedDetailPage={goToReportViewedDetailPage}
                  />
                )}
              </div>
            </div>
          </div>

          {/* DASHBOARD RIGHT SIDE SECTION */}
          <div className="dashboard-right">
            {/* BOARD SECTION */}
            <div className="board-container">
              <div className="mt-3 pt-3 pr-4 pl-4 pb-4 d-flex align-items-center flex-wrap title-section breadcrumb-mb-0 dashboard-content-board">
                <div className="dashboard-board-header">
                  <h1 className="mb-0 dashboard-title">Boards</h1>
                  <div
                    onClick={() =>
                      history.push(
                        formatRoute(
                          constants.APPLICATION_ROUTE.MY_BOARDS.LIST.ROUTE,
                          {}
                        )
                      )
                    }
                    className="ml-auto d-flex align-items-center view-all-text"
                  >
                    View All
                  </div>
                </div>
                {tokenDetails?.role?.short_name !== "member" && (
                  <BoardDashboard
                    boardType="board"
                    isOpen={
                      boardIsOpen.find((item) => item.type === "my").isOpen
                    }
                    title="My Boards"
                    type={"my"}
                    callApiWithType={callBoardApiWithType}
                    data={myBoardData}
                  />
                )}
                {(tokenDetails?.role?.short_name === "lead" ||
                  tokenDetails?.role?.short_name === "member") && (
                  <BoardDashboard
                    boardType="board"
                    isOpen={
                      boardIsOpen.find((item) => item.type === "enterprise")
                        .isOpen
                    }
                    title="Enterprise Boards"
                    type={"enterprise"}
                    callApiWithType={callBoardApiWithType}
                    data={enterpriseBoardData}
                  />
                )}
                <BoardDashboard
                  boardType="board"
                  isOpen={
                    boardIsOpen.find((item) => item.type === "tbri").isOpen
                  }
                  title="TBR Boards"
                  type={"tbri"}
                  callApiWithType={callBoardApiWithType}
                  data={TBRIBoardData}
                />
                <BoardDashboard
                  boardType="board"
                  isOpen={
                    boardIsOpen.find((item) => item.type === "shared").isOpen
                  }
                  title="Shared Boards"
                  type={"shared"}
                  callApiWithType={callBoardApiWithType}
                  data={sharedBoardData}
                />
              </div>
            </div>

            {/* DATA VISUALIZATION SECTION */}
            <div className="board-container">
              <div className="mt-4 mt-sm-3 pt-3 pr-4 pl-4 pb-4 mb-4 d-flex align-items-center flex-wrap title-section breadcrumb-mb-0 dashboard-content">
                <div className="dashboard-content-header">
                  <h1 className="mb-0 dashboard-title">Data Visualization</h1>
                  <div
                    onClick={() =>
                      history.push(
                        formatRoute(
                          constants.APPLICATION_ROUTE.DATA_VISUALIZATION.VIEW
                            .ROUTE,
                          {}
                        )
                      )
                    }
                    className="ml-auto d-flex align-items-center view-all-text"
                  >
                    View All
                  </div>
                </div>
                {tokenDetails?.role?.short_name !== "member" && (
                  <BoardDashboard
                    boardType="visualization"
                    isOpen={chartIsOpen.find((item) => item.type === "").isOpen}
                    title="My Visualizations"
                    type={""}
                    callApiWithType={callVisulizationWithType}
                    data={myVisualizations}
                  />
                )}
                {(tokenDetails?.role?.short_name === "lead" ||
                  tokenDetails?.role?.short_name === "member") && (
                  <BoardDashboard
                    boardType="visualization"
                    isOpen={
                      chartIsOpen.find((item) => item.type === "enterprise")
                        .isOpen
                    }
                    title="Enterprise Visualization Collection"
                    type={"enterprise"}
                    callApiWithType={callVisulizationWithType}
                    data={enterPriseVisualizations}
                  />
                )}
                <BoardDashboard
                  boardType="visualization"
                  isOpen={
                    chartIsOpen.find((item) => item.type === "tbri").isOpen
                  }
                  title="TBR Visualizations Collection"
                  type={"tbri"}
                  callApiWithType={callVisulizationWithType}
                  data={TBRIVisualizations}
                />
                <BoardDashboard
                  boardType="visualization"
                  isOpen={
                    chartIsOpen.find((item) => item.type === "shared").isOpen
                  }
                  title="Shared Visualizations"
                  type={"shared"}
                  callApiWithType={callVisulizationWithType}
                  data={sharedVisualizations}
                />
              </div>
            </div>

            <div className="board-container">
              <div className="mt-4 mt-sm-3 pt-3 pr-4 pl-4 pb-4 mb-4 d-flex align-items-center flex-wrap title-section breadcrumb-mb-0 dashboard-content">
                <div className="dashboard-content-header">
                  <h1 className="mb-0 dashboard-title">Upcoming Events</h1>
                  <div
                    onClick={() =>
                      history.push(
                        formatRoute(
                          constants.APPLICATION_ROUTE.WEBINAR_CALENDAR.LIST
                            .ROUTE,
                          {}
                        )
                      )
                    }
                    className="ml-auto d-flex align-items-center view-all-text"
                  >
                    View All
                  </div>
                </div>
                <UpcomingEventsDashboard data={upcomingEvents} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <DailogNew
        isOpen={dailogModal}
        accept={dailogModalAccept}
        decline={dailogModalDecline}
        header={
          <h3 className="text-darkblue2 font-weight-bold">
            {constants.WARNING.CONTENT_ACCESS_DENIED_TITLE}
          </h3>
        }
        content={
          <div className="noaccess-body">
            <h2 className="font-weight-semibold mb-0">
              {constants.WARNING.CONTENT_ACCESS_DENIED_TEXT}
            </h2>
          </div>
        }
        config={{ type: false, id: null }}
        modalStyleType={"noaccess-modal"}
      />
      {localStorage.getItem("show_feedback_form") && <RatingForm {...props} />}
    </aside>
  );
};

const mapStateToProps = (state) => {
  return {
    home: state.home,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    boardType: (type) => {
      dispatch(boardType(type));
    },
    activeMenuTab: (tab) => {
      dispatch(activeMenuTab(tab));
    },
    openSearchBar: (data) => {
      dispatch(openSearchBar(data));
    },
    getSearchText: (data) => {
      dispatch(getSearchText(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
