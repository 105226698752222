import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import "../Styles/calendar.scss";
import "../Styles/ratingform.scss";
import StarRating from "./StarRating";
import decodeJWT from "../lib/decodeJWT";
import { FeedBackService } from "../Services/FeedBack";
import { connect } from "react-redux";

function RatingForm(props) {
  const [feedBackJson, setFeedBackJson] = useState({
    accessibility_rating: 1,
    userExperiance_rating: 1,
    dataAlignment_rating: 1,
    service_rating: 1,
    use_most: [],
    like_most: [],
    description: "",
    over_all_rating: 1,
  });
  let tokenDetails = decodeJWT(localStorage.getItem("token"));

  const [showRatingModel, setShowRatingModel] = useState(true);

  const handleUselike_most = (value, filed, e) => {
    let array = feedBackJson[filed];
    let temp = [...array];
    const checkValue = (vl) => {
      if (!temp.includes(vl)) {
        temp.push(vl);
      }
    };
    if (value === "All") {
      if (e) {
        checkValue("Boards");
        checkValue("Data Visualisation");
        checkValue("Calendar");
      } else {
        temp = temp.filter(
          (i) =>
            i !== "Boards" && i !== "Data Visualisation" && i !== "Calendar"
        );
      }
    } else {
      if (e) {
        temp.push(value);
      } else {
        temp = temp.filter((i) => i !== value);
      }
    }
    setFeedBackJson({ ...feedBackJson, [filed]: temp });
  };

  const handleSubmit = async () => {
    try {
      let input = {
        over_all_rating: feedBackJson.over_all_rating,
        description: feedBackJson.description,
        rating_details: JSON.stringify({
          accessibility_rating: feedBackJson.accessibility_rating,
          userExperiance_rating: feedBackJson.userExperiance_rating,
          dataAlignment_rating: feedBackJson.dataAlignment_rating,
          service_rating: feedBackJson.service_rating,
          use_most: feedBackJson.use_most,
          like_most: feedBackJson.like_most,
        }),
      };

      await FeedBackService.addFeedBack(input);
      setShowRatingModel(false);
      localStorage.removeItem("show_feedback_form");
    } catch (e) {
      console.error("e", e);
    }
  };

  const handleAskMeLater = async () => {
    try {
      await FeedBackService.askMeLater();
      setShowRatingModel(false);
      localStorage.removeItem("show_feedback_form");
    } catch (e) {
      console.error("e", e);
    }
  };
  return (
    <Modal
      isOpen={showRatingModel}
      // toggle={() => setShowRatingModel(false)}
      style={{ width: "500px" }}
      className={
        "modal-lg modal-w-header custom-modal primary-modal rating-form"
      }
    >
      <ModalHeader>
        <div className="show-event-modal-header rating-header">
          <h2 className="">Rate your Experience</h2>
          <span
            onClick={() => setShowRatingModel(false)}
            style={{ color: "#555555", float: "right", cursor: "Pointer" }}
            className="material-icons"
          >
            close
          </span>
        </div>
      </ModalHeader>
      <ModalBody>
        <div className="rating-body">
          <div className="d-flex align-items-center">
            <span className="profile_circle">
              {props?.home?.loginUserSuccess?.first_name[0] +
                " " +
                props?.home?.loginUserSuccess?.last_name[0]}
            </span>
            <div className="user-details-block d-none d-md-flex flex-column align-items-start">
              <span>
                {" "}
                {props?.home?.loginUserSuccess?.first_name +
                  " " +
                  props?.home?.loginUserSuccess?.last_name}
              </span>
              <span className="user-role">
                {" "}
                {tokenDetails && tokenDetails.role && (
                  <span className="user-profile-role">
                    {props.home.loginUserSuccess.designation !== null
                      ? props.home.loginUserSuccess.designation
                      : tokenDetails.role.name}
                    {tokenDetails.client && tokenDetails.client.name
                      ? ` - ${tokenDetails.client.name}`
                      : ""}
                  </span>
                )}
              </span>
            </div>
          </div>
          <div className="my-2">
            <StarRating
              state={feedBackJson}
              field="over_all_rating"
              value={feedBackJson.over_all_rating}
              setState={setFeedBackJson}
              fontSize="34px"
            />
          </div>
          <div className="d-flex justify-content-between mb-2 rating-ques">
            <span>Accessibility</span>
            <StarRating
              state={feedBackJson}
              field="accessibility_rating"
              value={feedBackJson.accessibility_rating}
              setState={setFeedBackJson}
              fontSize="14px"
            />
          </div>
          <div className="d-flex justify-content-between mb-2 rating-ques">
            <span>User Experience</span>
            <StarRating
              state={feedBackJson}
              field="userExperiance_rating"
              value={feedBackJson.userExperiance_rating}
              setState={setFeedBackJson}
              fontSize="14px"
            />
          </div>
          <div className="d-flex justify-content-between mb-2 rating-ques">
            <span>Data Alignment</span>
            <StarRating
              state={feedBackJson}
              field="dataAlignment_rating"
              value={feedBackJson.dataAlignment_rating}
              setState={setFeedBackJson}
              fontSize="14px"
            />
          </div>
          <div className="d-flex justify-content-between mb-3 rating-ques">
            <span>Services</span>
            <StarRating
              state={feedBackJson}
              field="service_rating"
              value={feedBackJson.service_rating}
              setState={setFeedBackJson}
              fontSize="14px"
            />
          </div>
          <div>
            <div className="rating-ques mb-2">
              Which report type did you use the most?
            </div>
            <div className="d-flex flex-wrap mb-2" style={{ gap: "10px" }}>
              <div class="custom-checkbox">
                <input
                  type="checkbox"
                  id="company"
                  checked={feedBackJson.use_most.includes("Company")}
                  onChange={(e) =>
                    handleUselike_most("Company", "use_most", e.target.checked)
                  }
                />
                <label
                  for="company"
                  className="btn btn-sm border border-secondary btn-checkbox"
                >
                  Company
                </label>
              </div>
              <div class="custom-checkbox">
                <input
                  type="checkbox"
                  id="benchmark"
                  checked={feedBackJson.use_most.includes("Banchmark")}
                  onChange={(e) =>
                    handleUselike_most(
                      "Banchmark",
                      "use_most",
                      e.target.checked
                    )
                  }
                />
                <label
                  for="benchmark"
                  className="btn btn-sm border border-secondary btn-checkbox"
                >
                  Benchmark
                </label>
              </div>
              <div class="custom-checkbox">
                <input
                  type="checkbox"
                  id="market_forecast"
                  checked={feedBackJson.use_most.includes("Market Forecast")}
                  onChange={(e) =>
                    handleUselike_most(
                      "Market Forecast",
                      "use_most",
                      e.target.checked
                    )
                  }
                />
                <label
                  for="market_forecast"
                  className="btn btn-sm border border-secondary btn-checkbox"
                >
                  Market Forecast
                </label>
              </div>
              <div class="custom-checkbox">
                <input
                  type="checkbox"
                  id="market_landscape"
                  checked={feedBackJson.use_most.includes("Market Landscape")}
                  onChange={(e) =>
                    handleUselike_most(
                      "Market Landscape",
                      "use_most",
                      e.target.checked
                    )
                  }
                />
                <label
                  for="market_landscape"
                  className="btn btn-sm border border-secondary btn-checkbox"
                >
                  Market Landscape
                </label>
              </div>
              <div class="custom-checkbox">
                <input
                  type="checkbox"
                  id="customer_studies"
                  checked={feedBackJson.use_most.includes("Customer Studies")}
                  onChange={(e) =>
                    handleUselike_most(
                      "Customer Studies",
                      "use_most",
                      e.target.checked
                    )
                  }
                />
                <label
                  for="customer_studies"
                  className="btn btn-sm border border-secondary btn-checkbox"
                >
                  Customer Studies
                </label>
              </div>
              <div class="custom-checkbox">
                <input
                  type="checkbox"
                  id="ecosystem"
                  checked={feedBackJson.use_most.includes("Ecosystem")}
                  onChange={(e) =>
                    handleUselike_most(
                      "Ecosystem",
                      "use_most",
                      e.target.checked
                    )
                  }
                />
                <label
                  for="ecosystem"
                  className="btn btn-sm border border-secondary btn-checkbox"
                >
                  Ecosystem
                </label>
              </div>
              <div class="custom-checkbox">
                <input
                  type="checkbox"
                  id="special_report"
                  checked={feedBackJson.use_most.includes("Special Report")}
                  onChange={(e) =>
                    handleUselike_most(
                      "Special Report",
                      "use_most",
                      e.target.checked
                    )
                  }
                />
                <label
                  for="special_report"
                  className="btn btn-sm border border-secondary btn-checkbox"
                >
                  Special Report
                </label>
              </div>
              <div class="custom-checkbox">
                <input
                  type="checkbox"
                  id="projects"
                  checked={feedBackJson.use_most.includes("Projects")}
                  onChange={(e) =>
                    handleUselike_most("Projects", "use_most", e.target.checked)
                  }
                />
                <label
                  for="projects"
                  className="btn btn-sm border border-secondary btn-checkbox"
                >
                  Projects
                </label>
              </div>
            </div>
          </div>
          <div>
            <div className="rating-ques mb-2">
              Which function did you like the most?
            </div>
            <div
              className="d-flex justify-content-start flex-wrap"
              style={{ gap: "10px" }}
            >
              <div class="custom-checkbox">
                <input
                  type="checkbox"
                  id="all"
                  checked={
                    feedBackJson.like_most.includes("Boards") &&
                    feedBackJson.like_most.includes("Data Visualisation") &&
                    feedBackJson.like_most.includes("Calendar")
                  }
                  onChange={(e) =>
                    handleUselike_most("All", "like_most", e.target.checked)
                  }
                />
                <label
                  for="all"
                  className="btn btn-sm border border-secondary btn-checkbox"
                >
                  All
                </label>
              </div>
              <div class="custom-checkbox">
                <input
                  type="checkbox"
                  id="boards"
                  checked={
                    feedBackJson.like_most.includes("Boards") ||
                    feedBackJson.like_most.includes("All")
                  }
                  onChange={(e) =>
                    handleUselike_most("Boards", "like_most", e.target.checked)
                  }
                />
                <label
                  for="boards"
                  className="btn btn-sm border border-secondary btn-checkbox"
                >
                  Boards
                </label>
              </div>
              <div class="custom-checkbox">
                <input
                  type="checkbox"
                  id="calendar"
                  checked={
                    feedBackJson.like_most.includes("Calendar") ||
                    feedBackJson.like_most.includes("All")
                  }
                  onChange={(e) =>
                    handleUselike_most(
                      "Calendar",
                      "like_most",
                      e.target.checked
                    )
                  }
                />
                <label
                  for="calendar"
                  className="btn btn-sm border border-secondary btn-checkbox"
                >
                  Calendar
                </label>
              </div>
              <div class="custom-checkbox">
                <input
                  type="checkbox"
                  id="data_visualisation"
                  checked={
                    feedBackJson.like_most.includes("Data Visualisation") ||
                    feedBackJson.like_most.includes("All")
                  }
                  onChange={(e) =>
                    handleUselike_most(
                      "Data Visualisation",
                      "like_most",
                      e.target.checked
                    )
                  }
                />
                <label
                  for="data_visualisation"
                  className="btn btn-sm border border-secondary btn-checkbox"
                >
                  Data Visualization
                </label>
              </div>
            </div>
          </div>
          <div>
            <div className="rating-ques mt-3 mb-2">
              Share details of your own description
            </div>
            <textarea
              value={feedBackJson.description}
              style={{
                width: "100%",
                height: "100px",
                resize: "none",
                padding: "8px",
                borderRadius: "4px",
                border: "0.5px solid #D9D9D9",
              }}
              placeholder="Type something here.."
              className="d-flex from-group"
              row="5"
              onChange={(e) =>
                setFeedBackJson({
                  ...feedBackJson,
                  description: e.target.value,
                })
              }
            ></textarea>
          </div>
          <div
            className="d-flex justify-content-center"
            style={{ gap: "10px" }}
          >
            <button
              className="btn mt-3 border custom-border"
              style={{ color: "#3F7FE0" }}
              onClick={handleAskMeLater}
            >
              Ask Me Later
            </button>
            <button className="btn btn-primary mt-3" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}
const mapStateToProps = (state) => {
  return {
    home: state.home,
  };
};
export default connect(mapStateToProps, null)(RatingForm);

// export default RatingForm;
