import React, { useState, useEffect, useMemo, useRef } from "react";
import NewBarChart from "./Charts/BarChart";
import BubbleChart from "./Charts/BubbleChart";
import LineChart from "./Charts/LineChart";
import DoughnutChart from "./Charts/DoughnutChart";
import StackedBarChart from "./Charts/StackedBarChart";
import StackedLineChart from "./Charts/StackedLineChart";
import { VisualizationServicesV2 } from "../../../Services/VisualizationV2";
import TableView from "./TableView";
import { cloneDeep, zipObject, first, last, range } from "lodash";

import {
  filterQYDataByRange,
  filterYearDataByRange,
  generateXaxies,
} from "../../../Helpers/Helper";
import ChartFilters from "./ChartFilters";
import { CommonFunctions } from "./CommonFunction";

function Chart({
  selectedNodes,
  setChartPreview,
  quarterToggle,
  setQuarterToggle,
  paramId,
  selectedChart,
  setSelectedChart,
  chartName,
}) {
  const [view, setView] = useState(1);
  const [chartData, setChartData] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState();
  const [backUpChartData, setBackUpChartData] = useState([]);
  const [open, setOpen] = useState(false);
  const [marks, setMarks] = useState({});
  const Quarter = { "1Q": "Q1", "2Q": "Q2", "3Q": "Q3", "4Q": "Q4" };
  const [selectedRangeValue, setSelectedChartRangeValue] = useState("");
  const [chartRange, setChartRange] = useState();
  const chartRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);

  const handleDownload = (format) => {
    if (chartRef.current) {
      chartRef.current.downloadChart(format);
    }
    setShowDropdown(false);
  };

  const handleChartData = async () => {
    // Determine query parameter based on quarterToggle
    let queryparam = quarterToggle ? "?data=quarterly" : "?data=yearly";
    let url = "/getChartData" + queryparam;

    // Fetch chart data from the server
    let chartRes = await VisualizationServicesV2.getChartData(url, {
      type: quarterToggle ? "quarterly" : "yearly",
      data: selectedNodes,
    });

    // Function to process chart data and update state
    const processData = (dataFunc, chartData) => {
      // Adjust data using the provided function
      let data = dataFunc(chartData.data.data);

      // Generate x-axis marks
      let getMarks = generateXaxies(data);

      // Create tempMarks object using lodash's zipObject
      let tempMarks = zipObject(range(getMarks.length), getMarks);

      // Update state with new marks
      setMarks(tempMarks);

      // Get start and end quarters/years from the marks
      let startQYear = first(getMarks);
      let endQyear = last(getMarks);

      // Update chartRange state
      setChartRange([
        parseInt(CommonFunctions.getKeyByValue(tempMarks, startQYear)),
        parseInt(CommonFunctions.getKeyByValue(tempMarks, endQyear)),
      ]);

      // Update chart data state
      setChartData(data);
      setBackUpChartData(data);

      // Return start and end quarters/years for further use
      return { startQYear, endQyear, data };
    };

    // Handle chart data based on quarterToggle
    if (quarterToggle) {
      let { startQYear, endQyear } = processData(
        CommonFunctions.adjustYearQuarter,
        chartRes
      );

      // Update selected chart chartRange value for quarterly data
      setSelectedChartRangeValue(
        `${Quarter[startQYear.slice(0, 2)]} 20${startQYear.slice(2, 4)} - ${
          Quarter[endQyear.slice(0, 2)]
        } 20${endQyear.slice(2, 4)}`
      );
    } else {
      let { startQYear, endQyear } = processData(
        CommonFunctions.yearlyData,
        chartRes
      );

      // Update selected chart chartRange value for yearly data
      setSelectedChartRangeValue(`${startQYear} - ${endQyear}`);
    }

    // Set the selected period to 0
    setSelectedPeriod(0);
  };

  const handleChartPeriod = (vl) => {
    const currentYear = new Date().getFullYear();
    let tempChartData = cloneDeep(backUpChartData);
    if (vl === 2) {
      let lastTwoYears = [currentYear - 1, currentYear - 2];

      setChartData(
        tempChartData
          .map((item) => {
            item.data = item.data.filter((el) =>
              lastTwoYears.includes(el.year)
            );
            return item;
          })
          .filter((el) => el.data.length)
      );
    } else if (vl === 1) {
      let lastYear = [currentYear - 1];

      setChartData(
        tempChartData
          .map((item) => {
            item.data = item.data.filter((el) => lastYear.includes(el.year));
            return item;
          })
          .filter((el) => el.data.length)
      );
    } else {
      setChartData(tempChartData);
    }
    setSelectedPeriod(vl);
  };

  const handleRangeChange = (e) => {
    setChartRange(e);
  };

  const handleRange = () => {
    if (chartRange.length < 2) {
      console.error("Invalid chart range");
      return;
    }

    if (quarterToggle) {
      // Extract start and end year/quarter for quarterly data
      let startMark = chartRange?.length > 0 ? marks[chartRange[0]] : null;
      let endMark = chartRange?.length > 1 ? marks[chartRange[1]] : null;

      if (!startMark || !endMark) {
        console.error("Invalid marks for the given range");
        return;
      }

      let startYear = "20" + String(startMark.slice(2, 4));
      let startQuarter = startMark[0];
      let endYear = "20" + String(endMark.slice(2, 4));
      let endQuarter = endMark[0];

      // Clone backup chart data to avoid mutations
      let tempChartData = cloneDeep(backUpChartData);

      // Filter data based on the selected range
      let filteredData = filterQYDataByRange(
        tempChartData,
        parseInt(startYear),
        startQuarter,
        parseInt(endYear),
        endQuarter
      );

      // Update chart data state
      setChartData(filteredData);

      // Update selected chart range value
      setSelectedChartRangeValue(
        `Q${startQuarter} ${startYear} - Q${endQuarter} ${endYear}`
      );
    } else {
      // Extract start and end year for yearly data
      let startMark = marks[chartRange[0]];
      let endMark = marks[chartRange[1]];

      if (!startMark || !endMark) {
        console.error("Invalid marks for the given range");
        return;
      }

      let startYear = parseInt(startMark);
      let endYear = parseInt(endMark);

      // Clone backup chart data to avoid mutations
      let tempChartData = cloneDeep(backUpChartData);

      // Filter data based on the selected range
      let filteredData = filterYearDataByRange(
        tempChartData,
        startYear,
        endYear
      );

      // Update chart data state
      setChartData(filteredData);

      // Update selected chart range value
      setSelectedChartRangeValue(`${startYear} - ${endYear}`);
    }

    // Toggle the open state
    setOpen(!open);
  };

  useEffect(() => {
    if (selectedNodes.length) {
      handleChartData();
    }
  }, [quarterToggle, selectedNodes]);

  const memoizedLineData = useMemo(
    () => CommonFunctions.generateData("line", chartData),
    [chartData]
  );

  const memoizedBarData = useMemo(
    () => CommonFunctions.generateData("bar", chartData),
    [chartData]
  );
  const memoizedBubbleData = useMemo(
    () => CommonFunctions.generateData("bubble", chartData),
    [chartData]
  );
  const memoizedStackedLineData = useMemo(
    () => CommonFunctions.generateData("stackedline", chartData),
    [chartData]
  );
  const memoizedDoughnutData = useMemo(
    () => CommonFunctions.generateData("doughnut", chartData),
    [chartData]
  );

  return (
    <div style={{ width: "100%", height: chartData.length ? "auto" : "480px" }}>
      <ChartFilters
        setChartPreview={setChartPreview}
        view={view}
        setView={setView}
        setQuarterToggle={setQuarterToggle}
        quarterToggle={quarterToggle}
        setSelectedPeriod={setSelectedPeriod}
        selectedPeriod={selectedPeriod}
        handleChartPeriod={handleChartPeriod}
        selectedRangeValue={selectedRangeValue}
        open={open}
        setOpen={setOpen}
        marks={marks}
        chartRange={chartRange}
        handleRangeChange={handleRangeChange}
        handleRange={handleRange}
        selectedChart={selectedChart}
        setSelectedChart={setSelectedChart}
        handleDownload={handleDownload}
        setShowDropdown={setShowDropdown}
        showDropdown={showDropdown}
      />

      {chartData.length ? (
        view === 1 ? (
          <>
            {selectedChart === "line" && (
              <LineChart data={memoizedLineData} ref={chartRef} />
            )}
            {selectedChart === "bar" && (
              <NewBarChart data={memoizedBarData} ref={chartRef} />
            )}
            {selectedChart === "bubble" && (
              <BubbleChart data={memoizedBubbleData} ref={chartRef} />
            )}
            {selectedChart === "area_line" && (
              <StackedLineChart data={memoizedStackedLineData} ref={chartRef} />
            )}
            {selectedChart === "doughnut" && (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span
                  style={{
                    fontSize: "18px",
                    fontFamily: "sans-serif",
                    fontWeight: "bold",
                  }}
                >
                  Doughnut Chart
                </span>
                <DoughnutChart
                  data={memoizedDoughnutData}
                  downloadPDFRef={chartRef}
                />
              </div>
            )}
          </>
        ) : (
          <TableView data={chartData} />
        )
      ) : (
        <span className="no_data">No Data Available</span>
      )}
    </div>
  );
}

export default Chart;
