import React, { Component } from "react";
import CompanyDetailsCategories from "../Containers/CompanyDetailsCategories";
import CompanyDetailsContent from "../Containers/CompanyDetailsContent";
import { CompanyServices } from "../Services/Company";
import { connect } from "react-redux";
import Slugify from "../Utils/Slugify";
import { UncontrolledPopover, PopoverBody } from "reactstrap";
import CustomReactTooltip from "../Components/CustomReactTooltip";
import {
  categories,
  companyDetails,
  companyContent,
  companyFinancialDetails,
  companyFinancialRadarChartDetails,
} from "../Store/Actions/CompanyDetails";
import CustomBreadcrumb from "../Components/CustomBreadcrumb";
import queryString from "query-string";
import constants from "../Utils/constants";
import { formatRoute } from "react-router-named-routes";
import { TbriServices } from "../Services/Tbri";
import { activeMenuTab } from "../Store/Actions/User";
import DailogNew from "../Components/DailogNew";
import { BoardServices } from "../Services/Board";
import { boards } from "../Store/Actions/Board";
import PinToBoard from "../Containers/PinToBoard";
import ExportCompanyDashboardFileFormatter from "../Utils/ExportCompanyDashboardFileFormatter";
import Copy from "../Utils/Copy";
import permissionCheck from "../lib/permissionCheck";
import Toast from "../Utils/Toast";
import CompanySegmentFiles from "../Containers/CompanySegmentFiles";
import HelpModal from "./HelpModal";
import { SpecialReportServices } from "../Services/SpecialReports";
import gaEvent from "../Utils/GAEvents";

class CompanyDetails extends Component {
  state = {
    dailogModal: false,
    dailogModalContent: "",
    dailogModalHeader: "",
    dailogModalConfig: {},
    dailogModalStyleType: "",
    pinning_sub_category_id: "",
    documentModal: false,
    innerSidebarOpen: true,
    selectedCategory: "",
    content_id: null,
    segmentId: null,
    isBenchmark: false,
  };
  handleSelectedCategory = (catname) => {
    this.setState({ selectedCategory: catname });
  };
  toggleInnerSidebar = () => {
    this.setState({ innerSidebarOpen: !this.state.innerSidebarOpen });
  };
  async componentDidMount() {
    this.props.activeMenuTab(constants.APPLICATION_ROUTE.COMPANY.LIST.ROUTE);
    let companyId = this.props.match.params.id;
    let params = queryString.parse(this.props.location.search);
    let segmentId = 0;
    await CompanyServices.getCategories()
      .then((res) => {
        let response = res && res.data && res.data.data;
        response.forEach((res) => {
          res.is_selected = true;
          if (res.subcategories) {
            res.subcategories.forEach((res_sub) => {
              res.is_open = true;
              res_sub.is_selected = true;
              res_sub.show_actions = false;
            });
          }
        });
        this.props.categories(response);
      })
      .catch((error) => {
        this.props.categories([]);
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });

    await CompanyServices.getCompanyDetails(companyId)
      .then((res) => {
        let response = res && res.data && res.data.company;
        this.state.segmentId = JSON.parse(localStorage.getItem("segmentId"));
        this.state.isBenchmark = localStorage.getItem("isBenchmark");
        let allowed_segments = [];
        if (response.segments) {
          response.segments = response.segments.sort((a, b) =>
            a.name > b.name ? 1 : b.name > a.name ? -1 : 0
          );
          response.segments.forEach((seg, index) => {
            seg.is_selected = false;
            seg.allowed_access = response.allowed_access
              ? response.allowed_access
              : seg.allowed_access;
            seg.company_segment_ic_is_active =
              seg.company_segment_ic_is_active === 1 ? response.is_active : 0;
            if (
              this.state.isBenchmark &&
              this.state.segmentId &&
              parseInt(this.state.segmentId) === seg.id &&
              seg.allowed_access === true
            ) {
              seg.is_selected = true;
              segmentId = seg.id;
            } else if (
              params.segment &&
              parseInt(params.segment) === seg.id &&
              seg.allowed_access === true
            ) {
              seg.is_selected = true;
              segmentId = seg.id;
            }
            if (seg.allowed_access === true) allowed_segments.push(seg);
          });
          if (segmentId === 0 && allowed_segments[0]) {
            allowed_segments[0].is_selected = true;
            segmentId = allowed_segments[0].id;
          }
        }
        if (segmentId === 0) this.switchSegment(segmentId, false, false);

        this.props.companyDetails(res.data);

        if (segmentId !== 0) this.getCompanySegmentContent(segmentId);
        gaEvent.init({
          moduleName: constants.APPLICATION_ROUTE.COMPANY.LIST.NAME,
          id: this.props.match.params.id,
          segmentId: segmentId,
          page_url:
            this.props.history.location.pathname +
            this.props.history.location.search,
        });
      })
      .catch((error) => {
        this.props.companyDetails({});

        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
    this.callRecentlyView(companyId, segmentId);
  }

  callRecentlyView = async (companyId, segmentId) => {
    let body = {
      table_name: constants.APPLICATION_ROUTE.COMPANY.LIST.NAME,
      company_id: parseInt(companyId),
      segment_id: parseInt(segmentId),
    };
    await SpecialReportServices.addRecentlyViewDetails(body).catch((error) => {
      Toast(
        error && error.data && error.data.error
          ? error.data.error
          : constants.ERROR.SOMETHING_WENT_WRONG,
        "error"
      );
    });
  };

  componentWillMount() {
    let search = String(this.props.location.search);
    search = search.substring(search.indexOf("content="), search.length);
    this.setState({
      content_id: Number(
        search.substring(search.indexOf("=") + 1, search.length)
      ),
    });
  }

  componentWillUnmount = () => {
    gaEvent.trigger();
    this.props.categories([]);
    this.props.companyDetails({});
    this.props.companyContent([]);
    this.props.boards([]);
  };

  getTotalBoards = async (sub_category_id, type) => {
    let selectedSegment =
      this.props.company_details.companyDetails.company.segments.find(
        (s) => s.is_selected === true
      );
    const mapping_id = selectedSegment.company_segment_id;
    await BoardServices.getTotalBoards(mapping_id, sub_category_id, type)
      .then((res) => {
        let response = res && res.data && res.data.data;
        let finalresponse = [];
        response = response.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase()
            ? 1
            : b.name.toLowerCase() > a.name.toLowerCase()
            ? -1
            : 0
        );
        finalresponse.push({ id: "other", name: "+ Create New Board" });
        finalresponse.push(...response);
        finalresponse.forEach((res) => {
          res.is_selected = false;
          let isPinAllowed = true;
          let aclPermissionKey = constants.ACL.PERMISSION_KEY;
          let aclResource = constants.ACL.MY_BOARDS_RESOURCE;
          if (res.type === "shared" && res.mode && res.mode.short_name) {
            switch (res.mode.short_name) {
              case constants.MY_BOARDS_SHARE_MODE.EDIT.SLUG:
                aclPermissionKey = constants.ACL.BOARD_PERMISSION_KEY;
                aclResource = constants.ACL.MY_BOARDS_RESOURCE_EDIT;
                break;
              case constants.MY_BOARDS_SHARE_MODE.VIEW.SLUG:
                aclPermissionKey = constants.ACL.BOARD_PERMISSION_KEY;
                aclResource = constants.ACL.MY_BOARDS_RESOURCE_VIEW;
                break;
              case constants.MY_BOARDS_SHARE_MODE.COMMENT.SLUG:
                aclPermissionKey = constants.ACL.BOARD_PERMISSION_KEY;
                aclResource = constants.ACL.MY_BOARDS_RESOURCE_COMMENT;
                break;
              case constants.MY_BOARDS_SHARE_MODE.VIEW_COMMENT.SLUG:
                aclPermissionKey = constants.ACL.BOARD_PERMISSION_KEY;
                aclResource = constants.ACL.MY_BOARDS_RESOURCE_VIEW_COMMENT;
                break;
              default:
                aclPermissionKey = constants.ACL.BOARD_PERMISSION_KEY;
                aclResource = constants.ACL.MY_BOARDS_RESOURCE_VIEW;
                break;
            }
            isPinAllowed = permissionCheck(
              aclPermissionKey,
              aclResource.TITLE,
              aclResource.ACTIONS.PIN
            );
          }
          res.disable = !isPinAllowed;
        });
        this.props.boards(finalresponse);
      })
      .catch((error) => {
        this.props.boards([]);

        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  switchSegment = (segmentId, access, company_segment_ic_is_active = 1) => {
    gaEvent.trigger();
    gaEvent.init({
      moduleName: constants.APPLICATION_ROUTE.COMPANY.LIST.NAME,
      id: this.props.match.params.id,
      segmentId: segmentId,
      page_url:
        this.props.history.location.pathname +
        this.props.history.location.search,
    });
    if (access) {
      let original_details = Object.assign(
        {},
        this.props.company_details.companyDetails
      );
      original_details.company &&
        original_details.company.segments.forEach((seg, index) => {
          seg.is_selected = false;
          if (segmentId === seg.id) {
            seg.is_selected = true;
          }
        });
      this.props.companyDetails(original_details);
      // If segmentId is not 0 -> then calling getCompanySegmentContent
      if (segmentId !== 0) this.getCompanySegmentContent(segmentId);

      this.setState({
        content_id: "null",
      });
    } else {
      if (company_segment_ic_is_active === 0) {
        let dailogModalHeader = (
          <>
            <h3 className="text-darkblue2 font-weight-bold">
              {constants.WARNING.CONTENT_INACTIVE_TITLE}
            </h3>
          </>
        );
        let dailogModalContent = (
          <>
            <div className="inactive-body">
              <h2 className="font-weight-semibold mb-0">
                {constants.WARNING.CONTENT_INACTIVE_TEXT}
              </h2>
            </div>
          </>
        );
        this.setState({
          dailogModal: true,
          dailogModalHeader: dailogModalHeader,
          dailogModalContent: dailogModalContent,
          dailogModalConfig: { type: false, id: null },
          dailogModalStyleType: "inactive-modal",
        });
      } else {
        let dailogModalHeader = (
          <>
            <h3 className="text-darkblue2 font-weight-bold">
              {constants.WARNING.CONTENT_ACCESS_DENIED_TITLE}
            </h3>
          </>
        );
        let dailogModalContent = (
          <>
            <div className="noaccess-body">
              <h2 className="font-weight-semibold mb-0">
                {constants.WARNING.CONTENT_ACCESS_DENIED_TEXT}
              </h2>
            </div>
          </>
        );
        this.setState({
          dailogModal: true,
          dailogModalHeader: dailogModalHeader,
          dailogModalContent: dailogModalContent,
          dailogModalConfig: { type: false, id: null },
          dailogModalStyleType: "noaccess-modal",
        });
      }
    }
  };

  getCompanySegmentContent = async (segmentId) => {
    let companyId = this.props.match.params.id;
    await CompanyServices.addMostViewsOfReports(companyId, segmentId).catch(
      (error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      }
    );
    await CompanyServices.getCompanyContent(companyId, segmentId)
      .then((res) => {
        let response = res && res.data && res.data.data;
        response = response.map((r) => ({
          ...r,
          category_id:
            r.subcategory && r.subcategory.category && r.subcategory.category.id
              ? r.subcategory.category.id
              : null,
        }));
        let categories =
          this.props.company_details && this.props.company_details.categories
            ? this.props.company_details.categories
            : [];
        categories.forEach((category, index) =>
          category.subcategories.forEach((sub_category, index1) =>
            response.length > 0 &&
            response.find(
              (segment) => segment.subcategory_id === sub_category.id
            )
              ? (sub_category.is_selected = true)
              : (sub_category.is_selected = false)
          )
        );
        this.props.companyContent(response);
        this.props.categories(categories);
        this.changeRoute(segmentId);
      })
      .catch((error) => {
        this.props.companyContent([]);

        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  getCompanyFinancialInfo = async (segmentId) => {
    let companyCode = this.props.company_details.companyDetails.code;
    let segmentCodeFilter =
      this.props.company_details.companyDetails.segments.filter(
        (segment) => segment.id === segmentId
      );
    let segmentCode;
    if (segmentCodeFilter && segmentCodeFilter.length > 0)
      segmentCode = segmentCodeFilter[0].code;
    if (segmentCode && companyCode) {
      let prepareCombiation = [];
      prepareCombiation.push(
        `${companyCode}:${segmentCode}:${constants.TBRI_METRIC_CODES.REVENUE}`
      );
      prepareCombiation.push(
        `${companyCode}:${segmentCode}:${constants.TBRI_METRIC_CODES.OPERATING_INCOME}`
      );
      await TbriServices.getFinancialData(
        prepareCombiation.join(";"),
        "2019-12-01",
        "2019-12-01"
      )
        .then((res) => {
          let response = res.data.values ? res.data : {};
          this.props.companyFinancialDetails(response);
        })
        .catch((error) => {
          this.props.companyFinancialDetails([]);
        });
    }
  };

  getCompanyFinancialInfoComparision = async () => {
    if (this.props.company_details.companyDetails) {
      let companies = [
        { name: "Accenture", code: "CAN" },
        { name: "IBM", code: "IBM" },
        { name: "Dell Technologies", code: "Dell_Tech" },
      ];
      let companyCounter = 0;
      companies.forEach((comp, index_comp) => {
        comp.segments = [];
        let segmentsCounter = 0;
        this.props.company_details.companyDetails.segments.forEach(
          async (seg, index) => {
            let prepareCombiation = `${comp.code}:${seg.code}:${constants.TBRI_METRIC_CODES.REVENUE}`;
            await TbriServices.getFinancialData(
              prepareCombiation,
              "2019-12-01",
              "2019-12-01"
            )
              .then((res) => {
                let response = res.data.values ? res.data.values : [];
                let amount = response[0] ? response[0].amount : 0;
                comp.segments.push({ segment: seg.name, amount: amount });
                segmentsCounter = segmentsCounter + 1;
                if (
                  segmentsCounter ===
                  this.props.company_details.companyDetails.segments.length
                ) {
                  companyCounter = companyCounter + 1;
                  if (companyCounter === companies.length) {
                    this.props.companyFinancialRadarChartDetails(companies);
                  }
                }
              })
              .catch((error) => {
                let amount = 0;
                comp.segments.push({ segment: seg.name, amount: amount });
                segmentsCounter = segmentsCounter + 1;
              });
          }
        );
      });
    }
  };

  changeRoute = async (segmentId) => {
    if (segmentId > 0) {
      this.props.history &&
        (await this.props.history.push({
          //for unit testing in jest (this.props.history &&) Use
          pathname: formatRoute(
            constants.APPLICATION_ROUTE.COMPANY.VIEW.ROUTE,
            {
              id: this.props.match.params.id,
            }
          ),
          search: isNaN(this.state.content_id)
            ? "?segment=" + segmentId
            : "?segment=" + segmentId + "&content=" + this.state.content_id,
        }));
    }
  };

  dailogModalAccept = (config) => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
    });
  };

  dailogModalDecline = () => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
    });
  };

  togglePinBoardActions = (category_id, sub_category_id) => {
    let original_categories = [...this.props.company_details.categories];
    original_categories.forEach((res) => {
      if (res.id === category_id) {
        if (res.subcategories) {
          res.subcategories.forEach((res_sub) => {
            if (res_sub.id === sub_category_id)
              res_sub.show_actions = !res_sub.show_actions;
            else res_sub.show_actions = false;
          });
        }
      }
    });
    this.props.categories(original_categories);
  };

  addToBoardHandleSubCategory = async (category_id, sub_category_id) => {
    await this.getTotalBoards(sub_category_id, "company");
    let boards = [...this.props.board.boards];
    boards = boards.map((b) => ({ ...b, is_selected: false }));
    this.props.boards(boards);
    this.setState({
      pinning_category_id: category_id,
      pinning_sub_category_id: sub_category_id,
    });
  };

  closeBoardModal = () => {
    this.setState({
      pinning_category_id: null,
      pinning_sub_category_id: null,
    });
  };

  successAddToBoard = () => {
    this.togglePinBoardActions(
      this.state.pinning_category_id,
      this.state.pinning_sub_category_id
    );
    Toast(constants.MY_BOARDS.PIN.SUCCESS, "success");
    this.setState({
      pinning_category_id: null,
      pinning_sub_category_id: null,
    });
  };

  handleQuantDataRedirection = () => {
    let companyDetails =
      this.props.company_details && this.props.company_details.companyDetails
        ? this.props.company_details.companyDetails.company
        : [];
    let selectedSegments =
      companyDetails.id && companyDetails.segments
        ? companyDetails.segments.filter(
            (segment) => segment.is_selected === true
          )
        : [];
    this.props.history &&
      this.props.history.push({
        //for unit testing in jest (this.props.history &&) Use
        pathname: formatRoute(
          constants.APPLICATION_ROUTE.COMPANY.QUANTDATA.ROUTE,
          {}
        ),
        search:
          "?companyId=" +
          companyDetails.id +
          "&segmentId=" +
          selectedSegments[0].id,
      });
  };

  handleBenchmarksRedirection = () => {
    let companyDetails =
      this.props.company_details && this.props.company_details.companyDetails
        ? this.props.company_details.companyDetails.company
        : [];
    this.props.history &&
      this.props.history.push({
        //for unit testing in jest (this.props.history &&) Use
        pathname: formatRoute(
          constants.APPLICATION_ROUTE.BENCHMARK.LIST.ROUTE,
          {}
        ),
        search: "?companyId=" + companyDetails.id,
      });
  };

  handleMarketForecastsRedirection = () => {
    let companyDetails =
      this.props.company_details && this.props.company_details.companyDetails
        ? this.props.company_details.companyDetails.company
        : [];
    this.props.history &&
      this.props.history.push({
        //for unit testing in jest (this.props.history &&) Use
        pathname: formatRoute(
          constants.APPLICATION_ROUTE.MARKET_FORECAST.LIST.ROUTE,
          {}
        ),
        search: "?companyId=" + companyDetails.id,
      });
  };

  handleMarketLandscapeRedirection = () => {
    let companyDetails =
      this.props.company_details && this.props.company_details.companyDetails
        ? this.props.company_details.companyDetails.company
        : [];
    this.props.history &&
      this.props.history.push({
        pathname: formatRoute(
          constants.APPLICATION_ROUTE.MARKET_LANDSCAPE.LIST.ROUTE,
          {}
        ),
        search: "?companyId=" + companyDetails.id,
      });
  };

  handleSpecialReportRedirection = () => {
    let companyDetails =
      this.props.company_details && this.props.company_details.companyDetails
        ? this.props.company_details.companyDetails.company
        : [];
    this.props.history &&
      this.props.history.push({
        pathname: formatRoute(
          constants.APPLICATION_ROUTE.SPECIAL_REPORTS.LIST.ROUTE,
          {}
        ),
        search: "?companyId=" + companyDetails.id,
      });
  };

  handleDownload = async (revenue, netincome, netprofitmargin) => {
    let companyDetails =
      this.props.company_details && this.props.company_details.companyDetails
        ? this.props.company_details.companyDetails.company
        : [];
    let selectedSegments =
      companyDetails.id && companyDetails.segments
        ? companyDetails.segments.filter(
            (segment) => segment.is_selected === true
          )
        : [];
    let original_categories = [...this.props.company_details.categories];
    let selected_subcategories = [];
    original_categories.forEach((c) => {
      c.subcategories.forEach((s) => {
        if (s.is_selected === true) selected_subcategories.push(s.id);
      });
    });
    let request = {
      mappingId: selectedSegments[0].company_segment_id,
      type: "company",
      source: "content",
      ext: "pdf",
      subcategoryIds: selected_subcategories,
      financialMetrics: {
        data: {
          revenue: revenue,
          operatingIncome: netincome,
          operatingProfitMargin: netprofitmargin,
        },
        rawData: this.props.company_details.companyFinancialDetails,
      },
    };
    await CompanyServices.downloadContent(request)
      .then((response) => {
        if (response.status === 200) {
          if (navigator.userAgent.match("CriOS")) {
            var reader = new FileReader();
            var out = new Blob([response.data], { type: "application/pdf" });
            reader.onload = function (e) {
              window.location.href = reader.result;
            };
            reader.readAsDataURL(out);
          } else {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            let fileName = ExportCompanyDashboardFileFormatter(
              companyDetails.name,
              selectedSegments[0].name
            );
            link.setAttribute("download", `${fileName}.pdf`);
            document.body.appendChild(link);
            link.click();
            gaEvent.sendEventToGA(constants.GA.CUSTOM_EVENTS.DOWNLOAD_VIEW, {
              fileName: `${fileName}`,
              fileFormat: `pdf`,
              moduleName: constants.APPLICATION_ROUTE.COMPANY.LIST.NAME,
              id: this.props.match?.params?.id,
              pageUrl:
                this.props.history.location.pathname +
                this.props.history.location.search,
            });
          }
        }
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  copyHandler = (author) => {
    let companyDetails =
      this.props.company_details && this.props.company_details.companyDetails
        ? JSON.parse(JSON.stringify(this.props.company_details.companyDetails))
        : {};
    let segments =
      companyDetails?.company &&
      companyDetails?.company.id &&
      companyDetails.company.segments
        ? companyDetails.company.segments
        : [];
    let selectedSegmentsIndex = segments.findIndex(
      (segment) => segment.is_selected === true
    );
    if (selectedSegmentsIndex >= 0) {
      let selectedSegmentAuthors = segments[selectedSegmentsIndex]
        ? segments[selectedSegmentsIndex].authors
        : [];
      let authorIndex = selectedSegmentAuthors.findIndex(
        (a) => a.id === author.id
      );
      companyDetails.company.segments[selectedSegmentsIndex].authors[
        authorIndex
      ].copied = true;
      this.props.companyDetails(companyDetails);
      Copy(author.email);
      setTimeout(() => {
        let cd = {
          company: JSON.parse(JSON.stringify(companyDetails.company)),
        };
        cd.company.segments[selectedSegmentsIndex].authors[
          authorIndex
        ].copied = false;
        this.props.companyDetails(cd);
      }, 2000);
    }
  };

  documentModalHandler = () => {
    let companyDetails =
      this.props.company_details && this.props.company_details.companyDetails
        ? JSON.parse(
            JSON.stringify(this.props.company_details.companyDetails?.company)
          )
        : {};
    let segments =
      companyDetails.id && companyDetails.segments
        ? companyDetails.segments
        : [];
    let selectedSegments = segments.find(
      (segment) => segment.is_selected === true
    );
    this.setState({
      documentModal: !this.state.documentModal,
      companyId: companyDetails.id ? companyDetails.id : null,
      segmentId: selectedSegments.id ? selectedSegments.id : null,
    });
  };

  breadcrumbs = () => {
    let companyDetails =
      this.props.company_details && this.props.company_details.companyDetails
        ? this.props.company_details.companyDetails.company
        : [];
    let breadcrumbs = [
      {
        title: constants.APPLICATION_ROUTE.COMPANY.LIST.COMPANY_REPORTS,
        link: formatRoute(constants.APPLICATION_ROUTE.COMPANY.LIST.ROUTE, {}),
        is_active: false,
      },
      {
        title: companyDetails?.id
          ? companyDetails.name
          : constants.APPLICATION_ROUTE.COMPANY.VIEW.NAME,
        link: null,
        is_active: true,
      },
    ];
    return breadcrumbs;
  };

  render() {
    let companyDetails =
      this.props.company_details && this.props.company_details.companyDetails
        ? this.props.company_details.companyDetails?.company
        : [];
    let selectedSegments =
      companyDetails?.id && companyDetails?.segments
        ? companyDetails.segments
        : [];
    let selectedSegmentAuthors = selectedSegments.find(
      (s) => s.is_selected === true
    );
    let companyName = companyDetails?.name;
    let segmentName;
    selectedSegments &&
      selectedSegments.forEach((seg) => {
        if (seg.is_selected) segmentName = seg?.name;
      });
    return (
      <div
        className={`${
          this.state.innerSidebarOpen
            ? "opened-innersidebar"
            : "closed-innersidebar"
        } main_content fixed-title-main-content`}
      >
        <div className="m-3 mb-0 bg-white px-3 pb-3 br_8 main_content_height">
          <div className="d-flex align-items-center companies-details-page-title-sec fixed-title-section">
            <div className="breadcrumb-mb-0">
              <CustomBreadcrumb breadcrumbs={this.breadcrumbs()} />
            </div>
            <div className="ml-auto">
              {selectedSegmentAuthors?.authors != undefined &&
                selectedSegmentAuthors?.authors.length > 0 && (
                  <button
                    className="btn dropdown-btn d-inline-flex align-items-center mr-3 pr-1"
                    type="button"
                    id="authors-popover"
                  >
                    <span>
                      {selectedSegmentAuthors?.authors != undefined
                        ? selectedSegmentAuthors?.authors.length
                        : 0}{" "}
                      Author(s)
                    </span>
                    <span className="material-icons arrow-drown-down ml-1 icon-fs-20">
                      arrow_drop_down
                    </span>
                  </button>
                )}
              {selectedSegmentAuthors?.authors != undefined &&
                selectedSegmentAuthors?.authors.length > 0 && (
                  <UncontrolledPopover
                    target="authors-popover"
                    className="custom-popover-author authors-popover"
                    placement="bottom"
                    trigger="legacy"
                  >
                    <PopoverBody>
                      <div className="authors-list">
                        {selectedSegmentAuthors?.authors.map(
                          (author, index) => (
                            <div
                              className="author d-flex justify-content-between align-items-center"
                              key={index}
                            >
                              <a
                                href={`mailto:${author.email}?subject=${companyDetails.name} - ${selectedSegmentAuthors.name} Inquiry`}
                                data-for={`tooltip-${Slugify(
                                  author.id
                                )}-details`}
                                data-tip={`${author.first_name} ${
                                  author.last_name
                                }, 
                              ${
                                author.designation === null
                                  ? author.role.name
                                  : author.designation
                              } <br/> (${author.email})`}
                                data-iscapture="true"
                              >
                                {author.first_name} {author.last_name},{" "}
                                {author.designation === null
                                  ? author.role.name
                                  : author.designation}
                              </a>
                              <CustomReactTooltip
                                id={`tooltip-${Slugify(author.id)}-details`}
                                multiline={true}
                              />
                              <>
                                {author.copied && (
                                  <>
                                    <span
                                      className="material-icons-outlined copy-icon icon-fs-14 ml-4 text-green cursor-pointer"
                                      data-for={`tooltip-${Slugify(
                                        author.id
                                      )}-copied`}
                                      data-tip={`Copied`}
                                      data-iscapture="true"
                                    >
                                      done
                                    </span>
                                    <CustomReactTooltip
                                      id={`tooltip-${Slugify(
                                        author.id
                                      )}-copied`}
                                      multiline={true}
                                    />
                                  </>
                                )}
                                {!author.copied && (
                                  <>
                                    <span
                                      className="material-icons-outlined copy-icon icon-fs-14 ml-4 cursor-pointer"
                                      aria-hidden="true"
                                      onClick={() => {
                                        this.copyHandler(author);
                                      }}
                                      data-for={`tooltip-${Slugify(
                                        author.id
                                      )}-copy`}
                                      data-tip={`Copy<br>Email`}
                                      data-iscapture="true"
                                    >
                                      content_copy
                                    </span>
                                    <CustomReactTooltip
                                      id={`tooltip-${Slugify(author.id)}-copy`}
                                      multiline={true}
                                    />
                                  </>
                                )}
                              </>
                            </div>
                          )
                        )}
                      </div>
                    </PopoverBody>
                  </UncontrolledPopover>
                )}
            </div>
            <div className="mr-3">
              <HelpModal category="Company Details" />
            </div>
            <button
              className="icon-btn-primary icon-btn d-flex align-items-center justify-content-center"
              id="PopoverLegacy2"
            >
              <span className="material-icons-outlined icon">more_vert</span>
            </button>
          </div>

          <CompanyDetailsCategories
            toggleInnerSidebar={this.toggleInnerSidebar}
            handleSelectedCategory={this.handleSelectedCategory}
          />

          <CompanyDetailsContent
            handleDownload={this.handleDownload}
            switchSegmentHandler={this.switchSegment}
            isRelatedInsightsVisible={false}
            togglePinBoardActions={this.togglePinBoardActions}
            addToBoard={this.addToBoardHandleSubCategory}
            copyHandler={this.copyHandler}
            handleBenchmarksRedirection={this.handleBenchmarksRedirection}
            handleQuantDataRedirection={this.handleQuantDataRedirection}
            handleMarketForecastsRedirection={
              this.handleMarketForecastsRedirection
            }
            handleMarketLandscapeRedirection={
              this.handleMarketLandscapeRedirection
            }
            handleSpecialReportRedirection={this.handleSpecialReportRedirection}
            documentModalHandler={this.documentModalHandler}
            selectedCategory={this.state.selectedCategory}
            aclActionVisible={this.props.aclActionVisible ? false : true} //add this for jest testing
            contentId={this.state.content_id}
            companyDetails={this.props.company_details.companyDetails}
            callRecentlyView={this.callRecentlyView}
          />

          <DailogNew
            isOpen={this.state.dailogModal}
            accept={this.dailogModalAccept}
            decline={this.dailogModalDecline}
            header={this.state.dailogModalHeader}
            content={this.state.dailogModalContent}
            config={this.state.dailogModalConfig}
            modalStyleType={this.state.dailogModalStyleType}
          />
          {this.state.pinning_sub_category_id &&
            this.state.pinning_sub_category_id !== null && (
              <PinToBoard
                type="company"
                closeBoardModal={this.closeBoardModal}
                getTotalBoards={this.getTotalBoards}
                pinning_sub_category_id={this.state.pinning_sub_category_id}
                successAddToBoard={this.successAddToBoard}
              />
            )}
          {this.state.documentModal && (
            <CompanySegmentFiles
              reportName={companyName}
              segmentName={segmentName}
              reportId={this.state.companyId}
              reportSegment={this.state.segmentId}
              reportType="company"
              documentModal={this.state.documentModal}
              documentModalHandler={this.documentModalHandler}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    company_details: state.company_details,
    home: state.home,
    board: state.board,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    categories: (payload) => {
      dispatch(categories(payload));
    },
    companyDetails: (payload) => {
      dispatch(companyDetails(payload));
    },
    companyContent: (payload) => {
      dispatch(companyContent(payload));
    },
    companyFinancialDetails: (payload) => {
      dispatch(companyFinancialDetails(payload));
    },
    companyFinancialRadarChartDetails: (payload) => {
      dispatch(companyFinancialRadarChartDetails(payload));
    },
    activeMenuTab: (tab) => {
      dispatch(activeMenuTab(tab));
    },
    boards: (payload) => {
      dispatch(boards(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetails);
