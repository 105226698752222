import React from "react";
import { formatRoute } from "react-router-named-routes";
import constants from "../../../Utils/constants";
import "./PreferenceCenterSidebar.css";
import BlueArrow from "./PreferenceCenterIcon/BlueArrow.svg";
import BlackArrow from "./PreferenceCenterIcon/BlackArrow.svg";
import Slugify from "../../../Utils/Slugify";

const PreferenceCenterSidebar = ({
  segmentData,
  handleCollaps,
  selectedSegment,
  goToScroll,
}) => {
  return (
    <div className='preference-sidebar'>
      <a
        className='d-flex ml-4 mt-4 cursor-pointer'
        style={{ textDecoration: "none", color: "#000" }}
        href={formatRoute(constants.APPLICATION_ROUTE.DASHBOARD_LIST.ROUTE, {})}
      >
        <span className='material-icons icon-fs-24'>arrow_back</span>
        <h3 className='preference-head'>Preference Center</h3>
      </a>
      <div className='sidebar-div'>
        {" "}
        {segmentData
          .sort((a, b) => a.name.localeCompare(b.name, { sensitivity: "base" }))
          .map((item, index) => {
            let is_selected = selectedSegment.id === item.id;
            return (
              <div
                key={index}
                className='sidebar-item-div'
                style={{
                  color: is_selected ? "#2E6AD2" : "#555",
                  background: is_selected ? "#F1F8FF" : "transparent",
                  borderBottom: is_selected
                    ? "1px solid #2E6AD2"
                    : "1px solid #DEDEDE",
                  borderTop:
                    index === 0
                      ? "1px solid #DEDEDE"
                      : is_selected
                      ? "1px solid #2E6AD2"
                      : "none",
                  borderColor: is_selected ? "#2E6AD2" : "#DEDEDE",
                  fontWeight: is_selected ? 700 : 600,
                }}
                onClick={() => {
                  goToScroll(`#${Slugify(item.name)}_${item.id}`);
                  handleCollaps(index);
                }}
              >
                <span>{item.name}</span>
                <img src={is_selected ? BlueArrow : BlackArrow} alt='arrow' />
              </div>
            );
          })}
      </div>
    </div>
  );
};
export default PreferenceCenterSidebar;
